import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { Anchor } from '@strategies/react-anchors';

import panels from './panels';
import MapSequencePanel from './MapSequencePanel';
import CoMapPanel from './CoMapPanel';


export default observer(function MapSequence() {
    const { isEditing } = useHenshuApp();
    const [sticky, setSticky] = useState(false);
    const [image, setImage] = useState(0);
    const [activePin, setActivePin] = useState(null);

    useEffect(() => setActivePin(null), [image]);

    return (
        <Anchor offset={window.innerHeight * .33} onTop={() => setSticky(true)} onCenter={() => setSticky(false)}>
            <div className={`MapSequence ${isEditing ? 'editing' : ''} ${sticky ? 'sticky' : ''}`}>
                <div className="image-section">
                    {panels.map((panel, i) => (
                        <MapSequencePanel
                            key={panel.id}
                            panel={panel}
                            className={i <= image ? 'active' : ''}
                            activePin={activePin}
                            setActivePin={setActivePin}
                        />
                    ))}

                    <CoMapPanel
                        className={image === panels.length ? 'active' : ''}
                    />
                </div>

                {panels.map((panel, i) => (
                    <div key={panel.id} className="panel-wrapper">
                        <Anchor
                            onCenter={() => setImage(i)}
                            onOffscreen={a => {
                                if (a.top > 0) {
                                    sticky && i !== 0 && setImage(i - 1);
                                }
                            }}
                        >
                            <MapSequencePanel
                                panel={panel}
                                activePin={activePin}
                                setActivePin={setActivePin}
                            />
                        </Anchor>
                    </div>
                ))}

                <div className="panel-wrapper">
                    <Anchor
                        onChange={a => a.top <= 0 && setImage(panels.length)}
                        onCenter={() => setImage(panels.length)}
                        onOffscreen={a => {
                            if (a.top > 0) {
                                sticky && setImage(panels.length - 1);
                            }
                            else {
                                setSticky(false);
                            }
                        }}
                    >
                        <CoMapPanel />
                        <Anchor onOffscreen={a => 0 < a.top && a.top <= window.innerHeight + 100 && setSticky(true)} />
                    </Anchor>
                </div>
            </div>
        </Anchor>
    );
});
