import {useState} from "react";
import {observer} from 'mobx-react';
import {useHenshuApp, Curate} from '@strategies/henshu-app';
import {henshu, useHenshu} from '@strategies/henshu';
import { Anchor } from '@strategies/react-anchors';

import Splotch from '../../Splotch/Splotch';
import Outline from '../../Outline/Outline';
import ContentPanel from '../../ContentPanel/ContentPanel';
import DoubleImage from '../../DoubleImage/DoubleImage';
import FadeUp from '../../animations/FadeUp';
import Issue from '../Issue/Issue';
import Letter from '../../Letter/Letter';
import Parallax from '../../Parallax/Parallax';
import Section from '../../Section/Section';
import QA from '../../QA/QA';
import BuildingStack from './components/BuildingStack/BuildingStack'

import SasakiLogoWhite from '../../../assets/images/sasaki-logo-white.png';


export default observer(function SecondIssue() {
    const {bindTo} = useHenshu();
    const {upload} = useHenshuApp().persist;
    const [buildingExpanded, setBuildingExpanded] = useState(false);
    const [buildingPosition, setBuildingPosition] = useState('top');
    const [buildIndex, setBuildIndex] = useState(0);

    return (
        <Issue className="SecondIssue">
            <header className="heavy-padding">
                <div>
                    <img className="sasaki-logo" src={SasakiLogoWhite} alt="Sasaki"/>

                    <henshu.img
                        className="gif"
                        alt="Hello 110 Chauncy"
                        get={() => bindTo('he110chauncy').get()}
                        set={async buf => bindTo('he110chauncy').set(await upload(buf))}
                    />
                </div>

                <FadeUp>
                    <henshu.h1 {...bindTo('issues.first.title')} />

                    <Parallax className="header-circle" magnitude={-.05}>
                        <henshu.img
                            get={() => bindTo('issues.first.title.circle').get()}
                            set={async buf => bindTo('issues.first.title.circle').set(await upload(buf))}
                        />
                    </Parallax>
                </FadeUp>
            </header>

            <Section>
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.second.banner.front')}
                        back={bindTo('issues.second.banner.back')}
                    />
                </FadeUp>
            </Section>

            <Curate id="issues.second.intro" data={{ title: 'Intro' }}>
                <Section className="intro-body">
                    <FadeUp>
                        <henshu.p {...bindTo('issues.second.intro.body')} />
                    </FadeUp>
                </Section>
            </Curate>

            <ul className="intro top-intro">
                <henshu.each {...bindTo('issues.second.intro')}>
                    {(bindToIntro, i) => (
                        <li>
                            <FadeUp>
                                <Curate id={`issues.second.intro.section${i+1}`} data={{ title: 'Intro' }}>
                                    <DoubleImage
                                        frontMod={.1}
                                        backMod={i === 5 ? .18 : .27}
                                        front={bindToIntro('image.front')}
                                        back={bindToIntro('image.back')}
                                    />

                                    <henshu.richtext {...bindToIntro('text')} />
                                </Curate>
                            </FadeUp>
                        </li>
                    )}
                </henshu.each>
            </ul>

            <Section className="letters">
                <FadeUp>
                    <Curate id="issues.second.letter1" data={{ title: "Be Like Water, My Friend", author: "Tao Zhang" }}>
                        <Letter name="issues.second.letter1" />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="bubble-friend">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.first.bubble.front')}
                        back={bindTo('issues.first.bubble.back')}
                    />
                </FadeUp>
            </Section>

            <div className="building">
                <FadeUp>
                    <henshu.h3 className="building-header" {...bindTo('issues.second.build.header')} />
                </FadeUp>

                <div className={"build-sequence"}>
                    <div className={`building-stack-wrapper ${buildingPosition}`}>
                        <BuildingStack expanded={buildingExpanded} index={buildIndex}/>
                    </div>

                    <div className="build-content">
                        <Anchor onTop={() => setBuildingPosition('top')} onOffscreen={a => a.top >= window.innerHeight && setBuildingPosition('top')}>
                            <div className="build-section">
                                <Anchor
                                    onOffscreen={a => {
                                        if (a.top <= 0) {
                                            setBuildingExpanded(true);
                                            setBuildingPosition('fixed');
                                        }
                                    }}
                                    onTop={() => setBuildingExpanded(false)}
                                >
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.intro" data={{ title: bindTo('issues.second.build.header').get() }}>
                                            <div className="build-segment top-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.intro')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>
                            </div>

                            <div className="build-section collaborate">
                                <Anchor onCenter={() => setBuildIndex(0)}>
                                    <FadeUp>
                                        <henshu.h3 {...bindTo('issues.second.build.collaborate.header')} />
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(1)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.1" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.1')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(2)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.2" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.2')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(3)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.3" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.3')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(4)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.4" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.4')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(5)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.5" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.5')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(6)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.6" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                        <div className="build-segment">
                                            <henshu.richtext {...bindTo('issues.second.build.collaborate.6')} />
                                        </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(7)}>
                                    <FadeUp>
                                        <Curate id="issues.second.collaborate.7" data={{ title: bindTo('issues.second.collaborate.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.collaborate.7')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>
                            </div>

                            <div className="build-section focus">
                                <FadeUp>
                                    <henshu.h3 {...bindTo('issues.second.build.focus.header')} />
                                </FadeUp>

                                <Anchor>
                                    <FadeUp>
                                        <Curate id="issues.second.focus.1" data={{ title: bindTo('issues.second.build.focus.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.focus.1')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(7)}>
                                    <FadeUp>
                                        <Curate id="issues.second.focus.2" data={{ title: bindTo('issues.second.build.focus.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.focus.2')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(10)}>
                                    <FadeUp>
                                        <Curate id="issues.second.focus.3" data={{ title: bindTo('issues.second.build.focus.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.focus.4')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>
                            </div>

                            <div className="build-section recharge">
                                <FadeUp>
                                    <henshu.h3 {...bindTo('issues.second.build.recharge.header')} />
                                </FadeUp>

                                <Anchor>
                                    <FadeUp>
                                        <Curate id="issues.second.recharge.1" data={{ title: bindTo('issues.second.build.recharge.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.recharge.1')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(11)}>
                                    <FadeUp>
                                        <Curate id="issues.second.recharge.2" data={{ title: bindTo('issues.second.build.recharge.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.recharge.2')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(12)}>
                                    <FadeUp>
                                        <Curate id="issues.second.recharge.3" data={{ title: bindTo('issues.second.build.recharge.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.recharge.3')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(13)}>
                                    <FadeUp>
                                        <Curate id="issues.second.recharge.4" data={{ title: bindTo('issues.second.build.recharge.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.recharge.4')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(14)}>
                                    <FadeUp>
                                        <Curate id="issues.second.recharge.5" data={{ title: bindTo('issues.second.build.recharge.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.recharge.5')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>
                            </div>

                            <div className="build-section stuff">
                                <FadeUp>
                                    <henshu.h3 {...bindTo('issues.second.build.stuff.header')} />
                                </FadeUp>

                                <Anchor>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.1" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.1')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(15)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.2" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.2')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(16)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.3" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.3')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(17)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.4" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.4')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(18)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.5" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.5')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(19)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.6" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.6')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(20)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.7" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.7')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>

                                <Anchor onCenter={() => setBuildIndex(21)}>
                                    <FadeUp>
                                        <Curate id="issues.second.stuff.8" data={{ title: bindTo('issues.second.build.stuff.header').get() }}>
                                            <div className="build-segment">
                                                <henshu.richtext {...bindTo('issues.second.build.stuff.8')} />
                                            </div>
                                        </Curate>
                                    </FadeUp>
                                </Anchor>
                            </div>
                        </Anchor>
                    </div>
                </div>
                <Anchor
                    className="closer"
                    onBottom={() => {setBuildingExpanded(true); setBuildingPosition('fixed')}}
                    onOffscreen={a => {
                        if (a.top <= 0) {
                             setBuildIndex(22);
                            setBuildingExpanded(false);
                            setBuildingPosition('bottom');
                        }
                    }}
                />
            </div>

            <Section>
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.first.standing.front')}
                        back={bindTo('issues.first.standing.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="sub booking">
                <Curate id="issues.second.booking" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                    <FadeUp>
                        <henshu.h2 {...bindTo('issues.second.booking.header.1')} />
                    </FadeUp>

                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.second.booking.sub.1')} />
                    </FadeUp>
                </Curate>

                <div className="green-outline">
                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p12" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.1')} />
                                </div>
                                <span>or</span>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.2')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p34" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.3')} />
                                </div>
                                <span>or</span>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.4')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p5" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.5')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="table-friend">
                        <FadeUp>
                            <DoubleImage
                                front={bindTo('issues.second.table-friend.front')}
                                back={bindTo('issues.second.table-friend.back')}
                            />
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p6" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.6')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p6" data={{ title: bindTo('issues.second.booking.sub.1').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.7')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.second.booking.sub.2')} />
                    </FadeUp>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p6" data={{ title: bindTo('issues.second.booking.sub.2').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.8')} />
                                </div>
                            </Curate>
                        </FadeUp>

                        <div className="phone-friend">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.second.phone-friend.front')}
                                    back={bindTo('issues.second.phone-friend.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p6" data={{ title: bindTo('issues.second.booking.sub.2').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.9')} />
                                </div>
                                <span>or</span>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.10')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>
                </div>

                <FadeUp>
                    <henshu.h2 {...bindTo('issues.second.booking.header.2')} />
                </FadeUp>

                <ul className="intro">
                    <henshu.each {...bindTo('issues.second.booking.features')}>
                        {(bindToFeature, i) => (
                            <li>
                                <FadeUp>
                                    <Curate id={`issues.second.features.${i+1}`} data={{ title: bindTo('issues.second.booking.header.2').get() }}>
                                        <DoubleImage
                                            frontMod={.1}
                                            backMod={-.1}
                                            front={bindToFeature('image.front')}
                                            back={bindToFeature('image.back')}
                                        />

                                        <henshu.p {...bindToFeature('text')} />
                                    </Curate>
                                </FadeUp>
                            </li>
                        )}
                    </henshu.each>
                </ul>

                <FadeUp>
                    <henshu.h2 {...bindTo('issues.second.booking.header.3')} />
                </FadeUp>

                <div className="gold-disc">
                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p11" data={{ title: bindTo('issues.second.booking.header.3').get() }}>
                                <div>
                                    <Splotch />
                                    <henshu.p {...bindTo('issues.second.booking.p.11')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p12" data={{ title: bindTo('issues.second.booking.header.3').get() }}>
                                <div>
                                    <Splotch />
                                    <henshu.p {...bindTo('issues.second.booking.p.12')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="max-friend">
                        <FadeUp>
                            <DoubleImage
                                front={bindTo('issues.second.max-friend.front')}
                                back={bindTo('issues.second.max-friend.front')}
                            />
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p13" data={{ title: bindTo('issues.second.booking.header.3').get() }}>
                                <div>
                                    <Splotch />
                                    <henshu.p {...bindTo('issues.second.booking.p.13')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="glass-friend">
                        <FadeUp>
                            <DoubleImage
                                front={bindTo('issues.second.glass-friend.front')}
                                back={bindTo('issues.second.glass-friend.back')}
                            />
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p14" data={{ title: bindTo('issues.second.booking.header.3').get() }}>
                                <div>
                                    <Splotch />
                                    <henshu.p {...bindTo('issues.second.booking.p.14')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p15" data={{ title: bindTo('issues.second.booking.header.3').get() }}>
                                <div>
                                    <Splotch />
                                    <henshu.p {...bindTo('issues.second.booking.p.15')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>
                </div>

                <div className="mauve-outline">
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.second.booking.sub.4')} />
                    </FadeUp>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p1617" data={{ title: bindTo('issues.second.booking.sub.4').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.16')} />
                                </div>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.17')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p1819" data={{ title: bindTo('issues.second.booking.sub.4').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.18')} />
                                </div>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.19')} />
                                </div>
                            </Curate>
                        </FadeUp>

                        <div className="octo-friend">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.second.octo-friend.front')}
                                    back={bindTo('issues.second.octo-friend.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p20" data={{ title: bindTo('issues.second.booking.sub.4').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.20')} />
                                </div>
                            </Curate>
                        </FadeUp>

                        <div className="thinking-friend">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.second.thinking-friend.front')}
                                    back={bindTo('issues.second.thinking-friend.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p21" data={{ title: bindTo('issues.second.booking.sub.4').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.21')} />
                                </div>
                            </Curate>
                        </FadeUp>

                        <div className="abc-friend">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.second.abc-friend.front')}
                                    back={bindTo('issues.second.abc-friend.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>

                    <div className="booking-segment">
                        <FadeUp>
                            <Curate id="issues.second.booking.p2223" data={{ title: bindTo('issues.second.booking.sub.4').get() }}>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.22')} />
                                </div>
                                <div>
                                    <Outline />
                                    <henshu.p {...bindTo('issues.second.booking.p.23')} />
                                </div>
                            </Curate>
                        </FadeUp>
                    </div>
                </div>
            </Section>

            <Section className="reserved">
                <Curate id="issues.second.reserved" data={{ title: bindTo('issues.second.reserved.header').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.second.reserved.header')} />
                    </FadeUp>

                    <FadeUp>
                        <DoubleImage
                            frontMod={.05}
                            backMod={.1}
                            front={bindTo('issues.second.reserved.img.front')}
                            back={bindTo('issues.second.reserved.img.back')}
                        />
                    </FadeUp>

                    <FadeUp>
                        <henshu.richtext {...bindTo('issues.second.reserved.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="letters">
                <FadeUp>
                    <Curate id="issues.second.convo1" data={{
                        title: bindTo('issues.second.convo1.title').get(),
                        author: bindTo('issues.second.convo1.footer').get(),
                    }}>
                        <ContentPanel
                            className="quoted green"
                            title="issues.second.convo1.title"
                            body="issues.second.convo1.body"
                            footer="issues.second.convo1.footer"
                        />
                    </Curate>
                </FadeUp>

                <div className="collab-friend">
                    <FadeUp>
                        <DoubleImage
                            front={bindTo('issues.second.collab-friend.front')}
                            back={bindTo('issues.second.collab-friend.back')}
                        />
                    </FadeUp>
                </div>

                <FadeUp>
                    <Curate id="issues.second.convo2" data={{
                        title: bindTo('issues.second.convo2.title').get(),
                        author: bindTo('issues.second.convo2.footer').get(),
                    }}>
                        <ContentPanel
                            className="quoted gold"
                            title="issues.second.convo2.title"
                            body="issues.second.convo2.body"
                            footer="issues.second.convo2.footer"
                        />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="sub">
                <Curate id="issues.second.qa" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <henshu.h3 {...bindTo('issues.second.qa.title')} />
                    <henshu.p {...bindTo('issues.second.qa.body')} />
                </Curate>

                <Curate id="issues.second.qa.1" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <QA name="issues.second.qa.1" />
                </Curate>

                <div className="scarf-friend">
                    <FadeUp>
                        <DoubleImage
                            backMod={-.05}
                            front={bindTo('issues.second.scarf-friend.front')}
                            back={bindTo('issues.second.scarf-friend.back')}
                        />
                    </FadeUp>
                </div>

                <Curate id="issues.second.qa.2" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <QA name="issues.second.qa.2" />
                </Curate>

                <Curate id="issues.second.qa.3" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <QA name="issues.second.qa.3" />
                </Curate>

                <Curate id="issues.second.qa.4" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <QA name="issues.second.qa.4" />
                </Curate>

                <Curate id="issues.second.qa.5" data={{ title: bindTo('issues.second.qa.title').get() }}>
                    <div className="QA">
                        <FadeUp>
                            <p className="Q">
                                Q: <span>Who’s doing all these bananas illustrations and web design?</span>
                            </p>
                            <p className="A">
                                A:
                                <span>
                                    The illustrations are from our very own Rae Pozdro, Communications Manager. You can see more of her work <a target="_blank" rel="noopener noreferrer" href="https://pozdro.net">here</a>.
                                    The user interface design, experience and code was all built in-house by a few members of our most excellent Strategies team: Timothy Gale, Eric Youngberg, and David Morgan!
                                </span>
                            </p>
                        </FadeUp>

                        <div className="handphone-friend">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.second.handphone-friend.front')}
                                    back={bindTo('issues.second.handphone-friend.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>
                </Curate>
            </Section>

            <Section>
                <FadeUp>
                    <DoubleImage
                        front={bindTo('issues.second.footer-friend.front')}
                        back={bindTo('issues.second.footer-friend.back')}
                        frontMod={0}
                        backMod={-.15}
                    />
                </FadeUp>
            </Section>
        </Issue>
    );
});
