import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { FiLayers, FiChevronDown, FiChevronUp, FiHelpCircle, FiSearch } from 'react-icons/fi';

import Search from './Search';
import FadeUp from '../animations/FadeUp';
import Page from '../Page/Page';
import Section from '../Section/Section';
import OfficeStatus from './OfficeStatus';

import linework from '../../assets/images/printer_linework.png';
import SasakiLogo from '../../assets/images/sasaki_logo_RGB-05.png';
import SasakiFooter from '../../assets/images/sasaki-footer.png';


export default observer(function Index() {
    const { bindTo } = useHenshu();

    return (
        <Page className="Index">
            <header>
                <henshu.a href="#office" className="banner" {...bindTo('banner')} />

                <Section>
                    <hgroup>
                        <div>
                            <img src={SasakiLogo} alt="Sasaki logo" />
                        </div>
                        <henshu.h1 {...bindTo('intro.welcome')} />
                    </hgroup>

                    <div>
                        <img src={linework} alt="" aria-hidden="true" />

                        <ul>
                            <henshu.h2 {...bindTo('intro.in-this')} />
                            <li>
                                <FiLayers />
                                <henshu.a href="#office" {...bindTo('intro.office')} />
                            </li>
                            <li>
                                <FiSearch />
                                <henshu.a href="#search" {...bindTo('intro.search')} />
                            </li>
                             <li>
                                <FiHelpCircle />
                                <henshu.a href="#questions" {...bindTo('intro.questions')} />
                            </li>
                       </ul>
                    </div>
                </Section>

                <a href="#office">
                    <FadeUp auto>
                        <FiChevronDown />
                    </FadeUp>
                </a>
            </header>

            <Section className="office">
                <div className="anchor" id="office" />
                <henshu.h2 {...bindTo('intro.office.title')} />
                <henshu.p {...bindTo('intro.office.updated')} />
                <OfficeStatus />
            </Section>

            <Section className="previous">
                <henshu.h2 {...bindTo('intro.previous.title')} />
                <div>
                    <a href={"/issues/one"} rel="noopener noreferrer" target="_blank">
                        <henshu.img {...bindTo('issues.first.banner.front')} />
                    </a>
                    <a href={"/issues/two"} rel="noopener noreferrer" target="_blank">
                        <henshu.img {...bindTo('issues.second.banner.front')} />
                    </a>
                    <a href={"/issues/three"} rel="noopener noreferrer" target="_blank">
                        <henshu.img {...bindTo('issues.third.banner.front')} />
                    </a>
                    <a href={"/issues/four"} rel="noopener noreferrer" target="_blank">
                        <henshu.img {...bindTo('issues.fourth.banner.front')} />
                    </a>
                    <a href={"/issues/five"} rel="noopener noreferrer" target="_blank">
                        <henshu.img {...bindTo('issues.fifth.banner.front')} />
                    </a>
                </div>
            </Section>

            <Section className="search">
                <div className="anchor" id="search" />
                <div className="col">
                    <henshu.h2 {...bindTo('intro.search.title')} />
                    <henshu.p {...bindTo('intro.search.prompt')} />
                </div>
                <Search />
            </Section>

            <Section className="questions">
                <div className="anchor" id="questions" />
                <henshu.h2 {...bindTo('intro.questions.title')} />

                <henshu.each {...bindTo('intro.questions.sections')}>
                    {(bindToSection, i) => <div key={i}>
                        <henshu.h3 {...bindToSection('title')} />

                        <henshu.each {...bindToSection('questions')}>
                            {(bindToItem, j) => <div key={`${i}${j}`}><henshu.richtext {...bindToItem('content')} /></div>}
                        </henshu.each>
                    </div>}
                </henshu.each>
            </Section>

            <Section className="contact">
                <henshu.richtext {...bindTo('intro.contact')} />
            </Section>

            <footer>
                <Section>
                    <henshu.richtext {...bindTo('intro.footer')} />
                    <img src={SasakiFooter} alt="" aria-hidden="true" />

                    <button onClick={() => window.scrollTo(0,0)}>
                        <FiChevronUp />
                    </button>
                </Section>
            </footer>
        </Page>
    );
});

