import { observer } from 'mobx-react';
import { useHenshuApp, Curate } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';

import ContentPanel from '../../ContentPanel/ContentPanel';
import DoubleImage from '../../DoubleImage/DoubleImage';
import FadeUp from '../../animations/FadeUp';
import Issue from '../Issue/Issue';
import Section from '../../Section/Section';
import Letter from '../../Letter/Letter';

import SasakiLogoWhite from '../../../assets/images/sasaki-logo-white.png';


export default observer(function FifthIssue() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;

    return <>
        <Issue className="FifthIssue">
            <header className="heavy-padding">
                <div>
                    <img className="sasaki-logo" src={SasakiLogoWhite} alt="Sasaki"/>

                    <henshu.img
                        className="gif"
                        alt="Hello 110 Chauncy"
                        get={() => bindTo('he110chauncy').get()}
                        set={async buf => bindTo('he110chauncy').set(await upload(buf))}
                    />
                </div>

                <div>
                    <henshu.h1 {...bindTo('issues.first.title')} />
                </div>
            </header>

            <Section className="banner">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.fifth.banner.front')}
                        back={bindTo('issues.fifth.banner.back')}
                    />
                </FadeUp>
            </Section>

            <Section>
                <FadeUp>
                    <Curate id="issues.fifth.letter" data={{ title: 'What is this "hybrid"?', author: "Varda Halidy + Holly St. Clair" }}>
                        <Letter name="issues.fifth.letter" />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        frontMod={.1}
                        backMod={.18}
                        front={bindTo('cyborg-friend.front')}
                        back={bindTo('cyborg-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="lone-header heavy-padding">
                <Curate id="issues.fifth.equity" data={{ title: bindTo("issues.fifth.equity.header").get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo("issues.fifth.equity.header")} />
                    </FadeUp>

                    <FadeUp>
                        <henshu.richtext {...bindTo('issues.fifth.equity.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="equity">
                <henshu.each {...bindTo('issue.fifth.equity.items')}>
                    {(bindToEach, i) => (
                        <div className="side-by-side">
                            <Curate key={i} id={`issues.fifth.equity.${i+1}`} data={{ title: bindTo("issues.fifth.equity.header").get() }}>
                                <FadeUp>
                                    <henshu.richtext className="sans" {...bindToEach('body')} />
                                    <DoubleImage
                                        front={bindToEach('image.front')}
                                        back={bindToEach('image.back')}
                                    />
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext {...bindToEach('list')} />
                                </FadeUp>
                            </Curate>
                        </div>
                    )}
                </henshu.each>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('dog-walker-friend.front')}
                        back={bindTo('dog-walker-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="lone-header heavy-padding">
                <Curate id="issues.fifth.strategies" data={{ title: bindTo("issues.fifth.strategies.header").get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo("issues.fifth.strategies.header")} />
                    </FadeUp>

                    <FadeUp>
                        <henshu.p {...bindTo('issues.fifth.strategies.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="strategies">
                <henshu.each {...bindTo('issue.fifth.strategies.items')}>
                    {(bindToEach, i) => (
                        <div className="side-by-side">
                            <Curate key={i} id={`issues.fifth.strategies.${i+1}`} data={{ title: bindTo("issues.fifth.strategies.header").get() }}>
                                <FadeUp>
                                    <henshu.richtext className="sans" {...bindToEach('body')} />
                                    <DoubleImage
                                        front={bindToEach('image.front')}
                                        back={bindToEach('image.back')}
                                    />

                                    {i === 4 && <>
                                        <DoubleImage
                                            className="second-image"
                                            front={bindToEach('image.front2')}
                                            back={bindToEach('image.back2')}
                                        />
                                    </>}
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext {...bindToEach('list')} />
                                </FadeUp>
                            </Curate>
                        </div>
                    )}
                </henshu.each>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('laptop-friend.front')}
                        back={bindTo('laptop-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="more-strategies">
                <henshu.each {...bindTo('issue.fifth.more-strategies.items')}>
                    {(bindToEach, i) => (
                        <div className="side-by-side">
                            <Curate key={i} id={`issues.fiftfh.more-strategies.${i+1}`} data={{ title: bindTo("issues.fifth.strategies.header").get() }}>
                                <FadeUp>
                                    <henshu.richtext className="sans" {...bindToEach('body')} />
                                    {(i === 1 || i === 3) && (
                                        <DoubleImage
                                            front={bindToEach('image.front')}
                                            back={bindToEach('image.back')}
                                        />
                                    )}
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext {...bindToEach('list')} />
                                </FadeUp>
                            </Curate>
                        </div>
                    )}
                </henshu.each>
            </Section>

            <Section className="lone-header heavy-padding">
                <Curate id="issues.fifth.stuff" data={{ title: bindTo("issues.fifth.stuff.header").get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo("issues.fifth.stuff.header")} />
                    </FadeUp>

                    <FadeUp>
                        <henshu.p {...bindTo('issues.fifth.stuff.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="stuff">
                <henshu.each {...bindTo('issue.fifth.stuff.items')}>
                    {(bindToEach, i) => (
                        <div className="side-by-side">
                            <Curate key={i} id={`issues.fifth.stuff.${i+1}`} data={{ title: bindTo("issues.fifth.stuff.header").get() }}>
                                <FadeUp>
                                    <henshu.h4 {...bindToEach('title')} />
                                    <henshu.richtext className="sans" {...bindToEach('body')} />
                                    <DoubleImage
                                        front={bindToEach('image.front')}
                                        back={bindToEach('image.back')}
                                    />

                                    {i === 0 && <>
                                        <DoubleImage
                                            className="second-image"
                                            front={bindToEach('image.front2')}
                                            back={bindToEach('image.back2')}
                                        />
                                    </>}
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext {...bindToEach('list')} />
                                </FadeUp>
                            </Curate>
                        </div>
                    )}
                </henshu.each>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('guy-at-desk-friend.front')}
                        back={bindTo('guy-at-desk-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section>
               <FadeUp>
                   <Curate id="issues.fifth.convo1" data={{
                       title: bindTo('issues.fifth.convo1.title').get(),
                       author: bindTo('issues.fifth.convo1.footer').get(),
                   }}>
                        <ContentPanel
                            className="quoted green"
                            title="issues.fifth.convo1.title"
                            body="issues.fifth.convo1.body"
                            footer="issues.fifth.convo1.footer"
                        />
                    </Curate>
                </FadeUp>
                <FadeUp className="waving-kid-friend">
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('waving-kid-friend.front')}
                        back={bindTo('waving-kid-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('car-friend.front')}
                        back={bindTo('car-friend.back')}
                    />
                </FadeUp>
            </Section>
        </Issue>
    </>;
});

