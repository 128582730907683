import React, { useCallback } from "react";


export default function Floor6({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
                        <pattern
          id="L6_x5F_combo_x5F_workstation_00000099628605243640841040000017206590795696001667_"
          patternTransform="rotate(45.001 -6846.635 -6771.73)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L6_x5F_combo_x5F_workstation_00000151519211490544927940000011098516809925828506_"
          fill="url(#L6_x5F_combo_x5F_workstation_00000099628605243640841040000017206590795696001667_)"
          d="M1070.773 624.263c-2.111-23.808-16.857-47.487-39.568-54.934-31.379-10.289-66.132 11.838-97.975 3.091-20.481-5.626-35.491-22.895-54.289-32.782-13.28-6.985-28.525-10.182-43.493-9.122-12.499.885-25.117 4.909-34.419 13.303-18.174 16.401-19.157 45.873-8.522 67.924 10.635 22.05 30.326 38.201 49.992 52.779 16.462 12.203 33.381 23.862 51.364 33.685 12.732 6.954 26.469 13.092 40.974 13.318 10.561.164 20.9-2.823 30.935-6.117a474.075 474.075 0 0055.338-22.107c12.327-5.848 24.62-12.388 34.174-22.128 9.555-9.74 16.699-23.271 15.489-36.91z"
          display="inline"
        ></path>
        <path
            {...assignIndex(12)}
          id="L6_x5F_respite_x5F_wellness_00000046311702280759695540000007650307832892774589_"
          fill="#915C00"
          d="M881.213 509.297c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386 3.942 2.078 7.036 6.346 6.464 10.765-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.889-39.196 41.177z"
          display="inline"
        ></path>
        <path
            {...assignIndex(14)}
            stroke="#915C00"
            strokeWidth={5}
          id="L6_x5F_respite_x5F_phone_00000047762443059022115050000015861711856517314740_"
          fill="#D9A300"
          d="M1117.283 499.114c-.53-3.043-2.874-5.697-5.828-6.597-3.958-1.207-8.233.649-11.513 3.171-3.649 2.806-6.672 6.595-7.908 11.03-.935 3.354-.517 7.542 2.387 9.462 1.285.85 2.87 1.122 4.41 1.167 4.926.143 9.873-1.95 13.201-5.584s5.922-8.796 5.251-12.649z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L6_x5F_collab_x5F_open_collab_space_00000051383194881839918770000016030441621966379924_"
          fill="#A88CAD"
          d="M1167.887 527.709c-5.732-4.193-13.363-3.955-19.778-1.259-6.415 2.696-11.887 7.542-17.243 12.314-9.049 8.062-18.377 16.5-23.527 28.068-3.428 7.699-3.478 19.398 3.731 22.442 4.032 1.703 8.508-.242 12.384-2.349 13.796-7.501 26.526-17.433 37.573-29.314 3.339-3.591 6.606-7.497 8.255-12.332 2.037-5.974 2.662-14.602-1.395-17.57z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L6_x5F_collab_x5F_conf-room_00000026856872923599940270000009353666725106165429_"
          fill="#C24DFF"
          d="M740.501 438.452c-.779-2.778-1.606-5.649-3.469-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.905 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.459 4.54 3.313 9.246 7.069 12.184 3.756 2.938 9.958 3.241 12.827-.568 1.178-1.564 1.63-3.545 1.95-5.476 2.552-15.415-1.848-31.847-7.115-35.437z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L6_x5F_collab_x5F_conf-room_00000013188877123811238090000009131027463374101663_"
          fill="#C24DFF"
          d="M1205.984 315.691c-8.146-4.831-18.554-1.143-26.977 3.188-35.119 18.054-64.823 46.485-84.398 80.78-5.732 10.043-10.563 22.8-4.568 32.688 4.173 6.883 12.743 10.172 20.788 9.912s15.68-3.521 22.819-7.237c29.478-15.346 54.253-39.584 70.24-68.72 5.701-10.39 10.367-21.619 11.255-33.438.337-4.48.01-9.332-2.753-12.875-2.762-3.542-8.677-4.846-6.406-4.298z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L6_x5F_collab_x5F_conf-room_00000078752468660740811150000012262488273547078795_"
          fill="#C24DFF"
          d="M975.975 502.148c5.84-.365 11.586 1.767 16.651 4.698 7.119 4.119 13.814 11.229 12.869 19.399-.755 6.52-6.116 11.471-11.464 15.276-5.694 4.051-11.917 7.59-18.755 9.029-6.839 1.439-14.398.562-20.039-3.564-5.64-4.126-8.827-11.834-6.579-18.451 1.081-3.182 3.246-5.862 5.369-8.468l6.961-8.545c-.001-.001 9.147-9.009 14.987-9.374z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L6_x5F_collab_x5F_pinup"
          fill="#C24DFF"
          d="M1021.169 417.947c-.147-4.953-.095-9.912.154-14.861.093-1.851.268-3.841 1.463-5.257 1-1.185 2.538-1.748 4.012-2.228a102.338 102.338 0 0132.049-5.019c1.679.007 3.477.094 4.829 1.09 1.972 1.452 2.272 4.232 2.392 6.679.499 10.18.848 20.368 1.047 30.559.018.928.026 1.897-.387 2.727-.571 1.148-1.812 1.779-2.989 2.289-10.346 4.479-21.826 5.471-33.085 6.059-1.56.081-3.202.14-4.593-.57-1.347-.688-2.258-1.999-2.978-3.329-3.29-6.069-1.914-18.139-1.914-18.139z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L6_x5F_collab_x5F_pinup_00000135670344180176325120000005907397294826166203_"
          fill="#C24DFF"
          d="M1136.029 492.204l-46.553 35.19c-4.807 3.634-10.382 7.47-16.321 6.444-4.796-.829-8.425-4.624-12.526-7.246s-10.228-3.802-13.325-.047c-1.416 1.718-1.793 4.04-2.118 6.242-1.655 11.211-2.766 24.231 5.174 32.318 4.418 4.5 10.763 6.415 16.822 8.165 3.294.951 6.702 1.912 10.102 1.47 3.478-.452 6.59-2.326 9.528-4.242 11.893-7.753 22.833-16.862 33.742-25.948 6.743-5.616 13.725-11.547 17.141-19.63 3.315-7.843 2.841-16.698 2.31-25.195-.12-1.915-.278-3.943-1.398-5.501-1.119-1.558-3.566-2.322-2.578-2.02z"
          display="inline"
        ></path>
        <path
            {...assignIndex(3)}
          id="L6_x5F_collab_x5F_lounge_00000106852776273130120210000007410402081609053343_"
          fill="#C24DFF"
          d="M914.948 535.711c-9.311-2.586-17.271-6.407-17.271-17.736 0-12.301 17.414-31.452 28.356-37.245s25.032-.488 34.459 7.538c7.22 6.147-11.037 30.658-14.063 34.276-7.036 8.412-11.227 18.793-31.481 13.167z"
          display="inline"
        ></path>
        <path
            {...assignIndex(17)}
          id="L6_x5F_collab_x5F_materials_libraries_00000011716625375318036080000009580406976186515615_"
          fill="#FF002E"
          d="M837.121 498.093c-2.695-10.807-9.27-20.603-18.252-27.19-3.29-2.413-6.999-4.443-11.047-4.958-5.961-.759-11.786 1.817-17.244 4.331l-19.761 9.103c-4.681 2.156-9.455 4.375-13.188 7.928-6.687 6.366-9.051 16.109-9.252 25.339-.404 18.54 6.963 37.128 19.959 50.358 1.993 2.028 4.177 3.977 6.833 4.994 2.797 1.071 5.895 1.021 8.872.687 16.61-1.86 31.412-12.069 42.083-24.933 11.272-13.587 14.514-31.553 10.997-45.659z"
          display="inline"
        ></path>
        <path
            {...assignIndex(17)}
          id="L6_x5F_collab_x5F_materials_libraries_00000063632308807761139370000005826933796444152995_"
          fill="#FF002E"
          d="M1307.902 394.704c-4.097-9.696-11.682-17.349-19.567-24.142-8.867-7.639-18.867-14.751-30.374-16.309-17.663-2.391-34.218 8.613-48.754 19.288-25.1 18.433-51.259 39.434-60.496 69.6-2.523 8.239-3.59 17.45-.122 25.282 2.459 5.554 6.981 9.889 11.911 13.296 18.537 12.809 43.111 13.627 64.704 7.322 21.594-6.304 40.861-18.956 59.531-31.792 6.831-4.696 13.355-14.034 17.932-22.514 7.538-13.966 9.332-30.334 5.235-40.031z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L6_x5F_store_x5F_supplies_00000111179797197933528580000005352143569281877408_"
          fill="#FF002E"
          d="M784.1 418.822c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083-3.007 1.797-5.445 4.923-5.49 8.425-.054 4.279 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325s2.67-10.233-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(18)}
          id="L6_x5F_store_x5F_shelves_00000056398844750465221530000005777902052709596561_"
          fill="#FF002E"
          d="M850.738 537.979c.215 5.896.579 12.222 4.199 16.881 1.623 2.088 3.789 3.676 5.925 5.235 12.153 8.87 25.413 18.122 40.459 18.177 1.329.005 2.744-.092 3.821-.871 2.312-1.672 1.738-5.254.607-7.874-6.845-15.869-23.754-24.584-39.71-31.224-2.735-1.138-5.585-2.273-8.546-2.161s-6.073 1.819-6.755 1.837z"
          display="inline"
        ></path>
        <path
            {...assignIndex(18)}
          id="L6_x5F_store_x5F_shelves_00000063635588148262779300000008881784699312018094_"
          fill="#FF002E"
          d="M859.286 643.723a224.134 224.134 0 0133.856-34.745c2.062-1.701 4.278-3.422 6.912-3.87 2.635-.448 5.766.864 6.418 3.456.451 1.795-.355 3.644-1.181 5.3-7.161 14.356-17.552 27.39-31.177 35.858-3.544 2.202-7.888 4.131-11.766 2.593s-5.04-8.235-3.062-8.592z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L6_x5F_store_x5F_print_area_00000041991393632356164910000011535315276210753977_"
          fill="#FF002E"
          d="M955.892 439.713c-7.66-3.661-7.586-15.306-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.668-6.941 5.845-9.279 3.767-21.604 8.859-28.067 5.77z"
          display="inline"
        ></path>
        </svg>
    );
}
