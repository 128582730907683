import React, { useCallback } from "react";

export default function Floor7({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >

               <pattern
        id="New_Pattern"
        width="376.471"
        height="376.471"
        overflow="visible"
        patternUnits="userSpaceOnUse"
        viewBox="0 -376.471 376.471 376.471"
      >
        <path fill="none" d="M0 -376.471H376.471V0H0z"></path>
        <path fill="#BAD9C9" d="M0 -336.235H376.471V-329.411H0z"></path>
        <path fill="#BAD9C9" d="M0 -324.471H376.471V-317.647H0z"></path>
        <path fill="#A88CAD" d="M0 -329.412H376.471V-324.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -312.706H376.471V-305.882H0z"></path>
        <path fill="#A88CAD" d="M0 -317.647H376.471V-312.706H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -300.941H376.471V-294.11699999999996H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -305.882H376.471V-300.94100000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -289.176H376.471V-282.352H0z"></path>
        <path fill="#A88CAD" d="M0 -294.118H376.471V-289.177H0z"></path>
        <path fill="#BAD9C9" d="M0 -371.529H376.471V-364.705H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -376.471H376.471V-371.53000000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -359.765H376.471V-352.941H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -364.706H376.471V-359.76500000000004H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -348H376.471V-341.176H0z"></path>
        <path fill="#A88CAD" d="M0 -352.941H376.471V-348H0z"></path>
        <path fill="#A88CAD" d="M0 -341.176H376.471V-336.235H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -242.118H376.471V-235.29399999999998H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -230.353H376.471V-223.529H0z"></path>
        <path fill="#A88CAD" d="M0 -235.294H376.471V-230.353H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -218.588H376.471V-211.76399999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -223.529H376.471V-218.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -206.824H376.471V-200H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -211.765H376.471V-206.82399999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -195.059H376.471V-188.23499999999999H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -200H376.471V-195.059H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -277.412H376.471V-270.58799999999997H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -282.353H376.471V-277.41200000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -265.647H376.471V-258.823H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -270.588H376.471V-265.64700000000005H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -253.882H376.471V-247.058H0z"></path>
        <path fill="#A88CAD" d="M0 -258.824H376.471V-253.883H0z"></path>
        <path fill="#A88CAD" d="M0 -247.059H376.471V-242.118H0z"></path>
        <path fill="#BAD9C9" d="M0 -148H376.471V-141.176H0z"></path>
        <path fill="#BAD9C9" d="M0 -136.235H376.471V-129.411H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -141.176H376.471V-136.23499999999999H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -124.471H376.471V-117.647H0z"></path>
        <path fill="#A88CAD" d="M0 -129.412H376.471V-124.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -112.706H376.471V-105.882H0z"></path>
        <path fill="#A88CAD" d="M0 -117.647H376.471V-112.706H0z"></path>
        <path fill="#BAD9C9" d="M0 -100.941H376.471V-94.117H0z"></path>
        <path fill="#A88CAD" d="M0 -105.882H376.471V-100.941H0z"></path>
        <path fill="#BAD9C9" d="M0 -183.294H376.471V-176.47H0z"></path>
        <path fill="#A88CAD" d="M0 -188.235H376.471V-183.294H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -171.529H376.471V-164.70499999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -176.471H376.471V-171.53H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -159.765H376.471V-152.94099999999997H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -164.706H376.471V-159.765H0z"></path>
        <path fill="#A88CAD" d="M0 -152.941H376.471V-148H0z"></path>
        <path fill="#BAD9C9" d="M0 -53.882H376.471V-47.058H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -42.118H376.471V-35.294000000000004H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -47.059H376.471V-42.117999999999995H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -30.353H376.471V-23.529000000000003H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -35.294H376.471V-30.352999999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -18.588H376.471V-11.764000000000001H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -23.529H376.471V-18.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -6.824H376.471V0H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -11.765H376.471V-6.824000000000001H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -89.176H376.471V-82.352H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -94.118H376.471V-89.17699999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -77.412H376.471V-70.58800000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -82.353H376.471V-77.41199999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -65.647H376.471V-58.82300000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -70.588H376.471V-65.64699999999999H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -58.824H376.471V-53.882999999999996H0z"
        ></path>
                </pattern>
        <path
            {...assignIndex(12)}
          id="L7_x5F_respite_x5F_wellness"
          fill="#915C00"
          d="M881.213 461.297c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386 3.942 2.078 7.036 6.346 6.464 10.765-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.889-39.196 41.177z"
          display="inline"
        ></path>
        <pattern
          id="L7_x5F_combo_x5F_workstation_00000016053069595580402550000015939690383451663286_"
          patternTransform="rotate(45.001 -6911.23 -6744.973)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L7_x5F_combo_x5F_workstation_00000076561334092510428750000000729972082038977674_"
          fill="url(#L7_x5F_combo_x5F_workstation_00000016053069595580402550000015939690383451663286_)"
          d="M1259.833 404.122c-1.83-18.356-16.465-33.248-32.945-41.537-40.651-20.447-93.518-6.557-124.26 26.992-6.031 6.582-11.523 14.238-12.738 23.082-2.585 18.814 14.267 34.158 29.908 44.93 6.013 4.141 12.205 8.223 19.163 10.437 8.057 2.564 16.72 2.485 25.134 1.647 30.618-3.048 60.161-16.087 83.047-36.654 8.661-7.783 13.604-19.737 12.691-28.897z"
          display="inline"
        ></path>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L7_x5F_combo_x5F_workstation_00000005251316881307299620000013461795485152423839_"
          fill="url(#New_Pattern)"
          d="M965.325 658.752c10.804 5.753 24.389.149 34.717-6.315 13.729-8.592 26.283-18.806 37.263-30.317 9.41-9.865 18.032-21.688 17.916-34.872-.147-16.714-14.594-30.758-30.843-37.919-16.249-7.161-34.469-9.181-51.878-13.373-31.704-7.634-60.748-22.501-89.476-37.117-20.997-10.683-45.272-21.619-67.887-14.328-21.216 6.84-33.766 29.054-32.525 49.894 1.242 20.84 13.987 39.888 30.26 54.27 16.274 14.382 36.002 24.836 55.24 35.601 32.923 18.421 67.847 38.896 97.213 34.476z"
        ></path>
        <pattern
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L7_x5F_combo_x5F_workstation_00000115516784830661417790000016569344333097864608_"
          patternTransform="rotate(45.001 -6911.23 -6744.973)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
            display="none"
          id="L7_x5F_combo_x5F_workstation_00000044870765557364829870000013058362389851765415_"
          fill="url(#L7_x5F_combo_x5F_workstation_00000115516784830661417790000016569344333097864608_)"
          d="M1036.254 601.583c-4.775 16.04-19.167 27.042-32.958 36.523-17.625 12.115-36.444 23.932-57.649 26.719-12.562 1.651-25.546-.028-37.275-4.82-6.651-2.717-12.975-6.48-17.89-11.72-7.575-8.076-11.255-18.977-14.293-29.624-3.853-13.501-6.997-28.051-2.616-41.389 3.682-11.208 12.223-20.084 20.964-28.008 4.806-4.356 9.775-8.571 15.261-12.032 15.881-10.017 35.95-13.143 54.126-8.431 8.713 2.259 16.883 6.192 25.318 9.334 7.746 2.886 22.556 8.72 29.595 13.055 22.291 13.729 21.837 35.545 17.417 50.393z"
        ></path>
        <path
            {...assignIndex(10)}
          id="L7_x5F_focus_x5F_distraction_free"
          fill="#57D493"
          d="M1144.082 470.329l-.005-.012c-1.889-4.499-6.563-7.258-11.391-6.549-12.777 1.877-24.662 8.522-33.914 16.986-11.259 10.299-19.231 23.151-27.062 35.831-3.731 6.041-7.238 14.248-1.986 19.255 4.373 4.169 11.862 3.003 17.89 1.275 22.994-6.592 45.957-17.949 57.512-37.285 2.958-4.949 5.115-10.465 4.921-16.102-.138-3.993-1.613-8.047-2.833-9.204-1.284-1.22-2.446-2.562-3.132-4.195z"
          display="inline"
        ></path>
        <path
            {...assignIndex(3)}
          id="L7_x5F_collab_x5F_lounges_00000001662687769893749150000009212164159384932236_"
          fill="#C24DFF"
          d="M1239.674 389.127c-4.169-18.106 1.473-37.056 12.622-51.418 11.149-14.362 27.357-24.47 44.735-31.263 9.366-3.661 20.046-6.417 29.825-2.84 10.687 3.908 17.693 14.991 18.363 25.81.67 10.819-4.133 21.168-11.081 29.333s-15.94 14.449-24.915 20.536c-15.745 10.679-33.387 21.461-53.121 19.856-4.286-.349-8.719-1.367-12.16-3.991s-5.61-7.16-4.268-6.023z"
          display="inline"
        ></path>
        <path
            {...assignIndex(3)}
          id="L7_x5F_respite_x5F_lounge_00000076586827712110235530000013724674522059576724_"
          fill="#C24DFF"
          d="M916.721 486.251c-9.311-2.586-17.271-6.407-17.271-17.736 0-12.301 17.414-31.453 28.356-37.245s25.032-.488 34.459 7.538c7.22 6.147-11.037 30.658-14.063 34.276-7.035 8.411-11.227 18.792-31.481 13.167z"
          display="inline"
        ></path>
        <path
            {...assignIndex(6)}
          id="L7_x5F_collab_x5F_alt_workspace"
            stroke={'#C24DFF'}
            strokeWidth={5}
          fill="#D1C7D9"
          d="M828.903 474.761c1.98-8.952-.336-18.755-6.112-25.874-5.209-6.421-12.824-10.479-20.658-13.123-13.185-4.45-27.565-5.303-41.184-2.442-8.493 1.784-17.102 5.295-22.379 12.185-4.091 5.343-5.748 12.158-6.526 18.842-.577 4.953-.676 10.209 1.585 14.653 3.237 6.362 10.459 9.537 17.312 11.534 19.086 5.561 39.722 5.694 58.878.378 8.1-2.247 17.073-7.062 19.084-16.153z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L7_x5F_collab_x5F_conf-rooms_00000005263861923798921040000002849758207385921930_"
          fill="#C24DFF"
          d="M736.501 390.452c-.779-2.778-1.606-5.649-3.469-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.905 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.459 4.54 3.313 9.246 7.069 12.184 3.756 2.938 9.958 3.241 12.827-.568 1.178-1.564 1.63-3.545 1.95-5.476 2.552-15.415-1.848-31.847-7.115-35.437z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L7_x5F_collab_x5F_conf-rooms_00000066483206076766204650000011058858524012706701_"
          fill="#C24DFF"
          d="M1093.649 378.17c5.959 4.122 13.932 5.017 21.833 4.033 7.901-.984 15.831-3.723 23.678-6.497 3.412-1.206 6.856-2.438 10.07-4.379 5.278-3.187 9.595-8.068 13.781-12.839 4.242-4.833 8.653-9.961 10.434-15.917 3.299-11.031-4.151-20.951-13.919-23.206-9.769-2.255-20.962 1.314-31.34 5.788-15.533 6.696-32.256 17.455-38.157 33.807-2.566 7.111-1.776 15.477 3.62 19.21z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L7_x5F_collab_x5F_conf-rooms_00000018209471205485040780000000736597929645856430_"
          fill="#C24DFF"
          d="M977.749 452.687c5.84-.365 11.586 1.767 16.651 4.698 7.119 4.119 13.814 11.229 12.869 19.399-.755 6.52-6.116 11.471-11.464 15.276-5.694 4.051-11.917 7.59-18.755 9.028-6.839 1.439-14.398.562-20.039-3.564-5.64-4.126-8.827-11.834-6.579-18.451 1.081-3.182 3.246-5.862 5.369-8.468l6.961-8.545c-.001 0 9.146-9.008 14.987-9.373z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L7_x5F_store_x5F_display_space_00000081645351823556498530000017298993020216388518_"
          fill="#C24DFF"
          d="M1040.539 484.56c1.491-5.122 7.538-7.251 11.938-4.234a137.138 137.138 0 0019.172 10.989c2.081.979 4.226 1.931 5.925 3.481 3.539 3.228 4.486 8.394 4.69 13.18a64.322 64.322 0 01-3.767 24.55c-.537 1.485-1.156 2.993-2.264 4.118-3.57 3.627-9.655 1.503-14.182-.824-7.355-3.78-15.084-7.9-19.342-14.99-4.28-7.127-4.262-15.951-3.896-24.256.171-3.887.589-8.108 1.726-12.014z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L7_x5F_store_x5F_display_space_00000081645351823556498530000017298993020216388518_"
          fill="#C24DFF"
          d="M1021.406 352.741a364.294 364.294 0 0047.455-7.27c1.566-.347 3.357-.661 4.648.289 1.118.823 1.464 2.317 1.7 3.685a95.74 95.74 0 011.141 23.248c-.498 6.803-2.015 14.098-7.106 18.638-5.593 4.987-13.83 5.257-21.323 5.175-5.74-.063-11.48-.176-17.218-.339-3.011-.085-6.193-.237-8.711-1.889-4.539-2.979-5.022-9.328-5.035-14.758l-.035-14.933c-.007-2.781.009-5.667 1.195-8.183 1.184-2.515 3.9-4.54 3.289-3.663z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L7_x5F_store_x5F_supplies"
          fill="#FF002E"
          d="M784.1 372.653c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083-3.007 1.797-5.445 4.923-5.49 8.425-.054 4.28 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325 2.432-3.786 2.67-10.233-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(21)}
          id="L7_x5F_store_x5F_library_books"
            stroke={"#FF002E"}
            strokeWidth={5}
          fill="#B88F8F"
          d="M788.887 556.472c-14.015-11.715-22.692-29.583-23.233-47.842-.247-8.334 1.187-16.866 5.252-24.146 5.916-10.595 16.68-17.486 27.379-23.211 9.655-5.167 19.918-9.833 30.846-10.525s22.723 3.305 28.814 12.405c2.335 3.489 3.724 7.512 5.636 11.25 4 7.818 10.21 14.245 16.364 20.51 5.612 5.714 11.508 11.855 13.156 19.693 1.641 7.805-1.279 15.85-5.047 22.879-5.087 9.489-11.903 18.172-20.579 24.548-8.675 6.376-18.93 9.99-29.681 9.418-12.769-.679-34.892-3.264-48.907-14.979z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L7_x5F_store_x5F_print_area"
          fill="#FF002E"
          d="M955.683 394.502c-7.66-3.661-7.586-15.306-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.859-28.067 5.77z"
          display="inline"
        ></path>
        </svg>
    );
}
