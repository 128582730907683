import { henshu, useHenshu } from '@strategies/henshu';

import Page from '../../Page/Page';
import Section from '../../Section/Section';


export default function Issue({ children, className }) {
    const { bindTo } = useHenshu();

    return (
        <Page className={`Issue ${className}`}>
            {children}

            <Section>
                <footer>
                    <henshu.h2 {...bindTo('issues.footer.title')} />
                    <henshu.richtext {...bindTo('issues.footer.text')} />
                </footer>
            </Section>
        </Page>
    );
}
