const rand = (min, max) => Math.floor(Math.random() * (max - min) + min);


export default function Blob({ index, color }) {
    const blob = blobs[index || rand(0, blobs.length)](colors[color || rand(0, colors.length)]);

    return (
        <svg
            className="Blob"
            viewBox={blob.viewBox}
            preserveAspectRatio="none"
        >
            {blob.path}
        </svg>
    );
}

const colors = [
    '#DAE1DE',
    '#FDDBDF',
    '#E7E9ED',
    '#FFD7C8',
    '#FFED90',
];

const blobs = [
    color => ({
        viewBox: '0 0 171.36 77.37',
        path: <path fill={color} d="M165.6,5.93c-23.95-10.73-50.37-4.31-75.15-0.68C84,6.19,77.18,7.65,70.66,7.83c-4.9,0.14-10.26,0.37-15.04,1.59
			c-3.18,0.82-6.39,1.09-9.56,1.88c-4.17,1.04-8.73,2.21-13.02,2.16c-3.14-0.04-6.21-0.43-9.14,0.87c-1.43,0.64-2.49,1.43-3.99,1.95
			c-1,0.35-1.81,0.67-2.78,1.16C11.22,20.4,6.8,24.33,4.17,30.35C2.14,35,0.95,40.6,0.18,45.62c-1,6.49,2.31,13.41,6.81,17.9
			c4.41,4.4,9.93,7.28,16,8.41c2.8,0.52,5.58,1.51,8.41,1.67c4,0.22,8.1,0.34,12.03,1.12c6.29,1.25,12.76,0.61,19.07,1.65
			c2.94,0.48,5.36,1.08,8.41,0.99c3.15-0.09,5.92-1.15,8.98-1.69c2.79-0.49,5.77-0.24,8.61-0.22c4.24,0.03,7.67-0.98,11.8-1.69
			c4.01-0.69,8.15,0.13,12.18-0.14c3.96-0.26,7.65-2.01,11.51-2.68c5.93-1.02,12.21,0.67,18.11-0.4c1.86-0.34,3.83-1.01,5.61-1.67
			c3.21-1.18,7.18-1.24,10.17-2.96c3.93-2.25,5.7-7.41,7.7-11.21c1.6-3.03,3.55-4.94,4.46-8.36c1.62-6.08,1.74-12.76,0.27-18.84
			c-0.62-2.54-0.96-4.21-0.97-6.95c0-2.15,0.61-5.16-0.16-7.15c-0.37-0.94-1.48-1.86-1.98-2.8c-0.95-1.79-1.35-2.73-3.26-3.83
			c-2.17-1.24-4.36-0.88-6.81-0.87"/>
    }),
    color => ({
        viewBox: '0 0 237 47',
        path: <path fill={color} d="M227.52,5.83c-8.45-1.34-17.21-1.95-25.76-1.87c-6.63,0.06-13.17-0.91-19.84-0.96c-5.43-0.04-10.66,0.99-16.14,0.93
			c-3.26-0.04-6.27,0.87-9.49,1.01c-2.86,0.13-5.77-0.05-8.64-0.05c-13.43,0-26.58,2.3-39.98,2.82c-3.31,0.13-6.49,0.91-9.8,0.97
			c-2.92,0.06-5.84-0.04-8.76-0.04c-2.78,0-5.61-0.16-8.39-0.05c-3.07,0.13-5.93,1.28-8.95,1.74c-2.79,0.42-5.44,0.37-8.22,0.89
			c-2.43,0.46-5.08,0.82-7.53,1.09c-2.27,0.25-4.54,0.02-6.82,0.06c-2.39,0.05-4.52,0.87-6.88,1c-2.34,0.12-4.21-0.13-6.49,0.43
			c-1.62,0.4-2.99,0.41-4.67,0.44c-3.53,0.08-6.78,0.98-10.39,0.96C15.4,15.2,8.25,18.01,5.29,22.77C3.14,26.23,2.02,29.5,4.13,33.1
			c1.46,2.48,3.96,3.75,6.34,5.38c2.26,1.55,4.61,2.9,6.97,4.11c2.63,1.35,3.97,1.76,7.13,1.76c6.65,0.01,13.29,0,19.94,0
			c17.17,0,34.35,0.15,51.53,0.02c4.71-0.03,9.26-0.99,14.03-0.95c5.19,0.04,10.26-0.95,15.51-0.95c3.61,0,7.23,0.05,10.84,0
			c2.96-0.04,5.44,0.96,8.41,0.94c6.39-0.05,12.78,0,19.17,0c3,0,6.02-0.11,9.01-0.02c1.81,0.06,3.18,0.73,4.91,1.01
			c1.93,0.31,4.2-0.05,6.15-0.05c3.92,0,7.85,0,11.77,0c1.53,0,3.07,0.04,4.6,0.02c2.34-0.02,4.15-0.81,6.39-1
			c4.06-0.35,7.63,0.55,11.53-1.12c3.7-1.58,6.63-3.34,9.4-6.13c1.08-1.09,2.6-2.05,3.52-3.22c1.53-1.92,1.48-4.03,2-6.34
			c0.37-1.67,1.1-3.03,0.84-4.79c-0.17-1.22-0.74-1.65-1.01-2.75c-0.64-2.64,1.18-6.26-1.03-8.55c-1.21-1.25-3.2-1.55-4.57-2.77
			c-1.15-1.02-2.29-2.29-3.74-2.82"/>
    }),
    color => ({
        viewBox: '0 0 237 89',
        path: <path fill={color} d="M237,28.13c-2.53-4.03-4.89-8.5-9.4-10.36c-3.87-1.6-7.32-4.45-11.28-5.66c-3.97-1.21-8.13-2.02-12.16-3.03
			c-3.1-0.78-6.12-0.33-9.21-0.89c-4-0.73-7.96-0.75-12.01-1.19c-15.16-1.67-30.48-4.31-45.74-4.24c-5.91,0.03-11.82,0-17.74,0
			c-5.12,0-10.18-0.33-15.13,0.73c-4.1,0.88-8.79,0.08-12.97,0.17c-2.43,0.05-4.65,0.92-7.06,1.01c-2.55,0.1-4.54,0.26-7.03,0.69
			c-1.92,0.34-3.71,0.12-5.64,0.19c-1.91,0.07-3.53,0.71-5.4,0.99c-3.6,0.54-7.19,1.28-10.8,2.09c-8.88,1.99-17.67,4.53-26.11,7.65
			c-4.58,1.69-7.79,4.59-11.24,8.09c-2.06,2.09-4.07,3.92-6.41,5.68c-2.15,1.62-2.94,3.24-4.39,5.5c-1.32,2.05-2.61,3.27-3.33,5.69
			c-0.63,2.09-0.87,4.01-0.91,6.15c-0.07,3.92,0.01,7.86,0.01,11.78c0,3.14-0.85,7.32,1.44,9.75c3.13,3.32,8.66,5.91,12.91,7.38
			c4.7,1.61,8.96,2.83,13.84,3.71c4.81,0.87,9.32,2.81,14.15,3.55C55.5,85.11,65.99,84.5,76.3,84.5c11.31,0,22.62,0,33.93,0
			c10.74,0,21.17,0.94,31.89,0.94c4.39,0,8.77,0,13.16,0c4.47,0,8.51,0.93,12.9,0.93c3.98,0,7.55-0.99,11.55-0.92
			c1.85,0.03,2.51-0.5,4.18-0.87c1.31-0.29,2.87-0.09,4.23-0.08c1.72,0.01,3.03-0.02,4.7-0.47c1.7-0.46,2.98-0.22,4.65-0.42
			c4.33-0.52,8.25-2.69,12.49-3.6c4.69-1,9.28-1.71,13.13-4.84c2.27-1.85,3.65-4.17,5.43-6.44c1.25-1.59,2.68-2.8,3.57-4.68
			c1.53-3.22,1.92-6.93,2.78-10.34c0.95-3.78,1.17-7.15,1.17-11.04c0-3.41,0-6.81,0-10.22c0-3.15,0.48-6.33-0.94-9.04"/>
    }),
    color => ({
        viewBox: '0 0 277 58',
        path:	<path fill={color} d="M245.78,8.64c-7.96-2.5-17.03-2.85-25.37-2.82c-7.41,0.03-14.58,0.96-21.97,0.93c-7.3-0.02-14.09-0.44-21.31-1.64
			c-4.24-0.71-8.35-1.04-12.63-1.38c-5.48-0.44-10.65-1.71-16.18-1.69c-6.93,0.03-14.03,0.37-20.86,1.43
			c-3.36,0.52-6.51,0.49-9.94,0.46c-4.34-0.04-8.39,0.82-12.69,1c-3.46,0.14-6.95-0.09-10.41-0.07c-2.6,0.01-4.92,0.86-7.47,1.01
			c-4.42,0.27-8.98-0.04-13.41-0.05C66.68,5.81,60,7.65,53.23,8.56c-1.42,0.19-2.92-0.15-4.31,0.16c-1.48,0.33-2.19,0.77-3.75,0.86
			c-3.64,0.2-7.15,1.32-10.74,1.88c-9.03,1.41-17.27,5.29-25.46,9.16c-3.64,1.72-6.24,2.53-6.62,7.07C1.99,32,6.74,35.07,9.89,37.3
			c3.61,2.57,7.67,5.15,12.08,5.92c3.94,0.68,8.07-0.08,12.05,0.16c2.11,0.13,3.9,1.01,5.96,1.47c2.64,0.59,5.35,0.79,8.01,1.38
			c6.55,1.47,13.35,0.98,20.04,0.94c4.6-0.03,8.91,0.88,13.53,0.91c4.82,0.04,9.01,1.93,13.89,1.9c11.26-0.06,22.52,0,33.78,0
			c4.05,0,8.49-0.56,12.47,0.19c2.48,0.46,4.76,1.6,7.33,1.74c2.71,0.15,5.49-0.05,8.2-0.05c3.99,0,7.99,0.02,11.98,0
			c4.53-0.02,8.59,0.84,12.97,1.67c7.66,1.45,15.59-0.76,23.18-0.73c3.69,0.01,7.38-0.02,11.07,0c4.01,0.02,7.62-0.96,11.62-0.94
			c8.81,0.05,17.29,1.27,26.13,0.96c4.74-0.17,9.65-1.49,13.3-4.7c1.78-1.56,2.77-3.55,3.7-5.66c1.17-2.63,1.17-3.21,0.89-5.87
			c-0.37-3.55-0.79-7.64-2.69-10.81c-1.55-2.59-4.04-3.84-6.48-5.16c-1.87-1.01-2.71-2.54-3.95-4.23c-3.38-4.62-7.96-6.48-13.2-8.34
			c-2.51-0.89-3.65-1.32-6.25-1.28c-1.77,0.02-5.12,0.64-6.72-0.16c-0.05-0.26-0.09-0.52-0.14-0.78"/>
    }),
    color => ({
        viewBox: '0 0 215 113',
        path:	<path fill={color} d="M14.61,29.1c0.62-1.81,1.53-3.29,2.74-4.73c1.05-1.25,2.97-2.16,4.33-3.14c3.14-2.26,7.09-2.99,10.34-5.08
			c1.84-1.18,3.48-0.52,5.52-0.81c2.44-0.35,4.79-1.48,7.18-1.97c3.45-0.7,7.35,0.88,10.5-1.26c1.27-0.87,1.74-1.47,3.25-1.51
			c0.89-0.02,1.79,0.02,2.68,0.03c1.98,0.01,3.76-0.44,5.75-0.51c1.89-0.06,4.12-0.5,5.95-1.01C73.62,8.9,74.5,8.6,75.29,8.5
			c0.99-0.13,2.08,0.23,3.06,0.05c0.69-0.12,1.2-0.49,1.91-0.58C81.33,7.83,82.57,8,83.67,8c2.18,0,4.36-0.02,6.54,0
			c1.78,0.01,3.49-0.33,5.25-0.5c2.29-0.21,4.1-1.68,6.44-1.62c3.45,0.09,6.8-1.85,10.06-2.59c1.7-0.38,3.83-0.05,5.55-0.05
			c2.78,0,5.56,0,8.34,0c2.32,0,4.63-0.02,6.95,0c2.67,0.02,5.19-0.53,7.87-0.52c4.02,0.01,7.97-0.14,11.9,0.48
			c4.94,0.78,10.11-0.55,15.05,0.46c1.82,0.37,3.81,0.08,5.66,0.11c0.85,0.01,1.45,0.1,2.24,0.4c1.18,0.44,1.95,0.52,3.17,0.66
			c2.27,0.26,4.43,0.96,6.74,1.07c1.02,0.05,2.01,0.12,3,0.46c0.87,0.3,1.54,0.78,2.4,1.14c3.78,1.59,7.53,4.14,10.68,6.82
			c1.46,1.24,2.84,2.87,3.85,4.51c1.1,1.8,1.63,4.01,2.49,5.94c2.16,4.86,3.23,10.11,4.15,15.33c0.36,2.01,0.46,3.87,0.46,5.92
			c0,0.86,0.1,1.77-0.04,2.62c-0.19,1.18-0.82,2.26-0.97,3.45c-0.54,4.2-1.22,8.37-2.02,12.54c-0.36,1.86-0.65,3.69-1.06,5.51
			c-0.43,1.94-1.15,3.58-1.87,5.45c-0.57,1.47-1.15,2.78-1.94,4.18c-0.54,0.96-1.27,1.71-1.88,2.62c-0.72,1.07-0.79,2.26-1.29,3.4
			c-0.65,1.5-1.45,3.16-2.36,4.53c-1.12,1.67-3.04,2.8-4.5,4.22c-2.95,2.88-6.79,4.3-10.56,5.8c-4.22,1.68-8.26,3.53-12.63,4.73
			c-2.53,0.7-4.72,2.05-7.42,2.14c-3.2,0.12-6.28,0.7-9.42,1.33c-3.13,0.62-6.62,0.26-9.81,0.24c-4.15-0.03-8.3,1.02-12.45,0.42
			c-2.65-0.39-5.26-0.58-7.89-1.06c-2.81-0.5-5.54-1.22-8.4-1.47c-3.52-0.3-6.98-0.86-10.5-1.04c-1.53-0.08-2.96-0.54-4.5-0.56
			c-1.99-0.03-3.89,0.02-5.87-0.24c-7.86-1.03-15.81-2.12-23.73-2.35c-3.69-0.11-7.35-1.05-11.06-1.1
			c-4.01-0.05-7.97-0.43-11.9-1.19c-1.55-0.3-3.22-0.22-4.71-0.68c-1.3-0.4-2.36-1.13-3.71-1.43c-1.44-0.32-2.9-0.55-4.33-0.89
			c-1.64-0.4-3.02-1.3-4.61-1.82c-2.54-0.83-4.92-1.72-7.16-3.23c-1.77-1.19-3.79-1.71-5.61-2.91c-3.06-2.03-5.93-4.57-8.4-7.33
			c-2.48-2.78-3.97-6.62-5.28-10.05C4,70.29,3.93,68.7,3.53,67.09c-0.41-1.7-0.62-3.28-0.55-5.03c0.14-3.55,1.83-6.98,2.66-10.41
			c0.38-1.57,1.29-3.04,1.61-4.6c0.1-0.5-0.02-1.04,0.09-1.56c0.18-0.89,0.58-1.78,0.8-2.65c0.45-1.7,1.07-3.56,1.2-5.32
			c0.09-1.13,0.2-1.41,0.71-2.35c0.38-0.69,0.77-1.41,1.18-2.09c0.91-1.47,1.15-3.19,2.09-4.72c0.75-1.21,2.14-2.8,3.36-3.6
			c1.5-0.99,5.41-1.57,5.83-3.56"/>
    }),
    color => ({
        viewBox: '0 0 215 82',
        path: <path fill={color} d="M204.72,16.27c-4.6-2.65-9.17-6.22-14.3-7.79c-1.83-0.56-3.48-0.68-5.38-0.66c-2.97,0.02-5.59-0.64-8.51-0.97
			c-5.43-0.6-10.75-0.59-16.21-0.86c-3.1-0.16-6.02-0.98-9.16-1.02c-3.04-0.04-6.09,0.04-9.14,0.04c-11.44,0-22.72-0.94-34.18-0.94
			c-7.73,0-16.18-0.97-23.83,0.08C76.29,5.19,66.73,6.2,58.99,4.52C51.89,2.98,44.93,5,37.92,4.99C31,4.98,25.3,6.24,19.6,10.18
			c-5.46,3.78-12,7.68-14.08,14.49c-0.86,2.81-1.87,5.61-2.4,8.51c-0.35,1.88,0.33,3.03,0.57,4.91c0.25,2.04-0.3,4.06,0.14,6.11
			c0.34,1.61,1.21,3.12,1.51,4.75c0.29,1.56,0.23,2.98,0.61,4.51c1.08,4.3,3.36,9.13,7.12,11.76c2.24,1.57,5.33,2.33,7.95,3.19
			c1.78,0.58,3.34,1.56,5.09,2.15c3.17,1.06,6.73,1.25,10.01,1.86c2.77,0.51,5.5,1.23,8.28,1.68c2.79,0.45,5.58,0.2,8.36,0.64
			c4.67,0.74,9.47,0.1,14.08,0.94c9.97,1.82,20.83,0.73,30.98,0.73c10.98,0,21.96,0.1,32.94-0.02c5.19-0.06,10.27,0.96,15.51,0.96
			c2.22,0,4.5-0.16,6.71-0.04c2.01,0.11,3.77,0.9,5.78,1.01c3.6,0.19,7.39,0.13,10.99-0.01c1.59-0.06,2.89-0.63,4.43-0.88
			c2.05-0.34,4-0.03,6.08-0.51c9.15-2.14,17.13-3.34,23.55-10.86c2.71-3.18,5.66-5.31,6.58-9.58c0.7-3.27,2.61-7.62,1.9-11.07
			c-0.34-1.64-1.4-3.02-1.74-4.7c-0.37-1.82,0.01-3.61-0.4-5.42c-0.32-1.39-1.14-2.74-1.45-4.13c-0.44-1.9-0.02-3.79-0.4-5.68
			c-0.7-3.4-3.27-7.45-5.92-9.61c-1.61-1.32-5.65-2.11-6.11-4.28"/>
    }),
    color => ({
        viewBox: '0 0 131 82',
        path: <path fill={color} d="M125.14,13.9c-2.42-3.19-5.68-6.52-9.54-8.33c-0.22,0.04-0.44,0.08-0.68,0.08c-1.45,0.02-2.88,0.17-4.33,0.15
			c-2.45,0.37-4.68,0.74-7.41,0.73c-0.61,0-1.23,0-1.84,0c-1.09,0.57-2.29,0.58-3.38,0c-2.23,0-4.45,0.01-6.68,0.01H79.74
			c-4.96,0-10.67,0.76-15.51-0.24c-3.42-0.7-6.55-0.84-10.05-0.84c-4.08,0-7.48,0.86-11.44,1.14c-5.62,0.39-11.26,0.72-16.76,2.28
			c-6.5,1.85-9.42,9.64-13.86,14.02c-2.33,2.3-4.84,3.64-6.3,6.68c-1.05,2.18-1.65,5.11-2.18,7.4c-1.12,4.9-0.56,11.61,1.09,16.36
			c0.68,1.96,1.86,3.86,2.96,5.62c0.83,1.33,1.85,2.4,2.78,3.64c1.57,2.12,2.32,4.66,3.81,6.82c3.52,5.13,8.86,4.84,14.42,5.56
			c6.37,0.83,12.7,2.97,19.02,4.16c4.97,0.94,10.64,0.22,15.7,0.22c2.73,0,5.46-0.01,8.19,0c2.73,0.01,4.76-0.41,7.34-0.98
			c4.93-1.1,9.92-2.47,14.91-3.63c7.2-1.68,13.52-0.61,19.4-6.26c3.81-3.66,6.69-7.17,8.94-11.89c0.99-2.08,1.18-4.43,2.15-6.58
			c0.95-2.1,1.33-4.4,2.17-6.58c1.62-4.18,1.87-8.4,1.88-12.93C128.39,24.4,128.93,18.89,125.14,13.9z"/>
    }),
    color => ({
        viewBox: '0 0 233 74.17',
        path: <path fill={color} d="M194.75,7.68c-10.35-1.46-20.58,1.89-30.54,4.46c-5.07,1.31-10.34,1.87-15.51,2.82c-5.72,1.05-11.38,0.66-17.03,1.65
			c-9.87,1.73-20.93,0.47-30.97,0.47c-4.7,0-9.47,0.23-14.16,0.04c-1.91-0.08-3.55-0.85-5.44-1.02c-2.53-0.23-5.06,0.34-7.59-0.14
			c-1.94-0.37-3.73-1.29-5.66-1.61c-2.47-0.42-5.02-0.37-7.52-0.81c-4.91-0.86-10.09,0.56-14.98-0.42
			c-3.82-0.76-7.39-0.71-11.3-0.73c-3.72-0.02-6.34,0.6-9.82,1.77C17.1,16.57,8.03,18.74,4.31,25.92c-0.86,1.65-1.2,2.31-1.26,4.29
			c-0.06,1.87,0.02,3.76,0.02,5.63c0,3.33-0.32,6.33,0.92,9.41c1.71,4.22,3.63,6.82,7.57,9.07c1.29,0.73,2.32,1.88,3.67,2.51
			c1.73,0.79,3.68,0.9,5.45,1.57c8.81,3.36,17.92,5,27.25,6.42c4.42,0.67,8.98,0.58,13.39,1.33c3.8,0.65,6.84,1.69,10.76,1.67
			c9.8-0.05,19.6,0.08,29.39,0c4.15-0.03,7.93,0.93,12.11,0.93c5.82,0,11.46-0.96,17.3-0.93c4.81,0.02,10.3,0.72,15.03-0.22
			c4.42-0.87,8.41-1.73,12.95-1.67c3.3,0.04,6.25-0.96,9.59-0.93c4.64,0.04,9.11-0.62,13.7-1.03c4.67-0.42,9.45-1.32,14.02-2.27
			c1.81-0.38,3.65-0.23,5.44-0.64c1.84-0.42,3.59-1.53,5.36-2.16c1.83-0.65,2.7-1.58,4.19-2.73c1.87-1.44,4.24-2.32,6.18-3.85
			c1.93-1.52,5.05-3.37,6.6-5.32c1.58-2,2.58-4.94,3.76-7.19c2.08-3.99,2.76-8.02,2.77-12.45c0-2.26,0.18-3.82-0.89-5.84
			c-0.9-1.7-2.15-3.45-3.29-4.99c-2.85-3.86-6.27-5.78-10.78-7.36c-3.11-1.08-6.95-3.04-10.17-3.42c-6.74-0.78-14.11,0.05-20.9,0.05
			"/>
    }),
    color => ({
        viewBox: '0 0 229 81.8',
        path: <path fill={color} d="M222.41,27.25c-3.3-5.13-5.86-9.54-12.14-10.46c-5.44-0.79-10.82-0.67-16.24-1.55c-6.13-0.99-12.93-0.22-19.12-0.21
			c-6.33,0.01-12.44-0.98-18.75-0.95c-6.23,0.03-11.99,0.44-18.11,1.65c-5.95,1.17-11.49,1.18-17.52,1.18c-3.94,0-7.88-0.03-11.82,0
			c-5.16,0.03-10.11-0.92-15.29-0.95c-4.85-0.03-9.53,0.98-14.42,0.96c-2.28-0.01-4.67-0.25-6.93-0.05c-2.66,0.24-5.1,0.98-7.8,1.01
			c-7.49,0.09-15.26-0.69-22.71,0.05c-4.97,0.49-9.03,3.03-13.7,4.44c-3.08,0.93-6.42,1.76-9.29,3.33
			C12.8,28.86,8.04,36.16,6.07,42.29c-1.7,5.3-1.18,12.72,0.91,17.9c1.19,2.95,2.76,4.83,5.85,5.99c3.61,1.35,7.28,0.88,10.84,1.92
			c6.37,1.86,12.55,4.26,19.25,4.26c4.49,0,8.68-0.95,13.17-0.93c4.08,0.02,8.16,0,12.24,0c3.75,0,7.5-0.03,11.25,0.01
			c4.48,0.04,8.76-1.01,13.28-0.94c4.15,0.06,7.87-1.77,12.04-0.51c1.3,0.39,2.45,1.12,3.78,1.37c1.97,0.38,4.16,0.06,6.16,0.08
			c5.88,0.05,11.43-1.73,17.26-1.93c4.11-0.15,8.26,0.05,12.38,0.05c6.75,0,13.82,0.64,20.37-0.73c1.99-0.42,3.88,0.03,5.87-0.16
			c1.7-0.16,3.42-0.71,5.1-0.99c2.72-0.46,5.4-1.15,8.13-1.76c1.77-0.4,3.57-0.58,5.33-1.03c2.01-0.51,3.72-1.5,5.69-2.18
			c2.64-0.92,4.71-2.14,7.2-3.44c7.21-3.75,11.64-10.87,16.5-16.98c1.22-1.53,2.24-2.91,2.62-4.93c0.38-1.99-0.5-2.78-0.82-4.65
			c-0.44-2.59,1.41-7.34-0.89-9.19"/>
    }),
    color => ({
        viewBox: '0 0 244 102',
        path: <path fill={color} d="M216.86,14.61c-6.66-1.34-12.82-2.21-19.59-2.17c-1.9,0.01-4.03,0.27-5.87-0.09c-1.81-0.36-2.99-1.03-4.96-1
			c-5.02,0.07-9.6-0.55-14.58-0.98c-6.74-0.59-13.7-0.09-20.48-0.09c-5.66,0-11.48,0.43-17.13,0.06
			c-5.02-0.34-10.01-1.76-14.96-2.29c-7.99-0.85-15.94-2.11-24.07-2.11c-3.44,0-6.93-0.17-10.37-0.04
			c-2.36,0.09-4.53,1.01-6.88,1.18C75.68,7.23,73.28,7,70.98,7c-8.89,0-17.92-0.32-26.51,1.62c-8.15,1.84-16.14,4.59-22.78,9.75
			c-4.89,3.8-8.83,6.95-11.95,12.45c-2.08,3.66-3.82,8.97-4.6,13.14c-1.34,7.22,0.93,14.28,1.99,21.23c0.4,2.65,1.07,4.97,1.99,7.56
			c1.56,4.37,6.44,7.8,10.99,8.98c2.62,0.68,4.62,1.99,7.14,2.88c3.17,1.13,6.97,1.89,10.25,2.58c7.44,1.56,15.53,3.32,23.13,3.56
			c8.64,0.28,17.21,1.06,25.86,1.03c6.69-0.02,13.12-0.19,19.62,1c5.06,0.92,10.61-0.3,15.74,0.19c7.4,0.7,14.83,0.84,22.2,1.84
			c6.38,0.86,13.13,0.24,19.56,0.24c5.17,0,10.79,0.62,15.82-0.54c3.13-0.73,6.23-0.55,9.46-0.54c4.83,0.01,8.91-1.03,13.64-1.91
			c5.63-1.05,11.95-5.06,16.76-8.33c7.51-5.12,13.08-13.61,15.79-22.07c1.36-4.26,3.34-8.9,3.56-13.36
			c0.18-3.6,0.12-7.37,0.03-10.97c-0.12-4.4-2.56-8.32-4.84-11.87c-1.77-2.76-4.75-5.91-7.74-7.49c-1.33-0.7-2.86-0.94-4.19-1.81
			c-1.93-1.26-2.52-2.6-5.02-2.68c-3.46-0.1-6.32-0.98-9.78-1.04"/>
    }),
    color => ({
        viewBox: '0 0 119 65.2',
        path: <path fill={color} d="M98.61,16.55c-17.92-3.38-36.7-4.73-54.9-4.7c-9.91,0.02-20.6-1.51-29.74,2.77c-7.22,3.38-10.11,11.48-7.1,18.8
			c1.81,4.41,4.31,7.57,8.53,9.85c3.4,1.83,6.99,3.32,10.64,4.55c7.42,2.49,15.16,2.63,22.76,4.33c3.57,0.8,6.68,1.05,10.36,1.06
			c1.81,0,3.62,0.03,5.43,0c2.36-0.03,4.26,0.74,6.55,0.98c1.67,0.18,3.23-0.2,4.91,0.16c1.65,0.35,2.93,0.75,4.67,0.73
			c1.45-0.02,2.91,0.01,4.36-0.01c2.26-0.03,3.88,0.7,6.05,1c2.87,0.39,6.78,0.06,9.42-0.97c1.57-0.61,3.11-1.7,4.44-2.82
			c1.88-1.59,2.25-3.44,3.24-5.66c1.39-3.11,2.73-5.9,2.6-9.33c-0.1-2.72-0.9-4.52-1.92-7.03c-0.85-2.09-1.12-4.43-2.04-6.47
			c-3.21-7.12-14.54-12.59-21.42-7.24"/>
    }),
    color => ({
        viewBox: '0 0 163 72.5',
        path: <path fill={color} d="M153.89,4.77c-3.76,0.71-8.68,0.53-12.49-0.21c-1.38-0.27-2.24-0.67-3.66-0.75c-1.56-0.09-3.23,0.28-4.77,0.07
			c-2.86-0.39-5.21-1.01-8.2-0.98c-4.58,0.03-9.59-0.63-14.09,0.21c-7.63,1.41-15.74,0.73-23.51,0.73c-3.49,0-6.98,0-10.47,0
			c-5.99,0-12.49-0.72-18.42,0.19c-3.91,0.6-7.78,1.67-11.67,2.38c-4.89,0.89-9.27,2.73-13.95,4.24c-2.34,0.76-4.96,1.79-7.24,2.78
			c-1.58,0.69-2.61,1.8-4.02,2.68c-1.56,0.98-3.3,1.49-4.72,2.72c-1.95,1.68-3.36,4.26-4.69,6.44c-1.52,2.48-1.74,5-2.77,7.65
			c-0.95,2.45-1.75,6.57-0.78,9.24c0.9,2.45,2.98,4.95,4.54,7.02c3.91,5.16,8.89,6.01,15.01,7.52c6.67,1.65,13.11,3.78,19.71,5.66
			c3.72,1.06,7.52,0.85,11.3,1.88c3.44,0.94,6.82,0.69,10.35,0.68c6.81-0.01,14.2,0.8,20.93-0.21c5.7-0.86,11.04-1.67,16.89-1.67
			c5.06,0,9.62-0.97,14.72-0.94c2.46,0.02,4.26-0.68,6.63-0.99c2.7-0.36,5.06-0.46,7.74-1.12c5.04-1.25,9.25-4.54,12.94-8.22
			c8.28-8.27,8.46-19.86,8.46-30.82c0-2.25,0.14-4.54,0.02-6.79c-0.12-2.24-1.33-4.23-2.44-6.18c-1.89-3.31-3.49-3.56-6.98-4.37
			c-2.53-0.58-7.25-1.98-9.4,0.23"/>
    }),
    color => ({
        viewBox: '0 0 260 77.62',
        path: <path fill={color} d="M252.11,31.46c-4.46-1.02-8.28-2.45-12.26-4.69c-4.37-2.45-9.35-3.92-14.1-5.43c-3.77-1.2-7.48-2.62-11.25-3.81
			c-4.54-1.43-9.32-1.53-13.88-2.98c-5.8-1.84-12.22-2.68-18.28-3.55c-5.36-0.77-10.61-1.97-15.95-2.82
			c-2.8-0.44-5.8-0.83-8.61-1.07c-2.77-0.24-5.52,0.14-8.28-0.29c-6.59-1.02-13.6-1.72-20.28-1.69c-7.77,0.03-15.26,1.4-22.98,1.89
			C98.4,7.53,90.48,8.01,82.7,9.14c-6.17,0.9-12.46,1.36-18.56,2.57c-4.83,0.96-9.49,2.5-14.26,3.56c-2.25,0.5-4.07,0.62-6.29,1.5
			c-1.59,0.63-3.15,1-4.78,1.49c-2.06,0.62-3.49,1.81-5.4,2.67c-1.88,0.85-4,1.1-5.87,2.02c-6.02,2.96-11.33,8.71-14.56,14.4
			c-1.6,2.81-3.78,5.75-4.23,9.06c-0.52,3.8,4.09,7.39,6.76,9.47c2.9,2.26,5.91,3.49,9.09,5.17c1.92,1.02,3.63,1.75,5.78,2.14
			c2.26,0.41,3.69,1.03,5.82,1.88c1.76,0.7,3.63,0.76,5.44,1.23c1.49,0.38,2.77,1.27,4.22,1.84c3.68,1.46,7.86,1.86,11.75,1.84
			c9.75-0.06,19.48-0.09,29.12,0.89c3.44,0.35,6.83,1.37,10.26,1.85c3.43,0.48,7.24,0.08,10.7,0.08c14.54,0,29.08,0,43.62,0
			c6.65,0,13.34,0.17,19.99,0.02c3.53-0.08,7.39-0.68,10.81-1.43c1.85-0.4,3.57-0.19,5.42-0.42c2.2-0.28,4.04-1.04,6.34-1.01
			c3.79,0.05,7.58-0.01,11.37,0.03c3.86,0.04,7.88-0.95,11.63-1.77c4.24-0.94,8.11-2.56,12.24-3.88c1.66-0.53,3.23-0.75,4.91-1.14
			c2.22-0.51,3.33-1.53,5.16-2.63c3.51-2.11,7.91-3.6,9.91-7.49c1.69-3.29,3.86-6.65,5.16-10.1c0.83-2.21,0.42-2.75-0.07-4.98
			c-0.24-1.06,0.09-2.25-0.22-3.24c-0.29-0.92-2.53-3.03-3.4-3.8c-1.35-1.2-2.2-1.59-4.11-1.37"/>
    }),
    color => ({
        viewBox: '0 0 200 104',
        path: <path fill={color} d="M157.5,5.5c-4.43,0.94-8.96,2.17-13.38,2.87c-3.31,0.52-7.33-0.03-10.66-0.06c-5.34-0.04-10.53,0.95-15.93,0.95
			c-6.71-0.01-13.33,1.08-20,0.94c-5.74-0.12-11.48,0.96-17.28,0.94c-5.47-0.02-11.45,0.33-16.81,1.37
			c-9.14,1.78-18.25,3.4-27.12,6.33c-3.3,1.09-7.08,3.54-10.08,5.36c-1.99,1.2-3.8,3.28-5.47,4.83c-1.78,1.65-3.96,2.8-5.54,4.63
			c-2.47,2.84-5.04,6.83-6.18,10.37c-1.34,4.19-3.66,7.33-3.78,11.95c-0.09,3.24-0.25,5.83,1.2,8.72c1.38,2.76,3.59,4.62,5.4,7.05
			c2.07,2.77,4.61,4.23,7.08,6.58c2.78,2.63,5.14,4.39,8.7,5.9c7.23,3.06,14.93,5.45,22.73,6.68c4.75,0.76,9.46,1.68,14.2,2.46
			c5.69,0.93,11.16,0.65,16.84,2.1c4.19,1.08,7.89,1.2,12.2,1.18c4.51-0.02,8.72,0.96,13.23,0.93c3.71-0.02,7.43,0,11.14,0
			c4.9,0,9.32-0.34,14.15-0.94c4.4-0.55,8.75-1.96,13.2-1.9c3.67,0.05,7.62,0.49,11.23-0.19c3.38-0.63,7.1-0.76,10.38-1.88
			c4.05-1.38,7.03-4.02,10.38-6.65c2.85-2.24,3.96-5.61,6.49-8.2c2.79-2.86,5.34-5.73,6.81-9.37c1.05-2.62,2.68-4.91,3.32-7.7
			c0.86-3.75-0.24-7.35-0.64-11.04c-0.38-3.46-0.58-7.13-1.22-10.55c-0.35-1.91-1.12-3.87-1.88-5.66c-0.95-2.23-2.36-3.56-3.64-5.48
			c-0.93-1.39-1.02-2.88-1.76-4.42c-0.9-1.89-2.2-3.61-3.52-5.19c-2.86-3.42-6.2-4.14-9.67-6.58c-2.97-2.09-5.82-2.56-9.19-3.78
			c-3.59-1.3-6.6-0.42-10.18,0.18c-3.43,0.57-6.93-0.23-10.34,0.54c-3.72,0.84-6.87,1.38-10.7,1.42"/>

    }),
];
