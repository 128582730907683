import { useEffect, useState } from 'react';

import base01 from "../../assets/01.png";
import base02 from "../../assets/02.png";
import base03 from "../../assets/03.png";
import base04 from "../../assets/04.png";
import base05 from "../../assets/05.png";
import base06 from "../../assets/06.png";
import base07 from "../../assets/07.png";
import base08 from "../../assets/08.png";
import base09 from "../../assets/09.png";

import Floor2 from "../Floors/Floor2";
import Floor3 from "../Floors/Floor3";
import Floor4 from "../Floors/Floor4";
import Floor5 from "../Floors/Floor5";
import Floor6 from "../Floors/Floor6";
import Floor7 from "../Floors/Floor7";
import Floor8 from "../Floors/Floor8";
import Floor9 from "../Floors/Floor9";

const floors = [{
    image: base01,
    blob: () => <></>,
}, {
    image: base02,
    blob: i => <Floor2 index={i} />,
}, {
    image: base03,
    blob: i => <Floor3 index={i} />,
}, {
    image: base04,
    blob: i => <Floor4 index={i} />,
}, {
    image: base05,
    blob: i => <Floor5 index={i} />,
}, {
    image: base06,
    blob: i => <Floor6 index={i} />,
}, {
    image: base07,
    blob: i => <Floor7 index={i} />,
}, {
    image: base08,
    blob: i => <Floor8 index={i} />,
}, {
    image: base09,
    blob: i => <Floor9 index={i} />,
}];


export default function BuildingStack({ expanded, index }) {
    const aspect = 1.85;
    const [width, setWidth] = useState(window.innerWidth * .4);

    useEffect(() => {
        const onResize = () => {
            const floorHeight = window.innerHeight * .3;
            setWidth(floorHeight * aspect);
        };

        onResize();

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    return (
        <div
            className={`BuildingStack ${expanded ? "expanded" : ""}`}
            style={{ width: `${width}px` }}
        >
            {floors.map(floor => <div className={"floor"}>
                    <img src={floor.image} alt=""/>
                    {floor.blob(index)}
                </div>
            )}
        </div>
    )
}
