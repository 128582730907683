import { observer } from 'mobx-react';
import { useHenshuApp, Curate } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';

import ContentPanel from '../../ContentPanel/ContentPanel';
import DoubleImage from '../../DoubleImage/DoubleImage';
import FadeUp from '../../animations/FadeUp';
import Issue from '../Issue/Issue';
import Parallax from '../../Parallax/Parallax';
import Section from '../../Section/Section';
import MapSequence from './components/MapSequence/MapSequence';

import SasakiLogoWhite from '../../../assets/images/sasaki-logo-white.png';


export default observer(function ThirdIssue() {
    const {bindTo} = useHenshu();
    const {upload} = useHenshuApp().persist;

    return <>
        <Issue className="ThirdIssue">
            <header className="heavy-padding">
                <div>
                    <img className="sasaki-logo" src={SasakiLogoWhite} alt="Sasaki"/>

                    <henshu.img
                        className="gif"
                        alt="Hello 110 Chauncy"
                        get={() => bindTo('he110chauncy.snow').get()}
                        set={async buf => bindTo('he110chauncy.snow').set(await upload(buf))}
                    />
                </div>

                <FadeUp>
                    <henshu.h1 {...bindTo('issues.first.title')} />

                    <Parallax className="header-circle" magnitude={-.05}>
                        <henshu.img
                            get={() => bindTo('issues.first.title.circle').get()}
                            set={async buf => bindTo('issues.first.title.circle').set(await upload(buf))}
                        />
                    </Parallax>
                </FadeUp>
            </header>

            <Section className="banner heavy-padding">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.third.banner.front')}
                        back={bindTo('')}
                    />
                </FadeUp>
            </Section>

            <MapSequence />

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        className="map-friend"
                        front={bindTo('issues.third.map-friend')}
                        back={bindTo('issues.third.map-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="fun-facts-1">
                <div className="fun-facts-content">
                    <Curate id="issues.third.fun-facts1" data={{ title: 'Fun Facts' }}>
                        <henshu.each {...bindTo('issues.third.fun-facts1')}>
                            {bindToFacts => (
                                <div>
                                    <FadeUp>
                                        <henshu.p {...bindToFacts('text')} />
                                    </FadeUp>
                                </div>
                            )}
                        </henshu.each>
                    </Curate>
                </div>

                <div className="walking-friends">
                    <FadeUp>
                        <DoubleImage
                            backMod={.05}
                            front={bindTo('issues.third.walking-friends')}
                            back={bindTo('issues.third.walking-friends.back')}
                        />
                    </FadeUp>
                </div>

                <div className="pouring-friends">
                    <FadeUp>
                        <DoubleImage
                            backMod={.17}
                            front={bindTo('issues.third.pouring-friends')}
                            back={bindTo('issues.third.pouring-friends.back')}
                        />
                    </FadeUp>
                </div>
            </Section>

            <ul className="intro top-intro fun-facts-2">
                <henshu.each {...bindTo('issues.third.fun-facts2')}>
                    {(bindToFacts, i) => (
                        <li>
                            <FadeUp>
                                <Curate id={`issues.third.fun-facts2.${i+1}`} data={{ title: 'Fun Facts' }}>
                                    <DoubleImage
                                        frontMod={.1}
                                        backMod={i === 5 ? .18 : .27}
                                        front={bindToFacts('image.front')}
                                        back={bindToFacts('image.back')}
                                    />

                                    <henshu.richtext {...bindToFacts('text')} />
                                </Curate>
                            </FadeUp>
                        </li>
                    )}
                </henshu.each>

            </ul>

            <Section className="footnotes-note">
                <FadeUp>
                    <henshu.p {...bindTo('fun-facts-footer')} />
                </FadeUp>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        className="lunch-friend"
                        front={bindTo('issues.third.lunch-friend')}
                        back={bindTo('issues.third.lunch-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="letters">
                <div className="train-friend">
                    <FadeUp>
                        <DoubleImage
                            backMod={.3}
                            front={bindTo('issues.third.train-friend')}
                            back={bindTo('issues.third.train-friend.back')}
                        />
                    </FadeUp>
                </div>

                <FadeUp>
                    <Curate id="issues.third.convo1" data={{
                        title: bindTo('issues.third.convo1.title').get(),
                        author: bindTo('issues.third.convo1.footer').get()
                    }}>
                        <ContentPanel
                            className="quoted green"
                            title="issues.third.convo1.title"
                            body="issues.third.convo1.body"
                            footer="issues.third.convo1.footer"
                        />
                    </Curate>
                </FadeUp>

                <div className="noodle-friend">
                    <FadeUp>
                        <DoubleImage
                            front={bindTo('issues.third.noodle-friend')}
                            back={bindTo('issues.third.noodle-friend.back')}
                        />
                    </FadeUp>
                </div>

                <div className="swan-friend">
                    <FadeUp>
                        <DoubleImage
                            backMod={.3}
                            front={bindTo('issues.third.swan-friend')}
                            back={bindTo('issues.third.swan-friend.back')}
                        />
                    </FadeUp>
                </div>

                <div className="driving-friend">
                    <FadeUp>
                        <DoubleImage
                            front={bindTo('issues.third.driving-friend')}
                            back={bindTo('issues.third.driving-friend.back')}
                        />
                    </FadeUp>
                </div>

                <div className="ufo-friend">
                    <FadeUp>
                        <DoubleImage
                            backMod={.3}
                            front={bindTo('issues.third.ufo-friend')}
                            back={bindTo('issues.third.ufo-friend.back')}
                        />
                    </FadeUp>
                </div>

                <FadeUp>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <Curate id="issues.third.convo2" data={{
                        title: bindTo('issues.third.convo2.title').get(),
                        author: bindTo('issues.third.convo2.footer').get()
                    }}>
                        <ContentPanel
                            className="quoted gold"
                            title="issues.third.convo2.title"
                            body="issues.third.convo2.body"
                            footer="issues.third.convo2.footer"
                        />
                    </Curate>
                </FadeUp>

                <div className="dumpling-friend">
                    <FadeUp>
                        <DoubleImage
                            backMod={.3}
                            front={bindTo('issues.third.dumpling-friend')}
                            back={bindTo('issues.third.dumpling-friend.back')}
                        />
                    </FadeUp>
                </div>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        className="biker-friend"
                        front={bindTo('issues.third.footer-friend.front')}
                        back={bindTo('issues.third.footer-friend.back')}
                        frontMod={0}
                        backMod={-.15}
                    />
                </FadeUp>
            </Section>

            <Section className="footnotes">
                <FadeUp>
                    <h4>Sources / Extra Credit Reading</h4>

                    <ul>
                        {[
                            ["Mapjunction", 'https://www.mapjunction.com/?lat=42.3541877&lng=-71.0570338&map1=google.SATELLITE&map2=mj.1776HydeBritishandAmericanLinesP&zoom=13.5478641&mode=overlay&b=0.000&p=0.000'],
                            ['Boston Public Library–Leventhal Map & Education Center', 'https://www.leventhalmap.org/collections/'],
                            ["HistoryofMassachusetts.org–History of the Boston Landfill Project", "https://historyofmassachusetts.org/how-boston-lost-its-hills/"],
                            ["Hiddenhydrology.org–Boston’s Made Land", "https://www.hiddenhydrology.org/bostons-made-land/"],
                            ["WBUR–8 Maps That Explain Boston’s Changing Shoreline", "https://www.wbur.org/news/2021/06/14/8-maps-that-explain-bostons-changing-shoreline"],
                            ["Chinatown atlas", "https://www.chinatownatlas.org/"],
                            ["National Parks Service–Early History of Boston’s Chinatown", "https://www.nps.gov/articles/000/boston-chinatown.htm"],
                            ["Rentify (Sasaki Foundation Grant project)", "https://www.sasakifoundation.org/wp-content/uploads/2020/02/DesignGrants_RentifyChinatown_Narrative_web.pdf"],
                            ["Wikipedia–Amoskeag Manufacturing Co", "https://en.wikipedia.org/wiki/Amoskeag_Manufacturing_Company#History"],
                            ["Quarterly Journal of Economics–Amoskeag Manufacturing Co", "https://en.wikipedia.org/wiki/Amoskeag_Manufacturing_Company#History"],
                        ].map(([text, link]) => <li><a href={link} target="_blank" rel="noreferrer noopener">{text}</a></li>)}
                    </ul>
                </FadeUp>
            </Section>
        </Issue>
    </>;
});
