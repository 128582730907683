import React, { useCallback } from "react";

export default function Floor2({ index }) {

    const assignIndex = useCallback((i, c) => ({
        className: `${i < index ? 'visited' : (i === index ? 'active' : 'inactive')} ${c}`
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >

               <pattern
        id="New_Pattern"
        width="376.471"
        height="376.471"
        overflow="visible"
        patternUnits="userSpaceOnUse"
        viewBox="0 -376.471 376.471 376.471"
      >
        <path fill="none" d="M0 -376.471H376.471V0H0z"></path>
        <path fill="#BAD9C9" d="M0 -336.235H376.471V-329.411H0z"></path>
        <path fill="#BAD9C9" d="M0 -324.471H376.471V-317.647H0z"></path>
        <path fill="#A88CAD" d="M0 -329.412H376.471V-324.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -312.706H376.471V-305.882H0z"></path>
        <path fill="#A88CAD" d="M0 -317.647H376.471V-312.706H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -300.941H376.471V-294.11699999999996H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -305.882H376.471V-300.94100000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -289.176H376.471V-282.352H0z"></path>
        <path fill="#A88CAD" d="M0 -294.118H376.471V-289.177H0z"></path>
        <path fill="#BAD9C9" d="M0 -371.529H376.471V-364.705H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -376.471H376.471V-371.53000000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -359.765H376.471V-352.941H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -364.706H376.471V-359.76500000000004H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -348H376.471V-341.176H0z"></path>
        <path fill="#A88CAD" d="M0 -352.941H376.471V-348H0z"></path>
        <path fill="#A88CAD" d="M0 -341.176H376.471V-336.235H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -242.118H376.471V-235.29399999999998H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -230.353H376.471V-223.529H0z"></path>
        <path fill="#A88CAD" d="M0 -235.294H376.471V-230.353H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -218.588H376.471V-211.76399999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -223.529H376.471V-218.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -206.824H376.471V-200H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -211.765H376.471V-206.82399999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -195.059H376.471V-188.23499999999999H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -200H376.471V-195.059H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -277.412H376.471V-270.58799999999997H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -282.353H376.471V-277.41200000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -265.647H376.471V-258.823H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -270.588H376.471V-265.64700000000005H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -253.882H376.471V-247.058H0z"></path>
        <path fill="#A88CAD" d="M0 -258.824H376.471V-253.883H0z"></path>
        <path fill="#A88CAD" d="M0 -247.059H376.471V-242.118H0z"></path>
        <path fill="#BAD9C9" d="M0 -148H376.471V-141.176H0z"></path>
        <path fill="#BAD9C9" d="M0 -136.235H376.471V-129.411H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -141.176H376.471V-136.23499999999999H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -124.471H376.471V-117.647H0z"></path>
        <path fill="#A88CAD" d="M0 -129.412H376.471V-124.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -112.706H376.471V-105.882H0z"></path>
        <path fill="#A88CAD" d="M0 -117.647H376.471V-112.706H0z"></path>
        <path fill="#BAD9C9" d="M0 -100.941H376.471V-94.117H0z"></path>
        <path fill="#A88CAD" d="M0 -105.882H376.471V-100.941H0z"></path>
        <path fill="#BAD9C9" d="M0 -183.294H376.471V-176.47H0z"></path>
        <path fill="#A88CAD" d="M0 -188.235H376.471V-183.294H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -171.529H376.471V-164.70499999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -176.471H376.471V-171.53H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -159.765H376.471V-152.94099999999997H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -164.706H376.471V-159.765H0z"></path>
        <path fill="#A88CAD" d="M0 -152.941H376.471V-148H0z"></path>
        <path fill="#BAD9C9" d="M0 -53.882H376.471V-47.058H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -42.118H376.471V-35.294000000000004H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -47.059H376.471V-42.117999999999995H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -30.353H376.471V-23.529000000000003H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -35.294H376.471V-30.352999999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -18.588H376.471V-11.764000000000001H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -23.529H376.471V-18.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -6.824H376.471V0H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -11.765H376.471V-6.824000000000001H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -89.176H376.471V-82.352H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -94.118H376.471V-89.17699999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -77.412H376.471V-70.58800000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -82.353H376.471V-77.41199999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -65.647H376.471V-58.82300000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -70.588H376.471V-65.64699999999999H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -58.824H376.471V-53.882999999999996H0z"
        ></path>
      </pattern>
        <pattern
          id="L2_x5F_combo_x5F_workstation_00000024713898744761305100000000639420774144764558_"
          patternTransform="rotate(45.001 -6911.23 -6744.973)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L2_x5F_combo_x5F_workstation"
          fill="url(#L2_x5F_combo_x5F_workstation_00000024713898744761305100000000639420774144764558_)"
          d="M1241.309 659.659c-28.972-18.931-59.678-38.444-93.107-39.513-7.749-.248-15.707.584-22.533 4.345-9.062 4.992-15.366 15.123-16.606 26.137s2.503 22.639 9.637 30.876c5.928 6.845 13.812 11.254 21.661 15.155 16.853 8.376 34.924 15.168 53.236 14.409 18.312-.758 36.95-10.365 45.042-27.945 3.131-6.803 4.571-14.764 2.67-23.464z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L2_x5F_collab_x5F_Open_collaboration_space_00000104707771054330461600000015482834519566202247_"
          fill="#A88CAD"
          d="M1173.622 722.141c-5.732-4.193-13.363-3.955-19.778-1.259-6.415 2.696-11.887 7.542-17.243 12.314-9.049 8.062-18.377 16.5-23.527 28.068-3.428 7.699-3.478 19.398 3.731 22.442 4.033 1.703 8.509-.242 12.384-2.349 13.796-7.501 26.526-17.433 37.573-29.314 3.339-3.591 6.606-7.497 8.255-12.332 2.037-5.975 2.662-14.602-1.395-17.57z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L2_x5F_collab_x5F_Open_collaboration_space_00000109720113767844270420000015200707001613959297_"
          fill="#A88CAD"
          d="M787.776 786.165c-5.406-4.115-19.014-25.118-21.276-31.525-7.981-22.613-7.981-22.613-17.33-48.339-7.936-21.839-47.808-70.155-25.797-85.155 6.537-8.23 23.206 22.023 25.753 28.02 4.664 10.978 9.012 16.778 20.844 18.29 19.009 2.43 37.185-2.866 55.139 3.836 13.235 4.941 19.901 12.959 28.517 24.154 9.917 12.886 33.185 34.882 40.369 39.905 21.749 15.204 27.883 23.056 53.407 30.313 13.243 3.765 46.989 2.808 51.257-1.334 11.698-11.352 30.1-12.93 45.945-9.105 14.15 3.416 27.554 10.783 36.907 21.937 5.484 6.54 9.63 15.243 7.37 23.474-3.212 11.702-16.822 16.348-28.044 20.964a122.88 122.88 0 00-44.248 31.13c-9.22 10.203-16.697 21.934-26.426 31.654-9.728 9.72-22.571 17.533-36.311 16.952-7.438-.315-14.541-3.053-21.401-5.946-56.743-23.927-144.675-109.225-144.675-109.225z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L3_x5F_collab_x5F_pinup_00000095333559363070217850000014809419334422786696_"
          fill="#C24DFF"
          d="M1024.582 603.559c-.147-4.953-.095-9.912.153-14.861.093-1.851.267-3.841 1.463-5.257 1-1.185 2.538-1.748 4.012-2.228a102.333 102.333 0 0132.049-5.019c1.679.007 3.477.094 4.829 1.09 1.972 1.452 2.272 4.232 2.392 6.679.499 10.18.848 20.368 1.047 30.559.018.928.026 1.897-.387 2.727-.571 1.148-1.812 1.779-2.989 2.289-10.346 4.479-21.826 5.471-33.085 6.059-1.56.081-3.202.14-4.593-.57-1.347-.688-2.258-1.999-2.978-3.329-3.289-6.069-1.913-18.139-1.913-18.139z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L3_x5F_collab_x5F_pinup_00000066495568518324272980000001645362996557044635_"
          fill="#C24DFF"
          d="M1136.029 679.47l-46.553 35.19c-4.807 3.634-10.382 7.47-16.321 6.444-4.796-.829-8.425-4.624-12.526-7.246s-10.228-3.802-13.325-.047c-1.416 1.718-1.793 4.04-2.118 6.242-1.655 11.211-2.766 24.231 5.174 32.318 4.418 4.5 10.763 6.415 16.822 8.165 3.294.951 6.702 1.912 10.102 1.47 3.478-.452 6.59-2.326 9.528-4.242 11.893-7.753 22.833-16.862 33.742-25.948 6.743-5.616 13.725-11.547 17.141-19.63 3.315-7.842 2.841-16.698 2.31-25.195-.12-1.915-.278-3.943-1.398-5.502-1.119-1.556-3.566-2.32-2.578-2.019z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L2_x5F_collab_x5F_Conf-room_00000106147913730679079630000002298319934001001614_"
          fill="#C24DFF"
          d="M1340.03 593.313c.305-12.645-10.417-21.764-20.402-27.441-14.496-8.241-30.401-14.271-46.614-13.701-16.214.57-32.779 8.55-41.976 23.676-5.237 8.612-7.837 19.926-4.583 29.711 3.451 10.378 12.56 16.911 21.661 21.009 17.941 8.077 38.003 9.524 56.938 5.251 10.093-2.278 20.298-6.419 27.426-14.823 7.127-8.404 10.314-21.899 7.55-23.682z"
          display="inline"
        ></path>
        <path
            {...assignIndex(12)}
          id="L2_x5F_respite_x5F_Wellness_00000093172025236017204330000007237298978624147382_"
          fill="#915C00"
          d="M881.213 700.112c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386 3.942 2.078 7.036 6.346 6.464 10.765-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.888-39.196 41.177z"
          display="inline"
        ></path>
        <path
            {...assignIndex(13)}
          id="L2_x5F_respite_x5F_Kitchen_00000107587074485244726700000003515423311341596853_"
          fill="#D9A300"
          d="M1021.261 739.996c-15.218 10.773-35.743 13.731-54.456 10.258s-35.724-12.867-50.135-24.572c-7.767-6.309-15.281-14.075-16.389-23.648-1.211-10.461 6.058-20.759 15.988-25.682s21.93-5.107 32.849-2.626 20.995 7.42 30.879 12.424c17.338 8.779 35.647 19.094 43.193 36.147 1.639 3.704 2.712 7.8 1.81 11.719-.904 3.92-4.199 7.554-3.739 5.98z"
          display="inline"
        ></path>
        <path
            {...assignIndex(14)}
            stroke="#915C00"
            strokeWidth={5}
          id="L2_x5F_respite_x5F_Phone_00000097460962785435919390000008100354150137671348_"
          fill="#D9A300"
          d="M1117.366 689.074c-.752-9.283-11.194-15.523-20.441-14.41-2.465.297-4.91 1.005-7.008 2.333-2.559 1.62-4.518 4.14-5.547 6.989-3.243 8.972 4.441 19.764 13.963 20.362 9.522.598 19.604-8.237 19.033-15.274z"
          display="inline"
        ></path>
        <path
            {...assignIndex(16)}
          id="L2_x5F_store_x5F_Lockers_00000074413569647567677900000015478981878863801000_"
          fill="#FF002E"
          d="M1250.33 551.353c-13.958-17.713-30.795-31.899-49.195-41.449-4.808-2.495-9.972-4.714-15.128-3.791-4.142.742-7.866 3.444-11.446 6.139-25.487 19.184-51.597 43.917-58.618 79.936-.871 4.467-1.424 9.117-.847 13.658 2.069 16.298 17.05 24.436 30.171 26.821 24.299 4.416 49.687-.997 71.418-15.226 10.344-6.773 20.041-15.712 26.594-27.755 6.555-12.042 13.409-30.265 7.051-38.333z"
          display="inline"
        ></path>
        <path
            {...assignIndex(15)}
          id="L2_x5F_store_x5F_Coats_00000088103343533153547560000000990005281067825311_"
          fill="#FF002E"
          d="M957.624 663.451c-7.454-.83-14.747-2.933-21.42-6.178-3.454-1.68-6.823-3.733-9.117-6.692s-3.345-6.983-1.876-10.377c1.912-4.414 7.227-6.464 12.091-7.758 21.806-5.801 45.397-5.482 67.017.906 1.096.324 2.215.677 3.102 1.365 1.917 1.487 2.263 4.247 1.427 6.451-.836 2.205-2.617 3.962-4.438 5.552-12.676 11.073-29.982 18.601-46.786 16.731z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L2_x5F_store_x5F_Supplies_00000052105438887895340060000006226182185480354435_"
          fill="#FF002E"
          d="M784.1 607.712c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083s-5.445 4.923-5.49 8.425c-.054 4.279 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325 2.432-3.786 2.67-10.233-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L2_x5F_store_x5F_print_area"
          fill="#FF002E"
          d="M955.683 621.985c-7.66-3.661-7.586-15.307-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.86-28.067 5.77z"
          display="inline"
        ></path>
        </svg>
    );
}

