import { rand } from '../../util';

/*
import splotch1 from '../../assets/splotches/Untitled_Artwork-1.png';
import splotch2 from '../../assets/splotches/Untitled_Artwork-2.png';
import splotch3 from '../../assets/splotches/Untitled_Artwork-3.png';
*/
import splotch4 from '../../assets/splotches/Untitled_Artwork-4.png';
//import splotch5 from '../../assets/splotches/Untitled_Artwork-5.png';

const splotches = [
    //splotch1,
    //splotch2,
    //splotch3,
    splotch4,
    //splotch5,
];

export default function Splotch({ index }) {
    const splotch = splotches[index || rand(0, splotches.length)];
    const rotation = rand(0, 360);

    return (
        <img className="Splotch" src={splotch} alt="" style={{ transform: `rotate(${rotation}deg)`}} />
    );
}

