import { useHenshu } from '@strategies/henshu';

import FadeUp from '../../animations/FadeUp';
import DoubleImage from '../../DoubleImage/DoubleImage';


export default function FloatingImage({ backMod, frontMod, name }) {
    const { bindTo } = useHenshu();

    return (
        <div className={`FloatingImage ${name}`}>
            <FadeUp>
                <DoubleImage
                    frontMod={frontMod}
                    backMod={backMod}
                    front={bindTo(`${name}.front`)}
                    back={bindTo(`${name}.back`)}
                />
            </FadeUp>
        </div>
    );
}
