import { henshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';


export default function ImageWrap({ className = '', get, set }) {
    const { isEditing } = useHenshuApp();

    return (
        <div className={`ImageWrap ${className}`} style={{ backgroundImage: `url(${get()})` }}>
            {isEditing && (
                <henshu.img get={get} set={set} />
            )}
        </div>
    );
};
