import { henshu, useHenshu } from '@strategies/henshu';


export default function ContentPanel({ className = '', title, body, footer }) {
    const { bindTo } = useHenshu();

    return (
        <div className={`ContentPanel ${className}`}>
            <henshu.h3 {...bindTo(title)} />

            <div className="body">
                <henshu.richtext {...bindTo(body)} />
            </div>

            <henshu.h4 {...bindTo(footer)} />
        </div>
    );
};
