const slate = '#3A3A3A';
const brown = '#915900';
const grey = '#AAAAAA';

const panels = [

    // Intro
    { id: '00-01', pins: [] },
    { id: '00-02', pins: [] },
    { id: '00-03', pins: [] },

    // History
    { id: '01-01', pins: [] },
    { id: '01-02a', pins: [] },
    { id: '01-02b', pins: [] },
    { id: '01-03a', pins: [] },
    { id: '01-03b', pins: [] },
    { id: '01-04a', pins: [] },
    { id: '01-04', pins: [] },
    { id: '01-05', pins: [] },
    { id: '01-06', pins: [] },
    { id: '01-07', pins: [] },

    {
        id: 'Community Groups',
        color: '#C04CFE',
        pins: [
            {
                x: .47, y: .77,
                title: 'ACDC',
                link: 'http://www.asiancdc.org/',
                address: '38 Oak St, Boston, MA 02111',
                notes: "Asian Community Devpt Corp Chinatown Chow: A Guide to Boston's Chinatown Restaurants by ACDC",
            },
            {
                x: .525, y: .75,
                title: 'Pao Arts Center',
                link: 'http://www.paoartscenter.org/',
                address: '99 Albany St, Boston, MA 02111',
                notes: 'Rentify Chinatown - Foundation Design Grant winner - worked with them',
            },
            {
                x: .46, y: .79,
                title: 'BCNC',
                link: 'https://bcnc.net/red-oak-afterschool-summer',
                address: '38 Ash St, Boston, MA 02111',
                notes: 'Boston Chinatown Neighborhood Center. Provides after school/summer programs, citizenship, career, and family support services',
            },
            {
                x: .327, y: .915,
                title: 'Castle Square Tenant Organization',
                link: 'https://www.cstoboston.org/',
                address: '476 Tremont St. Boston, MA 02116 ',
                notes: 'Similar to ACDC, have a youth program similar to SEED',
            },
            {
                x: .595, y: .23,
                title: 'Boston Harbor Now',
                link: 'https://www.bostonharbornow.org/',
                address: '15 State Street, Suite 1100',
                notes: "Represented on the Foundation's Advisory Council",
            },
            {
                x: .455, y: .59,
                title: 'CHSNE',
                link: 'https://www.chsne.org/about/',
                address: '2 Boylston St, G-6, Boston, MA 02116 ',
                notes: "Chinese Historical Society of New England. Award-winning local writer, Cynthia Yee, is a contributor. Offer Chinatown tours.",
            },
            {
                x: .468, y: .59,
                title: 'Gund Kwok',
                link: 'http://gundkwok.org/about-us/',
                address: 'China Trade Center (lower level), 2 Boylston Street, Boston, MA 02116',
                notes: "Asian Women's Lion and Dragon Dance Troupe",
            },
            {
                x: .785, y: .71,
                title: 'Fort Point Arts Community',
                link: 'https://www.fortpointarts.org/',
                address: '300 Summer Street, Boston MA 02210',
                notes: "Fort Point is home to over 300 artists who produce work in a wide array of media. It is recognized as one of New England’s largest artists’ communities.",
            },
            {
                x: .468, y: .798,
                title: 'Chinatown Community Land Trust',
                link: 'https://chinatownclt.org/',
                address: '28 Ash Street, Boston, MA 02111',
                notes: '"The Chinatown Community Land Trust was launched as an important tool for residents and community activists struggling for greater control of development and rooted in the belief that we have the right to our historic community."',
            },
            {
                x: .475, y: .8,
                title: 'Chinatown Progressive Association',
                link: 'https://cpaboston.org/',
                address: '28 Ash St. Boston, MA 02111',
                notes: '"The Chinese Progressive Association is a grassroots community organization working for full equality and empowerment of the Chinese community in the Greater Boston area and beyond."',
            },
            {
                x: .56, y: .257,
                title: 'Environmental League of MA ',
                link: 'https://www.environmentalleague.org/',
                address: '15 Court Square Suite 1000 Boston, MA 02108',
                notes: "Represented on the Foundation's Advisory Council",
            },
            {
                x: .595, y: .605,
                title: 'Interaction Institute for Social Change',
                link: 'https://interactioninstitute.org/',
                address: '89 South Street, Suite 405, Boston, MA 02111',
                notes: "Represented on the Foundation's Advisory Council",
            },
            {
                x: .568, y: .465,
                title: 'Transit Matters',
                link: 'https://transitmatters.org/',
                address: '62 Summer St, C/O CLF, Boston, MA, 02110',
                notes: '2019 Foundation Community Grant recipient',
            },
            {
                x: .595, y: .325,
                title: 'Transportation for Massachusetts',
                link: 'https://www.t4ma.org/#',
                address: '50 Milk Street, 16th Floor, Boston MA 02109',
                notes: 'Worked with the Foundation on Mobility Innovator',
            },
        ],
    },

    {
        id: 'Parks',
        color: '#005B2A',
        pins: [
            {
                x: .365, y: .12,
                title: 'Phillips St Park',
                address: '21 Phillips St, Boston, MA 02114',
                notes: 'Pint-sized park with playscape, north of the common',
            },
            {
                x: .51, y: .58,
                title: 'Phillips Square plaza',
                address: '1 Harrison Ave, Boston, MA 02111',
                notes: 'Around the corner from the office—see people make announcements there occasionally',
            },
            {
                x: .325, y: .67,
                title: 'Statler Park',
                address: '243 Stuart St, Boston, MA 02116',
                notes: 'Little park with benches right by Stuart St tower',
            },
            {
                x: .41, y: .4,
                title: 'Boston Common',
            },
            {
                x: .655, y: .525,
                title: 'Rose Kennedy Greenway',
                link: 'https://www.rosekennedygreenway.org/',
                notes: 'They have a volunteer program that Sasakians might be interested in (such as horticulture and public art guide)',
            },
            {
                x: .305, y: .485,
                title: 'Boston Public Garden',
            },
            {
                x: .64, y: .37,
                title: 'Post Office Square',
            },
            {
                x: .5, y: .3,
                title: 'Granary Burying Ground',
            },
            {
                x: .555, y: .13,
                title: 'City Hall Plaza',
            },
            {
                x: .552, y: .62,
                title: 'Chinatown Park/Chin Park',
            },
            {
                x: .567, y: .725,
                title: 'Reggie Wong Memorial Park',
            },
            {
                x: .4, y: .75,
                title: 'Eliot Norton Park',
            },
            {
                x: .326, y: .853,
                title: 'Chandler/Tremont Plaza',
            },
            {
                x: .32, y: .85,
                title: "Ellis Memorial Children's Park",
            },
            {
                x: .542, y: .665,
                title: 'Mary Soo Hoo (Chinatown Gateway) Park',
                link: 'http://www.bostonplans.org/planning/planning-initiatives/mary-soo-hoo-park-site-improvements',
                notes: "This .082 acre park was named after longtime community activist Mary Soo Hoo. Located adjacent to the Chinatown gate, this park is frequented by elders in the community as well as families of all ages, who gather around shaded tables and children's play space. In the middle of the park is a 45' vent structure that was wrapped with imagery of a 14th Century Chinese painting from the Boston Museum of Fine Arts' collections as part of a redesign led by STIMSON in 2009.",
            },
            {
                x: .73, y: .1,
                title: 'Christopher Columbus Waterfront Park',
            },
        ],
    },

    { id: 'Travel Time A', pins: [] },
    { id: 'Travel Time B', pins: [] },

    {
        id: 'Commuter Info',
        pins: [
            {
                x: .53, y: .4,
                title: 'Downtown Crossing',
                notes: 'T stop - Red Line, Orange Line',
                color: slate,
            },
            {
                x: .478, y: .358,
                title: 'Park Street',
                notes: 'T stop - Red Line, Green Line',
                color: slate,
            },
            {
                x: .425, y: .565,
                title: 'Boylston',
                notes: 'T stop - Green Line',
                color: slate,
            },
            {
                x: .474, y: .568,
                title: 'Chinatown',
                notes: 'T stop - Orange Line',
                color: slate,
            },
            {
                x: .646, y: .578,
                title: 'South Station',
                notes: 'T stop - Red Line, Silver Line, Commuter Rail',
                color: slate,
            },
            {
                x: .17, y: .855,
                title: 'Back Bay',
                notes: 'T stop - Orange Line, Commuter Rail',
                color: slate,
            },
            {
                x: .51, y: .51,
                title: 'Lafayette Garage',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .54, y: .571,
                title: '85 Essex',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .498, y: .58,
                title: 'Stanhope Garage',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .452, y: .568,
                title: 'Boston Common Theater Garage',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .444, y: .612,
                title: '47 Lagrange St',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .475, y: .611,
                title: '660 Washington St',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .495, y: .62,
                title: 'Beach Sreet Garage',
                notes: 'Parking garage',
                color: brown,
            },
            {
                x: .445, y: .51,
                title: 'Tremont St @ Boylston Station',
                notes: 'Bus stop - 43, SL5',
                color: grey,
            },
            {
                x: .479, y: .58,
                title: 'Washington St @ Essex St',
                notes: 'Bus stop - 11, SL4, SL5',
                color: grey,
            },
            {
                x: .586, y: .586,
                title: 'Lincoln St @ Essex St',
                notes: 'Bus stop - 501, 504, 505',
                color: grey,
            },
            {
                x: .625, y: .61,
                title: 'Essex St @ Atlantic Ave',
                notes: 'Bus stop - SL4',
                color: grey,
            },
            {
                x: .568, y: .466,
                title: 'Otis St @ Summer St',
                notes: 'Bus stop - 7, 501, 504, 505',
                color: grey,
            },
            {
                x: .55, y: .472,
                title: 'Chauncy St @ Summer St',
                notes: 'Bus stop - 7, 11',
                color: grey,
            },
            {
                x: .674, y: .41,
                title: 'Pearl St @ High St',
                notes: 'Bus stop - 4',
                color: grey,
            },
            {
                x: .595, y: .327,
                title: 'Devonshire St @ Milk St',
                notes: 'Bus stop - 92, 93',
                color: grey,
            },
            {
                x: .475, y: .923,
                title: 'Herald St @ Harrison Ave',
                notes: 'Bus stop - 9',
                color: grey,
            },
            {
                x: .474, y: .393,
                title: 'Tremont St opp Temple Pl',
                notes: 'Bus stop - 43',
                color: grey,
            },
            {
                x: .67, y: .6,
                title: 'Summer St @ South Station',
                notes: 'Bus stop - 4, 7, 11',
                color: grey,
            },
        ],
    },

    {
        id: 'Civic Resources',
        color: '#5D7EFC',
        pins: [
            {
                x: .712, y: .547,
                title: 'Boston Society for Architecture (BSA)',
                link: 'http://www.architects.org/',
                address: '290 Congress St #200, Boston, MA 02210',
            },
            {
                x: .505, y: .425,
                title: 'Metropolitan Area Planning Council (MAPC)',
                link: 'https://www.mapc.org/',
                address: '60 Temple Pl, Boston, MA 02111',
            },
            {
                x: .666, y: .305,
                title: 'Boston Private Industry Council',
                link: 'https://www.bostonpic.org/',
                address: '2 Oliver St, Boston, MA 02109',
                notes: 'Partners with the Foundation on Architecture + Design Thinking Boot Camp, a four-day workshop for high schoolers interested in design',
            },
            {
                x: .58, y: .15,
                title: "Mayor's Office of New Urban Mechanics",
                link: 'https://www.boston.gov/departments/new-urban-mechanics',
                address: '1 City Hall Square, 5th Floor, Boston, MA 02201',
                notes: 'Partnered with the Foundation on past speaker series events',
            },
            {
                x: .11, y: .728,
                title: 'Boston Public Library - Central Library',
                link: 'https://www.bpl.org/',
                address: '700 Boylston St, Boston, MA 02116',
            },
            {
                x: .507, y: .526,
                title: 'Post Office',
                address: '7 Ave de Lafayette, Boston, MA 02111',
                notes: 'Around the corner from the office',
            },
            {
                x: .485, y: .265,
                title: 'Boston Athenaeum',
                link: 'https://www.bostonathenaeum.org/',
                address: '10-1/2 Beacon St, Boston, MA 02108',
                notes: "Paid admission, but it's a really nice space inside. They have weird hours, so check before going. ",
            },
            {
                x: .58, y: .38,
                title: 'American Student Assistance',
                link: 'http://www.asa.org/',
                address: '33 Arch St #2100, Boston, MA 02110',
                notes: "Supported Foundation's SEED + Design Mentorship Program in the past ",
            },
            {
                x: .46, y: .58,
                title: 'BPL Chinatown Branch (Temporary Chinatown Library branch)',
                link: 'https://www.bpl.org/locations/83/',
                address: '2 Boylston Street, Boston, MA 02116',
                notes: "This temporary Boston Public Library branch opened in 2018 to fulfill long-standing community needs for gathering space, computer access, and a place to study within Chinatown. In 2020 the search for a permanent site continued, informed by feedback on the the temporary branch and a 2017 Chinatown Library Services Planning Study.",
            },
        ],
    },

    {
        id: 'Other design offices',
        color: '#FE7900',
        pins: [
            {
                x: .548, y: .58,
                title: 'Utile',
                link: 'http://www.utiledesign.com/',
                address: '115 Kingston St, Boston, MA 02111',
            },
            {
                x: .7, y: .525,
                title: 'Payette',
                link: 'http://www.payette.com/',
                address: '290 Congress St, Boston, MA 02210',
            },
            {
                x: .575, y: .5,
                title: 'CannonDesign',
                link: 'http://www.cannondesign.com/',
                address: '99 Summer St #600, Boston, Massachusetts 02110',
            },
            {
                x: .678, y: .368,
                title: 'Perkins&Will',
                link: 'http://www.perkinswill.com/',
                address: '225 Franklin St, Boston, MA 02110',
            },
            {
                x: .52, y: .23,
                title: 'NBJJ',
                link: 'http://www.nbbj.com/',
                address: '1 Beacon Street, Suite 5200, Boston, MA 02108',
            },
            {
                x: .52, y: .25,
                title: 'Gensler',
                link: 'http://www.gensler.com/',
                address: '1 Beacon St, Boston, MA 02108',
            },
            {
                x: .798, y: .57,
                title: 'Bergmeyer',
                link: 'http://www.bergmeyer.com/',
                address: '51 Sleeper St Ste 600, Boston, MA 02210',
            },
            {
                x: .55, y: .27,
                title: 'William Rawn Associates',
                link: 'http://www.rawnarch.com/',
                address: '27 School Street, Second Floor, Boston, MA 02108',
            },
            {
                x: .554, y: .51,
                title: 'Ann Beha Architects',
                link: 'http://www.annbeha.com/',
                address: '33 Kingston St, Boston, MA 02111',
            },
            {
                x: .64, y: .305,
                title: 'Arrowstreet',
                link: 'http://www.arrowstreet.com/',
                address: '10 Post Office Square, Boston, MA 02109',
            },
            {
                x: .516, y: .53,
                title: 'Shepley Bulfinch',
                link: 'http://www.shepleybulfinch.com/',
                address: '99 Chauncy St 4th Floor, Boston, MA 02111',
            },
            {
                x: .576, y: .65,
                title: 'Höweler and Yoon Architecture',
                link: 'http://www.howeleryoon.com/',
                address: '150 Lincoln St. 3A, Boston, MA 02111',
            },
            {
                x: .29, y: .855,
                title: 'MASS Design Group',
                link: 'https://www.massdesigngroup.org/',
                address: '1 Chandler Street, Boston, MA 02116',
            },
            {
                x: .55, y: .6,
                title: 'Dumont Janks',
                link: 'http://dumontjanks.com/projects/',
                address: '129 Kingston Street, Ground Floor, Boston, MA 02111',
            },
            {
                x: .59, y: .5,
                title: 'Icon Architecture',
                link: 'https://www.iconarch.com/',
                address: '101 Summer Street, Boston, MA 02110',
            },
            {
                x: .51, y: .09,
                title: 'OJB',
                link: 'https://www.ojb.com/',
                address: 'One Bowdoin Square, Suite 801, Boston, MA 02114',
            },
            {
                x: .59, y: .45,
                title: 'Dyer Brown',
                link: 'https://dyerbrown.com/about',
                address: 'One Winthrop Square, Boston, MA 02110',
            },
            {
                x: .52, y: .7,
                title: 'Leers Weinzapfel Associates',
                link: 'https://www.lwa-architects.com/',
                address: '75 Kneeland St Suite 301, Boston, MA 02111',
            },
            /*
            {
                x: 0, y: 0,
                title: 'Elkus Manfredi Architects',
                link: 'https://www.elkus-manfredi.com/',
                address: '25 Drydock Avenue, Boston, Massachusetts 02210',
            },
            */
            {
                x: .745, y: .89,
                title: 'designLAB architects',
                link: 'https://www.designlabarch.com/',
                address: '35 Channel Center Street, Suite 103, Boston, MA 02210',
            },

         ],
    },

    {
        id: 'Sasaki Projects',
        color: '#85002E',
        pins: [
            {
                x: .346, y: .68,
                title: '212 Stuart Street',
            },
            {
                x: .738, y: .12,
                title: 'Christopher Columbus Waterfront Park',
            },
            {
                x: .57, y: .09,
                title: 'Boston City Hall Plaza',
            },
            {
                x: .15, y: .714,
                title: 'Copley Square',
            },
            {
                x: .535, y: .41,
                title: 'Havas/Arnold Global HQ',
            },
            {
                x: .88, y: .995,
                title: 'The Lawn on D',
            },
            {
                x: .927, y: .955,
                title: 'D Street',
            },
            {
                x: .93, y: .78,
                title: '10 World Trade',
            },
            {
                x: .735, y: .74,
                title: 'Fort Point 100 Acres Open Space Concept Plan and Ground Floor Activation Guidelines',
            },
            {
                x: .9, y: .49,
                title: 'Seaport Square Masterplan',
            },
            {
                x: .775, y: .6,
                title: "Boston Children's Museum",
            },
        ],
    },


];


export default panels;
