import { observer } from 'mobx-react';
import { useHenshuApp, Curate } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';

import ContentPanel from '../../ContentPanel/ContentPanel';
import DoubleImage from '../../DoubleImage/DoubleImage';
import FadeUp from '../../animations/FadeUp';
import Issue from '../Issue/Issue';
import Parallax from '../../Parallax/Parallax';
import Section from '../../Section/Section';

import SasakiLogoWhite from '../../../assets/images/sasaki-logo-white.png';


export default observer(function FourthIssue() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;

    return <>
        <Issue className="FourthIssue">
            <header className="heavy-padding">
                <div>
                    <img className="sasaki-logo" src={SasakiLogoWhite} alt="Sasaki"/>

                    <henshu.img
                        className="gif"
                        alt="Hello 110 Chauncy"
                        get={() => bindTo('he110chauncy.april').get()}
                        set={async buf => bindTo('he110chauncy.april').set(await upload(buf))}
                    />
                </div>

                <FadeUp>
                    <henshu.h1 {...bindTo('issues.first.title')} />

                    <Parallax className="header-circle" magnitude={-.05}>
                        <henshu.img
                            get={() => bindTo('issues.first.title.circle').get()}
                            set={async buf => bindTo('issues.first.title.circle').set(await upload(buf))}
                        />
                    </Parallax>
                </FadeUp>
            </header>

            <Section className="banner">
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.fourth.banner.front')}
                        back={bindTo('')}
                    />
                </FadeUp>

                <FadeUp>
                    <Curate id="issues.fourth.banner" data={{ title: 'Intro' }}>
                        <henshu.richtext className="sans" {...bindTo('issues.fourth.banner.body')} />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="stuff">
                <FadeUp>
                    <henshu.h3 {...bindTo('issues.fourth.stuff.header')} />
                </FadeUp>

                <Curate id="issues.fourth.stuff.1" data={{ title: bindTo('issues.fourth.stuff.header').get() }}>
                    <div className="stuff-part">
                        <div className="stuff-part-col">
                            <FadeUp>
                                <henshu.h4 {...bindTo('issues.fourth.stuff.1.title')} />
                            </FadeUp>

                            <FadeUp>
                                <henshu.richtext {...bindTo('issues.fourth.stuff.1.body')} />
                            </FadeUp>
                        </div>

                        <div className="stuff-part-col">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.fourth.stuff.1.front')}
                                    back={bindTo('issues.fourth.stuff.1.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>
                </Curate>

                <Curate id="issues.fourth.stuff.2" data={{ title: bindTo('issues.fourth.stuff.header').get() }}>
                    <div className="stuff-part">
                        <div className="stuff-part-col">
                            <FadeUp>
                                <henshu.h4 {...bindTo('issues.fourth.stuff.2.title')} />
                            </FadeUp>

                            <FadeUp>
                                <henshu.richtext {...bindTo('issues.fourth.stuff.2.body')} />
                            </FadeUp>
                        </div>

                        <div className="stuff-part-col">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.fourth.stuff.2.front')}
                                    back={bindTo('issues.fourth.stuff.2.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>
                </Curate>

                <Curate id="issues.fourth.stuff.3" data={{ title: bindTo('issues.fourth.stuff.header').get() }}>
                    <div className="stuff-part">
                        <div className="stuff-part-col">
                            <FadeUp>
                                <henshu.h4 {...bindTo('issues.fourth.stuff.3.title')} />
                            </FadeUp>

                            <FadeUp>
                                <henshu.richtext {...bindTo('issues.fourth.stuff.3.body')} />
                            </FadeUp>
                        </div>

                        <div className="stuff-part-col">
                            <FadeUp>
                                <DoubleImage
                                    front={bindTo('issues.fourth.stuff.3.front')}
                                    back={bindTo('issues.fourth.stuff.3.back')}
                                />
                            </FadeUp>
                        </div>
                    </div>
                </Curate>

                <FadeUp>
                    <Curate id={'issue.fourth.stuff.footer'} data={{ title: bindTo('issues.fourth.stuff.header').get() }}>
                        <henshu.h4 {...bindTo('issue.fourth.stuff.footer')} />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="side-by-sides">
                <FadeUp>
                    <henshu.h3 {...bindTo('issue.fourth.amenities.header')} />
                </FadeUp>

                <henshu.each {...bindTo('issue.fourth.amenities')}>
                    {(bindToEach, i) => (
                        <div className="side-by-side">
                            <Curate key={i} id={`issue.fourth.amenities.${i+1}`} data={{ title: bindTo('issue.fourth.amenities.header').get() }}>
                                <FadeUp>
                                    <henshu.h4 {...bindToEach('title')} />
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext className="sans" {...bindToEach('body')} />
                                    <DoubleImage
                                        front={bindToEach('image.front')}
                                        back={bindToEach('image.back')}
                                    />
                                </FadeUp>

                                <FadeUp>
                                    <henshu.richtext {...bindToEach('list')} />
                                </FadeUp>
                            </Curate>
                        </div>
                    )}
                </henshu.each>
            </Section>

            <Section className="heavy-padding">
                <Curate id="issue.fourth.etiquette" data={{ title: bindTo('issues.fourth.intro.header').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.fourth.intro.header')} />
                    </FadeUp>

                    <FadeUp>
                        <henshu.p {...bindTo('issues.fourth.intro.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <ul className="intro">
                <henshu.each {...bindTo('issues.fourth.intro')}>
                    {(bindToIntro, i) => (
                        <li key={i} className={`intro-${i}`}>
                            <FadeUp>
                                <Curate id={`issue.fourth.etiquette.${i+1}`} data={{ title: bindTo('issues.fourth.intro.header').get() }}>
                                    <DoubleImage
                                        frontMod={.1}
                                        backMod={i === 5 ? .18 : .27}
                                        front={bindToIntro('image.front')}
                                        back={bindToIntro('image.back')}
                                    />

                                    <henshu.richtext {...bindToIntro('text')} />
                                </Curate>
                            </FadeUp>
                        </li>
                    )}
                </henshu.each>
            </ul>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        frontMod={.1}
                        backMod={.18}
                        front={bindTo('phonecall-friend.front')}
                        back={bindTo('phonecall-friend.back')}
                    />
                </FadeUp>
            </Section>

            <Section className="letters">
                <FadeUp>
                    <henshu.h3 {...bindTo('issues.fourth.letters.title')} />
                </FadeUp>

                <FadeUp>
                    <DoubleImage
                        className="saw-friend"
                        frontMod={.35}
                        front={bindTo('saw-friendfront')}
                        back={bindTo('saw-friend.back')}
                    />
                </FadeUp>

                <FadeUp>
                    <Curate id="issues.fourth.convo1" data={{
                        title: bindTo('issues.fourth.convo1.title').get(),
                        author: bindTo('issues.fourth.convo1.footer').get(),
                    }}>
                        <ContentPanel
                            className="quoted green"
                            title="issues.fourth.convo1.title"
                            body="issues.fourth.convo1.body"
                            footer="issues.fourth.convo1.footer"
                        />
                    </Curate>
                </FadeUp>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

                <FadeUp>
                    <DoubleImage
                        className="turkey-friend"
                        frontMod={.35}
                        backMod={.45}
                        front={bindTo('turkey-friend.front')}
                        back={bindTo('turkey-friend.back')}
                    />
                </FadeUp>

                <FadeUp>
                    <Curate id="issues.fourth.convo2" data={{
                        title: bindTo('issues.fourth.convo2.title').get(),
                        author: bindTo('issues.fourth.convo2.footer').get(),
                    }}>
                        <ContentPanel
                            className="quoted gold"
                            title="issues.fourth.convo2.title"
                            body="issues.fourth.convo2.body"
                            footer="issues.fourth.convo2.footer"
                        />
                    </Curate>
                </FadeUp>
            </Section>

            <Section className="heavy-padding">
                <FadeUp>
                    <DoubleImage
                        className="coffee-friend"
                        front={bindTo('issues.fourth.footer-friend.front')}
                        back={bindTo('issues.fourth.footer-friend.back')}
                        frontMod={0}
                        backMod={-.15}
                    />
                </FadeUp>
            </Section>
        </Issue>
    </>;
});

