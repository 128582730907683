import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useHenshuApp, useCurator } from '@strategies/henshu-app';

import config from './firebaseConfig';

import Index from './components/Index/Index';
import FirstIssue from './components/issues/First/FirstIssue';
import SecondIssue from './components/issues/Second/SecondIssue';
import ThirdIssue from './components/issues/Three/ThirdIssue';
import FourthIssue from './components/issues/Four/FourthIssue';
import FifthIssue from './components/issues/Five/FifthIssue';


const isDevelopment = process.env.NODE_ENV !== 'production';
const isProduction = process.env.REACT_APP_HENSHU_ENV === 'production';

if (isProduction) {
    !window.heap.loaded && window.heap.load('4138702579');
}

export default observer(function App() {
    const app = useHenshuApp();
    const curator = useCurator();
    useEffect(() => curator.initialize(config), [curator]);

    useEffect(() => {
        if (Object.keys(app.content).length > 0) {
            setTimeout(() => {
                document.getElementById(window.location.hash.replace('#', '')).scrollIntoView();
            }, 600);
        }
    }, [app.content]);

    return (
        <div className="App">
            <BrowserRouter basename={!isProduction ? (isDevelopment ? '' : '/hello110chauncy') : ''}>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/issues">
                        <Route path="one" element={<FirstIssue />} />
                        <Route path="two" element={<SecondIssue />} />
                        <Route path="three" element={<ThirdIssue />} />
                        <Route path="four" element={<FourthIssue />} />
                        <Route path="five" element={<FifthIssue />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
});
