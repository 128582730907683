import React, { useCallback } from "react";

export default function Floor8({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
        <path
            {...assignIndex(12)}
          id="L8_x5F_respite_x5F_wellness"
          fill="#915C00"
          d="M881.213 415.234c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386s7.036 6.346 6.464 10.765c-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.888-39.196 41.177z"
          display="inline"
        ></path>
        <path
            {...assignIndex(13)}
          id="L8_x5F_respite_x5F_kitchen"
          fill="#D9A300"
          d="M1177.681 417.147a301.18 301.18 0 01-64.905-31.897c-5.388-3.509-10.805-7.334-14.281-12.744-3.476-5.41-4.602-12.801-1.144-18.222 3.843-6.024 11.844-7.792 18.989-7.791 26.863.006 51.107 17.409 67.411 38.759 3.306 4.329 6.428 8.967 7.758 14.249s.581 11.363-3.121 15.358-10.68 4.974-10.707 2.288z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L8_x5F_collab_x5F_open_collab_space_00000025421382204455577000000013585521289947994796_"
          fill="#A88CAD"
          d="M1041.002 473.499c-9.109-3.536-19.628-3.269-28.546.725s-16.121 11.664-19.548 20.815c-1.179 3.149-1.93 6.584-1.235 9.875.583 2.759 2.134 5.209 3.787 7.494 9.837 13.602 24.436 23.688 40.638 28.078 6.734 1.824 14.311 2.586 20.362-.886 1.991-1.142 3.713-2.69 5.544-4.073 4.675-3.531 10.292-6.21 13.395-11.179 2.858-4.577 3.039-10.468 1.464-15.629-1.575-5.161-4.736-9.695-8.232-13.805-8.195-9.643-18.372-17.821-27.629-21.415z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L8_x5F_collab_x5F_open_collab_space_00000133510362999145126690000016555085034467995559_"
          fill="#A88CAD"
          d="M1331.585 296.081c-2.876-8.313-9.25-15.599-16.432-21.697-12.087-10.263-26.898-17.802-42.419-21.591-9.679-2.363-20.185-3.204-28.552.608-6.489 2.956-11.01 8.402-14.553 14.141-2.111 3.419-13.769 16.731-17.501 20.709-5.077 5.411-9.073 11.725-13.546 17.646a133.449 133.449 0 01-26.547 26.405c-3.136 2.344-6.41 4.579-8.979 7.533-2.57 2.954-4.401 6.796-4.012 10.692.718 7.188 8.076 11.419 14.015 15.533a65.86 65.86 0 0113.809 12.859c3.174 3.949 5.901 8.275 9.446 11.895 3.545 3.62 8.144 6.559 13.207 6.759 5.576.22 10.695-2.845 15.38-5.878 26.745-17.32 43.946-31.355 53.492-38.099 7.852-5.547 40.956-25.01 46.777-32.367 5.732-7.245 9.604-15.93 6.415-25.148z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L8_x5F_collab_x5F_pinup_00000075131245328550856200000010327745490724162704_"
          fill="#C24DFF"
          d="M1218.161 225.383c1.657-3.374 4.087-7.252 7.845-7.206 1.279.015 2.495.516 3.676 1.008l18.246 7.614c3.841 1.603 7.874 3.353 10.34 6.705 3.167 4.305 2.978 10.13 2.654 15.464l-.694 11.411c-.189 3.115-.392 6.291-1.535 9.195s-3.404 5.546-6.425 6.328c-4.146 1.073-8.271-1.499-11.784-3.949l-13.56-9.455c-3.057-2.131-6.165-4.311-8.431-7.27-3.388-4.425-4.567-10.279-4.012-15.824.556-5.544 2.207-11.022 3.68-14.021z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000021081183708046326520000009344038869709430436_"
          fill="#C24DFF"
          d="M736.635 336.702c-.779-2.778-1.606-5.65-3.47-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.906 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.46 4.54 3.313 9.246 7.069 12.184 3.756 2.938 9.958 3.241 12.827-.568 1.177-1.564 1.63-3.545 1.95-5.476 2.553-15.416-1.846-31.848-7.114-35.437z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000180354815501351498580000004555712394348808103_"
          fill="#C24DFF"
          d="M1163.095 295.799c11.638-1.765 22.167-6.951 30.562-13.235s14.91-13.662 21.287-21.002c2.773-3.191 5.556-6.422 7.29-9.92 2.848-5.745 2.697-11.835 2.51-17.764-.189-6.007-.497-12.315-4.859-17.239-8.079-9.12-27.828-9.829-41.994-4.183-14.166 5.646-23.686 15.879-31.243 26.079-11.31 15.266-19.613 33.874-8.848 48.118 4.684 6.194 14.757 10.743 25.295 9.146z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000089547357845492141830000014513334720967603349_"
          fill="#C24DFF"
          d="M966.713 621.351c15.97-6.572 30.684-15.752 43.26-26.99 9.84-8.793 18.759-19.72 19.602-32.36.837-12.553-6.649-24.591-16.758-32.99-10.109-8.398-22.654-13.773-34.993-19.024l-35.55-15.13c-12.927-5.502-26.598-11.132-40.771-9.986-14.1 1.139-26.469 8.809-38.058 16.336-11.746 7.629-24.073 16.15-28.812 28.691-5.965 15.784 2.18 33.618 14.758 45.615s29.031 19.693 44.29 28.632c11.184 6.552 22.013 13.938 34.484 18.039 12.47 4.101 27.035 3.905 38.548-.833z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000163794347163470784510000009492049981919052704_"
          fill="#C24DFF"
          d="M872.205 484.633c-8.633 9.687-17.331 19.435-27.644 27.396-10.313 7.961-22.452 14.116-35.508 15.541-2.767.302-5.807.321-8.04-1.306-1.69-1.231-2.619-3.196-3.482-5.077-3.483-7.598-7.049-15.801-5.469-23.983.848-4.389 3.128-8.38 5.6-12.136 7.866-11.949 18.303-22.609 31.357-28.847s28.921-7.627 42.147-1.748c2.666 1.185 5.253 2.686 7.161 4.859 4.532 5.16 4.191 13.256.617 19.092-3.572 5.837-9.742 9.718-6.739 6.209z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000050640636540019178500000017507275323428243103_"
          fill="#C24DFF"
          d="M780.905 486.072c-18.744-19.387-26.386-46.596-33.367-72.606-1.597-5.949-3.206-12.001-3.028-18.157.178-6.156 2.404-12.542 7.243-16.371 8.917-7.055 22.397-2.736 31.258 4.389 6.36 5.114 11.621 11.44 16.655 17.857 5.929 7.557 11.703 15.483 14.671 24.609 3.979 12.238 2.399 26.157-4.223 37.199-6.622 11.042-18.173 19.02-29.209 23.08z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L8_x5F_collab_x5F_conf-room_00000070118316406316135080000006965480591315569848_"
          fill="#C24DFF"
          d="M980.22 406.065c5.84-.365 11.586 1.767 16.651 4.698 7.119 4.119 13.814 11.229 12.869 19.399-.755 6.52-6.116 11.471-11.464 15.276-5.694 4.051-11.917 7.59-18.755 9.028s-14.398.562-20.039-3.564c-5.64-4.126-8.827-11.834-6.579-18.451 1.081-3.182 3.246-5.862 5.369-8.468l6.961-8.545s9.147-9.008 14.987-9.373z"
          display="inline"
        ></path>
        <path
            {...assignIndex(3)}
          id="L8_x5F_respite_x5F_lounge_00000061464125555376622250000006226765323178594482_"
          fill="#C24DFF"
          d="M919.193 439.628c-9.311-2.586-17.271-6.407-17.271-17.736 0-12.301 17.414-31.453 28.356-37.245s25.032-.488 34.459 7.538c7.22 6.147-11.037 30.658-14.063 34.276-7.036 8.412-11.227 18.793-31.481 13.167z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L8_x5F_store_x5F_supplies"
          fill="#FF002E"
          d="M789.247 319.735c-4.664-2.212-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083-3.007 1.797-5.445 4.923-5.49 8.425-.055 4.279 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325 2.431-3.786 2.669-10.234-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L8_x5F_store_x5F_print_area"
          fill="#FF002E"
          d="M955.892 347.169c-7.66-3.661-7.586-15.306-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.859-28.067 5.77z"
          display="inline"
        ></path>
        </svg>
    );
}
