import React, { useCallback } from "react";

export default function Floor5({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
        <pattern
          id="L5_x5F_combo_x5F_workstations_00000085247292909009119940000002251599261959118771_"
          patternTransform="rotate(45.001 -6846.635 -6771.73)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L5_x5F_combo_x5F_workstations"
          fill="url(#L5_x5F_combo_x5F_workstations_00000085247292909009119940000002251599261959118771_)"
          d="M1232.125 528.502a29.328 29.328 0 00-10.017-28.85c-15.888-13.089-24.655-17.625-51.229-22.628-7.595-1.43-40.706 2.889-48.07 5.234-9.776 3.113-17.703 11.311-20.789 21.095s-1.364 20.899 4.273 29.471c4.684 7.123 11.708 12.344 18.784 17.099 15.192 10.21 31.854 19.174 50.03 21.335 18.176 2.162 38.171-3.642 49.121-18.31 2.439-3.268 5.671-14.199 7.897-24.446z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L5_x5F_collab_x5F_open_collab_space_00000021109337890618366320000017569213022877098413_"
          fill="#A88CAD"
          d="M1167.887 575.529c-5.732-4.193-13.363-3.955-19.778-1.259-6.415 2.696-11.887 7.542-17.243 12.314-9.049 8.062-18.377 16.5-23.527 28.068-3.428 7.699-3.478 19.398 3.731 22.442 4.032 1.703 8.508-.242 12.384-2.349 13.796-7.501 26.526-17.433 37.573-29.314 3.339-3.591 6.606-7.497 8.255-12.332 2.037-5.975 2.662-14.602-1.395-17.57z"
          display="inline"
        ></path>
        <path
            {...assignIndex(2)}
          id="L5_x5F_collab_x5F_open_collab_space_00000116951750798465040700000009885533209393533314_"
          fill="#A88CAD"
          d="M1052.795 632.527c-34.568-14.969-71.466-26.52-109.401-27.453-22.756-.56-44.686.145-57.942-2.405-13.967-2.686-18.31-8.478-24.128-13.837a156.335 156.335 0 01-32.521-42.407c-2.634-5.036-5.134-10.395-9.568-13.949-6.097-4.887-14.591-5.36-22.376-4.688-13.849 1.196-28.308 5.946-36.761 16.98-7.365 9.614-9.018 22.636-7.219 34.613 3.696 24.616 20.127 45.138 35.954 64.351 9.072 11.013 43.831 51.449 52.764 59.496 8.932 8.047 20.007 13.541 30.924 18.929l32.513 16.048c7.877 3.888 15.818 7.8 24.344 10.136 28.976 7.936 60.285-3.565 84.576-20.574 24.291-17.009 57.647-45.093 64.606-56.445 9.674-15.779-6.787-30.577-25.765-38.795z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L5_x5F_collab_x5F_conf_rooms_00000008144199230125123340000008961270864812969397_"
          fill="#C24DFF"
          d="M1326.03 450.318c.305-12.645-10.417-21.764-20.402-27.441-14.496-8.241-30.401-14.271-46.614-13.701-16.214.57-32.779 8.55-41.976 23.676-5.237 8.612-7.837 19.926-4.583 29.711 3.451 10.378 12.56 16.911 21.661 21.009 17.941 8.077 38.003 9.524 56.938 5.251 10.093-2.278 20.298-6.419 27.426-14.823s10.314-21.898 7.55-23.682z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L5_x5F_collab_x5F_conf_rooms_00000037687730340366751790000005851283310893245100_"
          fill="#C24DFF"
          d="M740.501 486.272c-.779-2.778-1.606-5.649-3.469-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.905 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.459 4.54 3.313 9.245 7.069 12.184 3.756 2.938 9.958 3.241 12.827-.568 1.178-1.564 1.63-3.545 1.95-5.476 2.552-15.416-1.848-31.848-7.115-35.437z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L5_x5F_collab_x5F_conf_rooms_00000075124818249600611390000010475476284032967566_"
          fill="#C24DFF"
          d="M1090.639 463.927c-2.052-4.774-2.506-10.072-2.625-15.267-.277-11.999 1.442-24.865 9.335-33.906 10.334-11.838 27.893-13.653 43.603-13.318 3.791.081 7.696.265 11.136 1.861 3.927 1.822 6.825 5.291 9.303 8.842a83.182 83.182 0 0113.009 29.757c1.413 6.472 1.985 13.503-.854 19.488-2.715 5.723-8.151 9.649-13.68 12.738-18.217 10.177-42.12 13.408-59.422 1.745-.001-.001-7.753-7.166-9.805-11.94z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L5_x5F_collab_x5F_pinup_00000039840685952422054310000018380403790456277905_"
          fill="#C24DFF"
          d="M1024.867 463.773c-.147-4.953-.095-9.912.154-14.861.093-1.851.267-3.841 1.463-5.257 1-1.185 2.538-1.748 4.012-2.228a102.338 102.338 0 0132.049-5.019c1.679.007 3.477.094 4.829 1.09 1.972 1.452 2.272 4.232 2.392 6.679.499 10.18.848 20.368 1.047 30.559.018.928.026 1.897-.387 2.727-.571 1.148-1.812 1.779-2.989 2.289-10.346 4.479-21.826 5.471-33.085 6.059-1.56.081-3.202.14-4.593-.57-1.347-.688-2.258-1.999-2.978-3.329-3.29-6.068-1.914-18.139-1.914-18.139z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L5_x5F_collab_x5F_pinup"
          fill="#C24DFF"
          d="M1136.029 540.024l-46.553 35.19c-4.807 3.634-10.382 7.47-16.321 6.444-4.796-.829-8.425-4.624-12.526-7.245-4.1-2.622-10.228-3.802-13.325-.047-1.416 1.718-1.793 4.04-2.118 6.242-1.655 11.211-2.766 24.231 5.174 32.318 4.418 4.5 10.763 6.415 16.822 8.165 3.294.951 6.702 1.912 10.102 1.47 3.478-.452 6.59-2.326 9.528-4.242 11.893-7.753 22.833-16.862 33.742-25.948 6.743-5.616 13.725-11.547 17.141-19.63 3.315-7.842 2.841-16.698 2.31-25.195-.12-1.915-.278-3.943-1.398-5.501-1.119-1.559-3.566-2.323-2.578-2.021z"
          display="inline"
        ></path>
        <path
            {...assignIndex(12)}
          id="L5_x5F_respite_x5F_wellness"
          fill="#915C00"
          d="M881.213 557.117c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386 3.942 2.078 7.036 6.346 6.464 10.765-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.888-39.196 41.177z"
          display="inline"
        ></path>
        <path
            {...assignIndex(14)}
            stroke="#915C00"
            strokeWidth={5}
          id="L5_x5F_respite_x5F_phone"
          fill="#D9A300"
          d="M1117.283 546.934c-.53-3.043-2.874-5.697-5.828-6.597-3.958-1.207-8.233.649-11.513 3.171-3.649 2.806-6.672 6.596-7.908 11.03-.935 3.354-.517 7.542 2.387 9.462 1.285.85 2.87 1.122 4.41 1.166 4.926.143 9.873-1.95 13.201-5.584 3.329-3.634 5.922-8.795 5.251-12.648z"
          display="inline"
        ></path>
        <path
            {...assignIndex(13)}
          id="L5_x5F_respite_x5F_kitchen"
          fill="#D9A300"
          d="M1033.606 608.978c-16.77 12.675-39.386 16.155-60.006 12.07-20.62-4.085-39.365-15.138-55.245-28.911-8.558-7.422-16.839-16.56-18.06-27.823-1.335-12.308 6.675-24.424 17.617-30.216 10.942-5.792 24.165-6.008 36.197-3.09 12.032 2.918 23.136 8.73 34.026 14.618 19.105 10.329 39.28 22.465 47.596 42.529 1.806 4.358 2.988 9.177 1.994 13.788-.994 4.612-4.625 8.886-4.119 7.035z"
          display="inline"
        ></path>
        <path
            {...assignIndex(16)}
          id="L5_x5F_store_x5F_lockers"
          fill="#FF002E"
          d="M1250.33 397.358c-13.958-13.426-30.795-24.179-49.195-31.418-4.808-1.891-9.972-3.573-15.128-2.873-4.142.562-7.866 2.611-11.446 4.653-25.487 14.541-31.492 27.827-26.636 37.028 5.321 10.082 14.553 17.419 20.617 21.929 11.089 8.248 17.951 11.985 38.575 15.376 12.083 1.986 29.609-6.511 36.163-15.639s13.408-22.94 7.05-29.056z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L5_x5F_store_x5F_supplies"
          fill="#FF002E"
          d="M783.51 459.89c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083-3.007 1.797-5.445 4.923-5.49 8.425-.054 4.28 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325 2.432-3.786 2.67-10.233-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(15)}
          id="L5_x5F_store_x5F_coats"
          fill="#FF002E"
          d="M1006.992 490.958c-.751.484-1.494.988-1.99 1.73-1.749 2.62-2.305 3.529-4.901 8.805-1.951 3.965-19.853.793-24.575 1.04s-10.021-1.666-11.023-5.027c-1.021-3.424 2.797-6.759 7.224-8.15s9.397-1.424 14.155-1.998c5.55-.669 11.167-2.097 16.664-1.229 1.967.311 4.006 1.026 4.815 2.365.558.922.156 2.126-.369 2.464z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L5_x5F_store_x5F_print_area"
          fill="#FF002E"
          d="M954.133 484.12c-7.66-3.661-7.586-15.306-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.859-28.067 5.77z"
          display="inline"
        ></path>
        </svg>
    );
}
