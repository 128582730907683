import React, { useCallback } from "react";

export default function Floor4({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
       <pattern
          id="L4_x5F_combo_x5F_workstation_00000037679093550282564160000016212758783611929504_"
          patternTransform="rotate(45.001 -6846.635 -6771.73)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L4_x5F_combo_x5F_workstation_00000134953762760529378780000014419185054549164443_"
          fill="url(#L4_x5F_combo_x5F_workstation_00000037679093550282564160000016212758783611929504_)"
          d="M768.971 598.778c-4.041-4.807-12.111-3.994-17.889-1.533-4.646 1.978-6.004 7.936-5.795 12.981.424 10.226 4.638 19.885 9.2 29.047 25.144 50.498 63.244 94.478 109.641 126.563 11.256 7.784 23.561 15.074 37.2 16.187 12.229.997 24.453-3.157 34.895-9.599 11.409-7.038 21.259-17.117 26.282-29.546 5.024-12.429 4.738-27.302-2.368-38.668-4.41-7.055-11.069-12.387-17.857-17.198-18.286-12.96-38.37-23.128-58.37-33.246l-44.667-22.597c-26.137-13.221-49.601-26.32-70.272-32.391z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L4_x5F_collab_x5F_conference_room"
          fill="#C24DFF"
          d="M976.927 598.793c5.84-.365 11.586 1.767 16.651 4.698 7.119 4.119 13.814 11.229 12.869 19.399-.755 6.52-6.116 11.471-11.464 15.276-5.694 4.051-11.917 7.59-18.755 9.028-6.839 1.439-14.398.562-20.039-3.564-5.641-4.126-8.827-11.834-6.579-18.451 1.081-3.182 3.246-5.862 5.369-8.468l6.961-8.545c-.001 0 9.147-9.009 14.987-9.373z"
          display="inline"
        ></path>
        <path
            {...assignIndex(1)}
          id="L4_x5F_collab_x5F_conference_room_00000132786700132857445670000014245074602657019278_"
          fill="#C24DFF"
          d="M736.501 520.968c-.779-2.778-1.606-5.65-3.469-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.905 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.459 4.54 3.313 9.246 7.069 12.184 3.756 2.938 9.958 3.241 12.827-.568 1.178-1.564 1.63-3.545 1.95-5.476 2.552-15.416-1.848-31.848-7.115-35.437z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L4_x5F_collab_x5F_pinup"
          fill="#C24DFF"
          d="M1024.867 510.329c-.147-4.953-.095-9.912.154-14.861.093-1.851.267-3.841 1.463-5.257 1-1.185 2.538-1.748 4.012-2.228a102.338 102.338 0 0132.049-5.019c1.679.007 3.477.094 4.829 1.09 1.972 1.452 2.272 4.232 2.392 6.679.499 10.18.848 20.368 1.047 30.559.018.928.026 1.897-.387 2.727-.571 1.148-1.812 1.779-2.989 2.289-10.346 4.479-21.826 5.471-33.085 6.059-1.56.081-3.202.14-4.593-.57-1.347-.688-2.258-1.999-2.978-3.329-3.29-6.069-1.914-18.139-1.914-18.139z"
          display="inline"
        ></path>
        <path
            {...assignIndex(4)}
          id="L4_x5F_collab_x5F_pinup_00000042702270668721481140000009401778427339512221_"
          fill="#C24DFF"
          d="M1134.508 588.985l-46.553 35.19c-4.807 3.634-10.382 7.47-16.321 6.444-4.796-.829-8.425-4.624-12.526-7.245-4.1-2.622-10.228-3.802-13.325-.047-1.416 1.718-1.793 4.04-2.118 6.242-1.655 11.211-2.766 24.231 5.174 32.318 4.418 4.5 10.763 6.415 16.822 8.165 3.294.951 6.702 1.912 10.102 1.47 3.478-.452 6.59-2.326 9.528-4.242 11.893-7.753 22.833-16.862 33.742-25.948 6.743-5.616 13.725-11.547 17.141-19.63 3.315-7.842 2.841-16.698 2.31-25.195-.12-1.915-.278-3.943-1.398-5.501-1.118-1.558-3.566-2.322-2.578-2.021z"
          display="inline"
        ></path>
        <path
            {...assignIndex(3)}
          id="L4_x5F_collab_x5F_lounge_00000030452735642247088540000008393985501020714374_"
          fill="#C24DFF"
          d="M915.9 632.356c-9.311-2.586-17.271-6.407-17.271-17.735 0-12.301 17.414-31.453 28.356-37.245 10.942-5.792 25.032-.488 34.459 7.538 7.22 6.147-11.037 30.658-14.063 34.276-7.036 8.411-11.227 18.792-31.481 13.166z"
          display="inline"
        ></path>
        <path
            {...assignIndex(12)}
          id="L4_x5F_respite_x5F_wellness_00000071531765627034225360000000054631823069647498_"
          fill="#915C00"
          d="M881.213 606.079c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386s7.036 6.346 6.464 10.765c-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.888-39.196 41.177z"
          display="inline"
        ></path>
        <path
            {...assignIndex(14)}
            stroke="#915C00"
            strokeWidth={5}
          id="L4_x5F_respite_x5F_phone_00000151544116350023164820000009195437810484227488_"
          fill="#D9A300"
          d="M1117.283 588.444c-.53-3.043-2.874-5.697-5.828-6.597-3.958-1.207-8.233.649-11.513 3.171-3.649 2.806-6.672 6.596-7.908 11.03-.935 3.354-.517 7.542 2.387 9.462 1.285.85 2.87 1.122 4.41 1.167 4.926.143 9.873-1.95 13.201-5.584 3.329-3.635 5.922-8.795 5.251-12.649z"
          display="inline"
        ></path>
        <path
            {...assignIndex(18)}
          id="L4_x5F_store_x5F_shelves_etc_00000175280260620007845440000009750756511726192792_"
          fill="#FF002E"
          d="M892.887 668.938c-9.675-5.628-19.867-9.115-30.013-10.27-2.47-.281-5.342-.165-6.576 2.546-1.125 2.471-.257 6.137 1.382 8.477s3.86 3.668 6.005 4.924c13.589 7.957 27.374 15.993 41.628 19.035 3.253.694 7.043.85 8.897-2.494 1.983-3.578.475-9.498-2.296-12.781s-6.385-4.596-9.833-5.794c-4.177-1.45-8.284-3.114-9.194-3.643z"
          display="inline"
        ></path>
        <path
            {...assignIndex(18)}
          id="L4_x5F_store_x5F_shelves_etc_00000014622248317916318060000013745856104602206344_"
          fill="#FF002E"
          d="M781.567 629.316c14.305 11.741 30.663 23.877 49.137 22.792 3.016-.177 6.607-1.226 7.364-4.151.816-3.158-2.265-5.871-5.043-7.58a118.091 118.091 0 00-36.396-14.706c-2.453-.54-5.084-.988-7.419-.064-2.336.926-4.088 3.755-7.643 3.709z"
          display="inline"
        ></path>
        <path
            {...assignIndex(18)}
          id="L4_x5F_store_x5F_shelves_etc_00000008137968264787347190000012414923365927006861_"
          fill="#FF002E"
          d="M998.896 720.511c-14.565-10.87-31.441-18.627-49.15-22.593-4.558-1.021-10.252-1.386-12.915 2.413-1.988 2.836-1.201 6.935.935 9.694 2.136 2.759 5.329 4.468 8.458 6.016a210.667 210.667 0 0041.823 15.425c3.153.796 6.919 1.374 9.267-.844 3.812-3.598-.17-10.114 1.582-10.111z"
          display="inline"
        ></path>
        <path
            {...assignIndex(19)}
          id="L4_x5F_store_x5F_shelves_etc_00000142168704742966864350000011673986976155287951_"
          fill="#FF002E"
          d="M783.1 505.636c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083-3.007 1.797-5.445 4.923-5.49 8.425-.054 4.279 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325s2.67-10.233-.589-11.779z"
          display="inline"
        ></path>
        <path
            {...assignIndex(20)}
          id="L4_x5F_store_x5F_print_area_00000002359928987609679620000011613528436319670434_"
          fill="#FF002E"
          d="M955.892 530.676c-7.66-3.661-7.586-15.306-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.859-28.067 5.77z"
          display="inline"
        ></path>
        <path
            {...assignIndex(5)}
          id="L4_x5F_collab_x5F_model_x26_woodshops_00000159435868790712782040000004681566925918681261_"
          fill="#C24DFF"
          d="M932.393 802.139c8.251 11.748 54.05-8.945 65.4-14.294 12.028-5.668 34.097-22.571 103.621-91.571 21.409-21.247 41.816-35.604 58.298-49.649 14.325-12.207 32.262-23.732 52.801-47.689 9.453-11.026 36.871-34.568 35.387-40.181-2.706-10.24-21.027-16.008-35.83-13.773-18.754 2.831-51.502 35.375-69.186 54.59-19.295 20.965-19.378 14.536-65.009 52.677-42.271 35.333-39.234 40.121-74.43 68.285-12.872 10.3-24.373 22.452-37.791 31.975-12.079 8.573-23.982 15.591-30.568 30.783-2.201 5.081-6.079 14.026-2.693 18.847z"
          display="inline"
        ></path>
        <path
            {...assignIndex(5)}
          id="L4_x5F_collab_x5F_model_x26_woodshops_00000080173401871837656810000005544917899109509280_"
          fill="#C24DFF"
          d="M1241.542 551.995c-9.49-.651-5.207-2.686-53.08-19.515-30.797-10.827-43.257-13.742-57.816-26.047-7.902-6.679-20.623-17.431-20.331-31.967.204-10.145 6.645-17.088 17.154-28.415 5.532-5.962 19.274-19.515 42.567-29.599 9.951-4.308 18.11-7.84 28.59-8.88 23.528-2.335 38.021 9.948 67.981 25.455 51.932 26.88 66.741 20.179 71.793 33.743 10.888 29.227-47.339 88.623-96.858 85.225z"
          display="inline"
        ></path>
        </svg>
    );
}

