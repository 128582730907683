const config = {
    apiKey: "AIzaSyD6lSXx60mw-XMv79qWCUTOSk2vS4tK9p8",
    authDomain: "chauncy-welcome-guide.firebaseapp.com",
    projectId: "chauncy-welcome-guide",
    storageBucket: "chauncy-welcome-guide.appspot.com",
    messagingSenderId: "818611914064",
    appId: "1:818611914064:web:e352c8d96c6267394e9855"
};

export default config;
