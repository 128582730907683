import React, { useCallback } from "react";

export default function Floor9({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])


    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
        <path
            {...assignIndex(11)}
          id="L9_x5F_respite_x5F_roof_deck_00000011007421136761981620000002243131550858744490_"
          fill="#D9A300"
          d="M1217.74 368.554c17.121-5.977 40.176-28.532 53.659-38.751 10.549-7.996 50.342-38.304 51.245-49.798.897-11.415-4.988-19.29-15.825-26.927-10.837-7.637-24.286-12.524-37.515-17.3l-38.113-13.758c-13.859-5.003-26.107-12.944-53.989-8.347-14.899 2.456-57.749 38.783-70.173 45.627-12.593 6.938-19.265 30.647-30.889 26.09-48.337-18.949-66.542-11.375-59.033-1.193 8.116 11.006 44.566 25.798 57.882 34.1 14.719 9.176 48.095 26.481 64.454 34.609 11.99 5.958 23.6 12.674 36.97 16.404 13.369 3.731 28.983 3.553 41.327-.756z"
          display="inline"
        ></path>
        </svg>
    );
}

