import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { Pinboard } from '@strategies/react-pinboard';


export default observer(function CoMapPanel({ className = '' }) {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;

    return (
        <div className={`MapSequence__Panel CoMapPanel ${className}`}>
            <div className="AutoSizer">
                <Pinboard aspect={1752/1004}>
                    <henshu.img
                        get={() => bindTo('issues.third.comap-panel.image').get()}
                        set={async v => bindTo('issues.third.comap-panel.image').set(await upload(v))}
                    />
                </Pinboard>
            </div>

            <div className="content">
                <div className="panel">
                    <henshu.h3 {...bindTo('issues.third.comap-panel.title')} />
                    <henshu.richtext {...bindTo('issues.third.comap-panel.body')} />
                </div>
            </div>
        </div>
    );
});
