export default function Marker({ color, onClick }) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 70 85"
          preserveAspectRatio="xMinYMin meet"
          onClick={onClick}
        >
          <defs>
            <path
              id="a1000z"
              d="M35 5c16.569 0 30 13.117 30 29.297S35 80 35 80 5 50.477 5 34.297C5 18.117 18.431 5 35 5z"
            ></path>
            <filter
              id="a1002z"
              width="134.8%"
              height="127.8%"
              x="-17.5%"
              y="-14%"
              filterUnits="objectBoundingBox"
            >
              <feOffset in="SourceAlpha" result="a1004z"></feOffset>
              <feGaussianBlur
                in="a1004z"
                result="a1005z"
                stdDeviation="2.5"
              ></feGaussianBlur>
              <feColorMatrix
                colorInterpolationFilters="sRGB"
                in="a1005z"
                result="a1006z"
                values="0 0 0 0 0.02 0 0 0 0 0 0 0 0 0 0.22 0 0 0 0.5 0"
              ></feColorMatrix>
            </filter>
          </defs>
          <g filter="url(#a1002z)">
            <use
              stroke="#000"
              strokeOpacity="0"
              strokeWidth="0"
              clipPath="url(#a1001z)"
              xlinkHref="#a1000z"
            ></use>
          </g>
          <use fill={color} clipPath="url(#a1001z)" xlinkHref="#a1000z"></use>
        </svg>
    );
}
