import { useCallback, useState, createElement } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';
import {
    FiWifi,
    FiPrinter,
    FiUsers,
    FiCalendar,
    FiCoffee,
    FiMonitor,
    FiShoppingBag,
    FiStar,
    FiMail,
    FiScissors,
    FiBookOpen,
    FiFeather,
    FiMessageSquare,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';

import { MdDirectionsBike, MdOutlineShower } from 'react-icons/md';


const ROBIN_FLOORS = {
    '02': 137983,
    '03': 132090,
    '04': 132086,
    '05': 132088,
    '06': 132087,
    '07': 132091,
    '08': 132089,
};

const ICONS = {
    'wifi': { icon: FiWifi, name: "Wifi" },
    'printer': { icon: FiPrinter, name: "Printers" },
    'users': { icon: FiUsers, name: "Collaboration Spaces" },
    'calendar': { icon: FiCalendar, name: "Conference Rooms" },
    'coffee': { icon: FiCoffee, name: "Kitchen" },
    'monitor': { icon: FiMonitor, name: "Workstations" },
    'shopping-bag': { icon: FiShoppingBag, name: "Lockers" },
    'star': { icon: FiStar, name: "Nursing Room / Wellness Room" },
    'mail': { icon: FiMail, name: "Mail Room" },
    'scissors': { icon: FiScissors, name: "Shop" },
    'book-open': { icon: FiBookOpen, name: "Libraries" },
    'feather': { icon: FiFeather, name: "Outdoor" },
    'message': { icon: FiMessageSquare, name: "Special Event Space" },
    'bike-racks': { icon: MdDirectionsBike, name: "Bike Racks" },
    'showers': { icon: MdOutlineShower, name: "Showers" },
};


export default observer(function OfficeStatus() {
    const { bindTo } = useHenshu();

    return <>
        <Level id="Basement" icons={['bike-racks', 'showers']} {...bindTo('intro.levels.basement')} />
        <Level id="Level 02" icons={['wifi', 'printer', 'users', 'calendar', 'coffee', 'monitor', 'shopping-bag', 'star', 'mail', 'message']} {...bindTo('intro.levels.2')} />
        <Level id="Level 03" icons={['wifi', 'printer', 'users', 'calendar', 'monitor', 'star']} {...bindTo('intro.levels.3')} />
        <Level id="Level 04" icons={['wifi', 'printer', 'users', 'calendar', 'monitor', 'star', 'scissors']} {...bindTo('intro.levels.4')} />
        <Level id="Level 05" icons={['wifi', 'printer', 'users', 'calendar', 'coffee', 'monitor', 'shopping-bag', 'star']} {...bindTo('intro.levels.5')} />
        <Level id="Level 06" icons={['wifi', 'printer', 'users', 'calendar', 'monitor', 'star', 'book-open']} {...bindTo('intro.levels.6')} />
        <Level id="Level 07" icons={['wifi', 'printer', 'users', 'calendar', 'monitor', 'star', 'book-open']} {...bindTo('intro.levels.7')} />
        <Level id="Level 08" icons={['wifi', 'printer', 'users', 'calendar', 'coffee', 'monitor', 'star', 'message']} {...bindTo('intro.levels.8')} />
        <Level id="Roof" icons={['wifi', 'users', 'feather', 'message']} {...bindTo('intro.levels.roof')} />
    </>;
});


const Level = observer(function Level({ id, icons, get, set }) {
    const { bindTo } = useHenshu();
    const [open, setOpen] = useState(false);
    const { semi = [], active = [], floorplan = '' } = get();
    const { isEditing, persist } = useHenshuApp();

    const update = useCallback(obj => set({ ...get(), ...obj }), [get, set]);
    const toggleOpen = useCallback(() => setOpen(!open), [open]);
    const toggleButton = useCallback(icon => {
        if (isEditing) {
            const index = active.indexOf(icon);

            if (index !== -1) {
                active.splice(index, 1);
            }
            else {
                const semiIndex = semi.indexOf(icon);

                if (semiIndex !== -1) {
                    semi.splice(semiIndex, 1);
                    active.push(icon);
                }
                else {
                    semi.push(icon);
                }
            }

            update({ semi: Array.from(new Set(semi)), active: Array.from(new Set(active)) });
        }
    }, [isEditing, semi, active, update]);

    return (
        <div className={`Level ${open ? 'open' : ''} ${active.length > 0 ? 'active' : ''}`}>
            <header>
                <div className="progress">
                    <div className="bar">
                        <span style={{ width: `${Math.floor(100 * (new Set(active)).size / icons.length)}%` }} />
                    </div>
                </div>

                <div className="title">{id}</div>

                <div className="icons">
                    {icons.map(icon => (
                        createElement(ICONS[icon].icon, {
                            key: icon,
                            title: ICONS[icon].name,
                            className: active.indexOf(icon) !== -1 ? 'active' : semi.indexOf(icon) !== -1 ? 'semi' : '',
                            onClick: () => toggleButton(icon)
                        })
                    ))}
                </div>

                <button onClick={toggleOpen}>
                    {open ? <FiChevronUp /> : <FiChevronDown />}
                </button>
            </header>

            <main>
                <div>
                    <ul>
                        {icons.map(icon => (
                            <li key={icon} className={active.indexOf(icon) !== -1 ? 'active' : semi.indexOf(icon) !== -1 ? 'semi' : ''}>
                                {ICONS[icon].name}
                            </li>
                        ))}
                    </ul>

                    {id !== 'Roof' && id !== 'Basement' && (
                        <henshu.a
                            {...bindTo('intro.office.robin')}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://dashboard.robinpowered.com/sasaki/office?locations=9460&levels=${ROBIN_FLOORS[id]}`}
                        />
                    )}
                </div>

                {id !== 'Roof' && id !== 'Basement' && (
                    <div className="img">
                        <henshu.img get={() => floorplan} set={async value => update({ floorplan: await persist.upload(value)})} />
                    </div>
                )}
            </main>
        </div>
    );
});
