import React from 'react';
import ReactDOM from 'react-dom';
import { HenshuApp } from '@strategies/henshu-app';

import './index.scss';
import App from './App';
import seed from './seed';
import config from './firebaseConfig';


ReactDOM.render(
    <React.StrictMode>
        <HenshuApp config={config} seeds={seed}>
            <App />
        </HenshuApp>
    </React.StrictMode>,
    document.getElementById('root')
);
