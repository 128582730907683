import { henshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';

import Parallax from '../Parallax/Parallax';


export default function DoubleImage({ className = '', front, frontMod = .1, back, backMod = .2, style = {} }) {
    const { isEditing, persist } = useHenshuApp();

    return (
        <div style={style} className={`DoubleImage ${className} ${isEditing ? 'editing' : ''}`}>
            <Parallax className="front" magnitude={frontMod}>
                <henshu.img
                    get={front.get}
                    set={async buf => front.set(await persist.upload(buf))}
                />
            </Parallax>

            {(back.get() || isEditing) && (
                <Parallax className="back" magnitude={backMod}>
                    <henshu.img
                        get={back.get}
                        set={async buf => back.set(await persist.upload(buf))}
                    />
                </Parallax>
            )}
        </div>
    );
}
