import algoliasearch from 'algoliasearch/lite';
import { useState } from 'react';
import {
    InstantSearch,
    connectHits,
    SearchBox,
    Pagination,
    Highlight,
    Snippet,
} from 'react-instantsearch-dom';


const searchClient = algoliasearch(
    '17E5AVPGRW',
    'b1b72e95843dc89f1adaea79d14db9c1'
);

export default function Search() {
    const [query, setQuery] = useState('');

    const Hits = connectHits(({ hits }) => {
        if (query !== '') {
            if (hits.length > 0) {
                return <>
                    <ul className="hits">
                        {hits.map(hit => <Hit hit={hit} />)}
                    </ul>
                    <Pagination />
                </>;
            }
            else {
                return <div className="no-hits">No results</div>;
            }
        }

        return <></>;
    });

    return (
        <div className="Search">
            <InstantSearch
                indexName="WelcomeGuideIndex"
                searchClient={searchClient}
                onSearchStateChange={({ query }) => setQuery(query)}
            >
                <SearchBox />
                <Hits hitComponent={Hit} />
            </InstantSearch>
        </div>
    );
}


const PAGES = [
    'one',
    'two',
    'three',
    'four',
    'five',
];

function Hit({ hit }) {
    return (
        <div className="Hit">
            <a href={`/issues/${PAGES[hit.issue - 1]}#${hit.anchor}`}>
                <h3 className="hit-title">
                    <Highlight attribute="title" hit={hit} />
                </h3>

                <main className="hit-text">
                    <Snippet
                        attribute={'text'}
                        hit={hit}
                    />
                </main>

                <footer>
                    <div className="hit-author">
                        {hit.author && (
                            <Highlight attribute="author" hit={hit} />
                        )}
                    </div>

                    <div className="guide-number">
                        Welcome Guide #{hit.issue}
                    </div>
                </footer>
            </a>
        </div>
    );
}
