import { rand } from '../../util';

/*
import outline1 from '../../assets/outlines/Untitled_Artwork-5.png';
import outline2 from '../../assets/outlines/Untitled_Artwork-6.png';
import outline3 from '../../assets/outlines/Untitled_Artwork-7.png';
import outline4 from '../../assets/outlines/Untitled_Artwork-8.png';
*/
import outline5 from '../../assets/outlines/Untitled_Artwork-9.png';
/*
import outline6 from '../../assets/outlines/Untitled_Artwork-10.png';
import outline7 from '../../assets/outlines/Untitled_Artwork-11.png';
import outline8 from '../../assets/outlines/Untitled_Artwork-12.png';
import outline9 from '../../assets/outlines/Untitled_Artwork-13.png';
import outline10 from '../../assets/outlines/Untitled_Artwork-14.png';
*/

const outlines = [
    /*
    outline1,
    outline2,
    outline3,
    outline4,
    */
    outline5,
    /*
    outline6,
    outline7,
    outline8,

    outline9,
    outline10,
    */
];

export default function Outline({ index }) {
    const outline = outlines[index || rand(0, outlines.length)];
    const rotation = rand(0, 360);

    return (
        <img className="Outline" src={outline} alt="" style={{ transform: `rotate(${rotation}deg)`}} />
    );
}

