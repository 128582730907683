import { useState } from 'react';
import { henshu, useHenshu } from '@strategies/henshu';

import DoubleImage from '../DoubleImage/DoubleImage';


export default function Letter({ className = '', name,  expandable = true }) {
    const { bindTo } = useHenshu();
    const [expanded, expand] = useState(!expandable);

    return (
        <div className={`Letter ${className}`}>
            <DoubleImage
                style={{ zIndex: 100 }}
                frontMod={0}
                backMod={-.15}
                front={bindTo(`${name}.banner.front`)}
                back={bindTo(`${name}.banner.back`)}
            />

            <div className={`body ${expanded ? 'expanded' : ''}`}>
                <henshu.richtext {...bindTo(`${name}.body`)} />

                {!expanded && (
                    <button onClick={() => expand(true)}>
                        Read More ...
                    </button>
                )}
            </div>
        </div>
    );
};
