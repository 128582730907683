import { useState, useEffect } from 'react';


export default function Parallax({ className = '', children, magnitude = .2, log = false }) {
    const [pos, setPos] = useState(0);
    const [ref, setRef] = useState(null);

    useEffect(() => {
        if (ref) {
            const update = () => {
                const { top, height } = ref.getBoundingClientRect();
                const screenHeight = window.innerHeight + height;
                const screenPos = window.innerHeight - top;

                if (screenPos >= 0 && screenPos <= screenHeight) {
                    setPos(magnitude * screenHeight * ((screenPos / screenHeight) - .5));
                }
            };

            window.addEventListener('scroll', update);
            return () => window.removeEventListener('scroll', update);
        }
    }, [ref, log, magnitude]);

    return (
        <div className={`Parallax ${className}`} ref={r => r && !ref && setRef(r)}>
            <div className="Parallax-frame" style={{ transform: `translateY(${pos}px)` }}>
                {children}
            </div>
        </div>
    )
};
