import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp, Curate } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { Anchor } from '@strategies/react-anchors';

import Blob from '../../Blob/Blob';
import ContentPanel from '../../ContentPanel/ContentPanel';
import DoubleImage from '../../DoubleImage/DoubleImage';
import FadeUp from '../../animations/FadeUp';
import FloatingImage from '../FloatingImage/FloatingImage';
import ImageWrap from '../../ImageWrap/ImageWrap';
import Issue from '../Issue/Issue';
import Letter from '../../Letter/Letter';
import Parallax from '../../Parallax/Parallax';
import Section from '../../Section/Section';

import SasakiLogoWhite from '../../../assets/images/sasaki-logo-white.png';


export default observer(function FirstIssue() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;
    const [placesToWorkActive, setPlacesToWorkActive] = useState(false);

    return (
        <Issue className="FirstIssue">
            <header className="heavy-padding">
                <div>
                    <img className="sasaki-logo" src={SasakiLogoWhite} alt="Sasaki" />

                    <henshu.img
                        alt="Hello 110 Chauncy"
                        get={() => bindTo('he110chauncy').get()}
                        set={async buf => bindTo('he110chauncy').set(await upload(buf))}
                    />
                </div>

                <FadeUp>
                    <henshu.h1 {...bindTo('issues.first.title')} />

                    <Parallax className="header-circle" magnitude={-.05}>
                        <henshu.img
                            get={() => bindTo('issues.first.title.circle').get()}
                            set={async buf => bindTo('issues.first.title.circle').set(await upload(buf))}
                        />
                    </Parallax>
                </FadeUp>
            </header>

            <Section>
                <FadeUp>
                    <DoubleImage
                        backMod={.3}
                        front={bindTo('issues.first.banner.front')}
                        back={bindTo('issues.first.banner.back')}
                    />
                </FadeUp>
            </Section>

            <ul className="intro">
                <henshu.each {...bindTo('issues.first.intro')}>
                    {(bindToIntro, i) => (
                        <li>
                            <Curate id={`issues.first.intro[${i+1}]`} data={{ title: 'Intro' }}>
                                <FadeUp>
                                    {i !== 0 && (
                                        <DoubleImage
                                            frontMod={.1}
                                            backMod={i === 5 ? .18 : .27}
                                            front={bindToIntro('image.front')}
                                            back={bindToIntro('image.back')}
                                        />
                                    )}

                                    <henshu.richtext {...bindToIntro('text')} />
                                </FadeUp>
                            </Curate>
                        </li>
                    )}
                </henshu.each>
            </ul>

            <Section className="letters">
                <Curate id="issues.first.section1" data={{ title: 'Why Downtown?', author: 'James Miner' }}>
                    <FadeUp>
                        <Letter name="issues.first.section1" />
                    </FadeUp>
                </Curate>

                <Curate id="issues.first.section2" data={{ title: "Vision For A New Workspace", author: 'Liz von Goeler + Victor Vizgaitis' }}>
                    <FadeUp>
                        <Letter name="issues.first.section2" className="single-image" />
                    </FadeUp>
                </Curate>
            </Section>

            <Section>
                <FadeUp>
                    <DoubleImage
                        front={bindTo('issues.first.section3.image.front')}
                        back={bindTo('issues.first.section3.image.back')}
                        frontMod={-.4}
                        backMod={-.1}
                    />
                </FadeUp>

                <Curate id="places-to-work" data={{ title: bindTo('issues.first.section3.title').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.first.section3.title')} />
                    </FadeUp>

                    <div className="places-to-work">
                        <Anchor onCenter={() => setPlacesToWorkActive(true)}>
                                <ul>
                                    {placesToWork.map(place => (
                                        <li key={place.name} className={placesToWorkActive ? 'active' : ''}>
                                            <span>
                                                <Blob />
                                                {place.name}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                        </Anchor>
                    </div>
                </Curate>
            </Section>

            <Section className="sub">
                <FloatingImage name="library-friend" backMod={.05}/>
                <FloatingImage name="meditating-friend" backMod={.3}/>

                <Curate id="issues.first.section4" data={{ title: bindTo('issues.first.section4.title').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.first.section4.title')} />
                        <henshu.richtext {...bindTo('issues.first.section4.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section>
                <FadeUp>
                    <DoubleImage
                        front={bindTo("mask-friends.front")}
                        back={bindTo("mask-friends.back")}
                        frontMod={0}
                        backMod={-.25}
                    />
                </FadeUp>
            </Section>

            <Section className="sub">
                <Curate id="issues.first.section5" data={{ title: bindTo('issues.first.section5.title').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.first.section5.title')} />
                        <henshu.richtext {...bindTo('issues.first.section5.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="sub">
                <FloatingImage name="pinup-friend" />

                <Curate id="issues.first.section6" data={{ title: bindTo('issues.first.section6.title').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.first.section6.title')} />
                        <henshu.richtext {...bindTo('issues.first.section6.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="sub">
                <FloatingImage name="screen-friends" backMod={.05} />
                <FloatingImage name="seats-metric" backMod={-.1} />
                <FloatingImage name="window-friend" frontMod={.1} backMod={.2} />

                <Curate id="issues.first.section7" data={{ title: bindTo('issues.first.section7.title').get() }}>
                    <FadeUp>
                        <henshu.h3 {...bindTo('issues.first.section7.title')} />
                        <henshu.richtext {...bindTo('issues.first.section7.body')} />
                    </FadeUp>
                </Curate>
            </Section>

            <Section>
                <FloatingImage name="model-making-friend" backMod={.05} frontMod={.1} />
                <FloatingImage name="phone-friend" backMod={.18} frontMod={.1} />
                <FloatingImage name="whiteboard-friend" frontMod={.1} />
                <FloatingImage name="down-dog-friend" backMod={-.1} frontMod={.1} />

                <Curate id="issues.first.metrics" data={{ title: 'Metrics' }}>
                    <ul className="metrics">
                        <henshu.each {...bindTo('issues.first.metrics')}>
                            {(bindToMetric, i) => (
                                <FadeUp>
                                    <li key={bindToMetric('name').get()} className={metricOrientation[i]}>
                                        <div className="metric-content">
                                            <henshu.span {...bindToMetric('name')} />
                                            <henshu.b {...bindToMetric('number')} />
                                        </div>
                                    </li>
                                </FadeUp>
                            )}
                        </henshu.each>
                    </ul>
                </Curate>
            </Section>

            <Section className="letters">
                <Curate id="issues.first.section8" data={{
                    title: bindTo("issues.first.section8.title").get(),
                    author: bindTo("issues.first.section8.footer").get(),
                }}>
                    <FadeUp>
                        <FloatingImage name="elf-friend" backMod={.4} />
                        <ContentPanel
                            className="quoted green"
                            title="issues.first.section8.title"
                            body="issues.first.section8.body"
                            footer="issues.first.section8.footer"
                        />
                    </FadeUp>
                </Curate>

                <Curate id="issues.first.section9" data={{
                    title: bindTo("issues.first.section9.title").get(),
                    author: bindTo("issues.first.section9.footer").get(),
                }}>
                    <FadeUp>
                        <FloatingImage name="drawing-friend" backMod={.3} />
                        <ContentPanel
                            className="quoted pink"
                            title="issues.first.section9.title"
                            body="issues.first.section9.body"
                            footer="issues.first.section9.footer"
                        />
                    </FadeUp>
                </Curate>
            </Section>

            <Section className="wide features-section">
                <Section className="sub">
                    <henshu.h3 {...bindTo('issues.first.feature.header')} />
                    <Curate id="issues.first.feature.body" data={{ title: bindTo('issues.first.feature.header').get() }}>
                        <henshu.p {...bindTo('issues.first.feature.body')} />
                    </Curate>
                </Section>

                <ul className="features">
                    <henshu.each {...bindTo('issues.first.features')}>
                        {(bindToFeature, i) => (
                            <Curate id={`issues.first.features[${i}]`}  data={{ title: bindTo('issues.first.feature.header').get() }}>
                                <Feature bindTo={bindToFeature} i={i} />
                            </Curate>
                        )}
                    </henshu.each>
                </ul>
            </Section>

            <Section>
                <FadeUp>
                    <DoubleImage
                        front={bindTo('laptop-friend.front')}
                        back={bindTo('laptop-friend.back')}
                        frontMod={0}
                        backMod={-.15}
                    />
                </FadeUp>
            </Section>
        </Issue>
    );
});


function Feature({ bindTo, i }) {
    const [active, setActive] = useState(false);

    return (
        <li key={i} className={active ? '' : 'inactive'}>
            <Anchor onTop={() => setActive(true)}>
                <henshu.p {...bindTo('body')} />
                <ImageWrap {...bindTo('image')} />
            </Anchor>
        </li>
    )
}

const placesToWork = [
    {
        name: 'Desks',
        blob: '',
    },
    {
        name: 'Quiet Spaces',
        blob: '',
    },
    {
        name: 'Multipurpose spaces',
        blob: '',
    },
    {
        name: 'Cafe spaces',
        blob: '',
    },
    {
        name: 'Roof deck',
        blob: '',
    },
    {
        name: 'Phone rooms',
        blob: '',
    },
    {
        name: 'Four event space locations',
        blob: '',
    },
    {
        name: 'Hidden',
        blob: '',
    },
    {
        name: 'Library stacks and working areas',
        blob: '',
    },
    {
        name: 'Fab Lab',
        blob: '',
    },
    {
        name: 'Wellness rooms',
        blob: '',
    },
    {
        name: 'Work spaces at soft seating',
        blob: '',
    },
];


const metricOrientation = [
    'left',
    'left',
    'left',
    'right',
    'right',
    'right',
    'left',
    'left',
    'left',
    'left',
    'left',
    'right',
    'right',
    'right',
];

