import { useEffect, useState } from 'react';
import { Anchor } from '@strategies/react-anchors';


export default function FadeUp({ auto, children, className }) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (auto) {
            const dispose = setTimeout(() => setActive(true), 500);
            return () => clearTimeout(dispose);
        }
    }, [auto]);

    return (
        <div className={`FadeUp ${active ? 'active' : ''} ${className || ''}`}>
            <Anchor onCenter={() => setActive(true)} onOffscreen={a => a.top <= 0 && setActive(true)} />
            {children}
        </div>
    );
};
