import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { Pinboard, Pin } from '@strategies/react-pinboard';

import Marker from './Marker';


export default observer(function MapSequencePanel({ className = '', panel, activePin, setActivePin }) {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;

    const panelIsActive = panel.pins.indexOf(activePin) !== -1;

    const onClick = useCallback((pin, e) => {
        e.preventDefault();
        e.stopPropagation();

        setActivePin(pin);

        const resetActivePin = () => {
            setActivePin(null)
            window.removeEventListener('click', resetActivePin);
        };
        window.addEventListener('click', resetActivePin);
    }, [setActivePin]);

    const isPinActive = useCallback(pin => `${activePin.x}${activePin.y}` === `${pin.x}${pin.y}`, [activePin]);

    if (!panel) return null;

    return (
        <div className={`MapSequence__Panel ${className}`}>
            <div className="AutoSizer">
                <Pinboard aspect={1752/1004}>
                    <henshu.img
                        get={() => bindTo(`${panel.id}.image`).get()}
                        set={async v => bindTo(`${panel.id}.image`).set(await upload(v))}
                    />

                    {panel.pins.map(pin => (
                        <Pin key={`${pin.x}${pin.y}`} x={pin.x} y={pin.y}>
                            <Marker
                                color={panelIsActive && isPinActive(pin) ? '#E8C868' : pin.color || panel.color}
                                onClick={e => onClick(pin, e)}
                            />
                        </Pin>
                    ))}
                </Pinboard>
            </div>

            <div className="content">
                <div className="panel">
                    {panel.pins.indexOf(activePin) !== -1 ? <>
                        <hgroup>
                            <h3>
                                {activePin.link ? (
                                    <a href={activePin.link} target="_blank" rel="noopener noreferrer">
                                        {activePin.title}
                                    </a>
                                ) : (
                                    activePin.title
                                )}
                            </h3>

                            {activePin.address && (
                                <h4>{activePin.address}</h4>
                            )}
                        </hgroup>

                        <p>
                            {activePin.notes}
                        </p>
                    </> : <>
                        <henshu.h3 {...bindTo(`${panel.id}.title`)} />
                        <henshu.richtext {...bindTo(`${panel.id}.body`)} />
                    </>}
                </div>
            </div>
        </div>
    );
});
