import { henshu, useHenshu } from '@strategies/henshu';

import FadeUp from '../animations/FadeUp';


export default function QA({ name }) {
    const { bindTo } = useHenshu();

    return (
        <div className="QA">
            <FadeUp>
                <p className="Q">
                    Q: <henshu.span {...bindTo(`${name}.q`)} />
                </p>
                <p className="A">
                    A: <henshu.span {...bindTo(`${name}.a`)} />
                </p>
            </FadeUp>
        </div>
    );
}
