import oldSeed from './assets/seed.json';


const seed = {
    default: {
        ...oldSeed,

        'banner': "👋 Sasaki is open for business! 🏗️🌞 Check out the building weather report for each level of the building!",
    }
};

export default seed;
