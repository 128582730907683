import React, { useCallback } from "react";

export default function Floor3({ index }) {

    const assignIndex = useCallback(i => ({
        className: i < index ? 'visited' : (i === index ? 'active' : 'inactive')
    }), [index])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 1920 1080"
            viewBox="0 0 1920 1080"
        >
                   <pattern
        id="New_Pattern"
        width="376.471"
        height="376.471"
        overflow="visible"
        patternUnits="userSpaceOnUse"
        viewBox="0 -376.471 376.471 376.471"
      >
        <path fill="none" d="M0 -376.471H376.471V0H0z"></path>
        <path fill="#BAD9C9" d="M0 -336.235H376.471V-329.411H0z"></path>
        <path fill="#BAD9C9" d="M0 -324.471H376.471V-317.647H0z"></path>
        <path fill="#A88CAD" d="M0 -329.412H376.471V-324.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -312.706H376.471V-305.882H0z"></path>
        <path fill="#A88CAD" d="M0 -317.647H376.471V-312.706H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -300.941H376.471V-294.11699999999996H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -305.882H376.471V-300.94100000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -289.176H376.471V-282.352H0z"></path>
        <path fill="#A88CAD" d="M0 -294.118H376.471V-289.177H0z"></path>
        <path fill="#BAD9C9" d="M0 -371.529H376.471V-364.705H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -376.471H376.471V-371.53000000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -359.765H376.471V-352.941H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -364.706H376.471V-359.76500000000004H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -348H376.471V-341.176H0z"></path>
        <path fill="#A88CAD" d="M0 -352.941H376.471V-348H0z"></path>
        <path fill="#A88CAD" d="M0 -341.176H376.471V-336.235H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -242.118H376.471V-235.29399999999998H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -230.353H376.471V-223.529H0z"></path>
        <path fill="#A88CAD" d="M0 -235.294H376.471V-230.353H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -218.588H376.471V-211.76399999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -223.529H376.471V-218.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -206.824H376.471V-200H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -211.765H376.471V-206.82399999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -195.059H376.471V-188.23499999999999H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -200H376.471V-195.059H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -277.412H376.471V-270.58799999999997H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -282.353H376.471V-277.41200000000003H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -265.647H376.471V-258.823H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -270.588H376.471V-265.64700000000005H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -253.882H376.471V-247.058H0z"></path>
        <path fill="#A88CAD" d="M0 -258.824H376.471V-253.883H0z"></path>
        <path fill="#A88CAD" d="M0 -247.059H376.471V-242.118H0z"></path>
        <path fill="#BAD9C9" d="M0 -148H376.471V-141.176H0z"></path>
        <path fill="#BAD9C9" d="M0 -136.235H376.471V-129.411H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -141.176H376.471V-136.23499999999999H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -124.471H376.471V-117.647H0z"></path>
        <path fill="#A88CAD" d="M0 -129.412H376.471V-124.471H0z"></path>
        <path fill="#BAD9C9" d="M0 -112.706H376.471V-105.882H0z"></path>
        <path fill="#A88CAD" d="M0 -117.647H376.471V-112.706H0z"></path>
        <path fill="#BAD9C9" d="M0 -100.941H376.471V-94.117H0z"></path>
        <path fill="#A88CAD" d="M0 -105.882H376.471V-100.941H0z"></path>
        <path fill="#BAD9C9" d="M0 -183.294H376.471V-176.47H0z"></path>
        <path fill="#A88CAD" d="M0 -188.235H376.471V-183.294H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -171.529H376.471V-164.70499999999998H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -176.471H376.471V-171.53H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -159.765H376.471V-152.94099999999997H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -164.706H376.471V-159.765H0z"></path>
        <path fill="#A88CAD" d="M0 -152.941H376.471V-148H0z"></path>
        <path fill="#BAD9C9" d="M0 -53.882H376.471V-47.058H0z"></path>
        <path
          fill="#BAD9C9"
          d="M0 -42.118H376.471V-35.294000000000004H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -47.059H376.471V-42.117999999999995H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -30.353H376.471V-23.529000000000003H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -35.294H376.471V-30.352999999999998H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -18.588H376.471V-11.764000000000001H0z"
        ></path>
        <path fill="#A88CAD" d="M0 -23.529H376.471V-18.588H0z"></path>
        <path fill="#BAD9C9" d="M0 -6.824H376.471V0H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -11.765H376.471V-6.824000000000001H0z"
        ></path>
        <path fill="#BAD9C9" d="M0 -89.176H376.471V-82.352H0z"></path>
        <path
          fill="#A88CAD"
          d="M0 -94.118H376.471V-89.17699999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -77.412H376.471V-70.58800000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -82.353H376.471V-77.41199999999999H0z"
        ></path>
        <path
          fill="#BAD9C9"
          d="M0 -65.647H376.471V-58.82300000000001H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -70.588H376.471V-65.64699999999999H0z"
        ></path>
        <path
          fill="#A88CAD"
          d="M0 -58.824H376.471V-53.882999999999996H0z"
        ></path>
      </pattern>

        <pattern
          id="L3_x5F_combo_x5F_workstation_00000036251452249244794930000011702030135797093822_"
          patternTransform="rotate(45.001 -6846.635 -6771.73)"
          xlinkHref="#New_Pattern"
        ></pattern>
        <path
            {...assignIndex(7)}
            stroke={'#C24DFF'}
            strokeWidth={5}
          id="L3_x5F_combo_x5F_workstation_00000065051752542643346410000010690734044421526718_"
          fill="url(#L3_x5F_combo_x5F_workstation_00000036251452249244794930000011702030135797093822_)"
          d="M1241.309 606.147c-28.972-18.931-59.678-38.444-93.107-39.513-7.749-.248-15.707.584-22.533 4.345-9.062 4.992-15.366 15.123-16.606 26.137s2.503 22.639 9.637 30.876c5.928 6.845 13.812 11.254 21.661 15.155 16.853 8.376 34.924 15.168 53.236 14.409 18.312-.758 36.95-10.365 45.042-27.945 3.131-6.803 4.571-14.764 2.67-23.464z"
        ></path>
        <path
            {...assignIndex(4)}
          id="L3_x5F_collab_x5F_pinup_00000151511541306208953770000012767389260925397404_"
          fill="#C24DFF"
          d="M1024.582 603.559c-.147-4.953-.095-9.912.153-14.861.093-1.851.267-3.841 1.463-5.257 1-1.185 2.538-1.748 4.012-2.228a102.333 102.333 0 0132.049-5.019c1.679.007 3.477.094 4.829 1.09 1.972 1.452 2.272 4.232 2.392 6.679.499 10.18.848 20.368 1.047 30.559.018.928.026 1.897-.387 2.727-.571 1.148-1.812 1.779-2.989 2.289-10.346 4.479-21.826 5.471-33.085 6.059-1.56.081-3.202.14-4.593-.57-1.347-.688-2.258-1.999-2.978-3.329-3.289-6.069-1.913-18.139-1.913-18.139z"
        ></path>
        <path
            {...assignIndex(4)}
          id="L3_x5F_collab_x5F_pinup"
          fill="#C24DFF"
          d="M1136.029 679.47l-46.553 35.19c-4.807 3.634-10.382 7.47-16.321 6.444-4.796-.829-8.425-4.624-12.526-7.246s-10.228-3.802-13.325-.047c-1.416 1.718-1.793 4.04-2.118 6.242-1.655 11.211-2.766 24.231 5.174 32.318 4.418 4.5 10.763 6.415 16.822 8.165 3.294.951 6.702 1.912 10.102 1.47 3.478-.452 6.59-2.326 9.528-4.242 11.893-7.753 22.833-16.862 33.742-25.948 6.743-5.616 13.725-11.547 17.141-19.63 3.315-7.842 2.841-16.698 2.31-25.195-.12-1.915-.278-3.943-1.398-5.502-1.119-1.556-3.566-2.32-2.578-2.019z"
        ></path>
        <path
            {...assignIndex(1)}
          id="L3_x5F_collab_x5F_conf_room_00000093889124775249259330000018170067092704264870_"
          fill="#C24DFF"
          d="M1340.03 545.313c.305-12.645-10.417-21.764-20.402-27.441-14.496-8.241-30.401-14.271-46.614-13.701-16.214.57-32.779 8.55-41.976 23.676-5.237 8.612-7.837 19.926-4.583 29.711 3.451 10.378 12.56 16.911 21.661 21.009 17.941 8.077 38.003 9.524 56.938 5.251 10.093-2.278 20.298-6.419 27.426-14.823 7.127-8.404 10.314-21.899 7.55-23.682z"
        ></path>
        <path
            {...assignIndex(1)}
          id="L3_x5F_collab_x5F_conf_room_00000108290796489475250740000002405065426511096198_"
          fill="#C24DFF"
          d="M738.021 567.001c-.779-2.778-1.606-5.649-3.47-7.852-1.863-2.202-5.072-3.546-7.709-2.375-1.749.777-2.905 2.466-3.787 4.165-5.581 10.746-3.472 23.832.234 35.359 1.46 4.54 3.313 9.246 7.069 12.184s9.958 3.241 12.827-.568c1.177-1.564 1.63-3.545 1.95-5.477 2.553-15.415-1.846-31.847-7.114-35.436z"
        ></path>
        <path
            {...assignIndex(1)}
          id="L3_x5F_collab_x5F_conf_room"
          fill="#C24DFF"
          d="M972.294 639.936c5.84-.365 11.586 1.767 16.651 4.698 7.119 4.119 13.814 11.229 12.869 19.399-.755 6.52-6.116 11.471-11.464 15.276-5.694 4.051-11.917 7.59-18.755 9.028-6.839 1.439-14.398.562-20.039-3.564-5.64-4.126-8.827-11.834-6.579-18.451 1.081-3.182 3.246-5.862 5.369-8.468l6.961-8.545c-.001.001 9.147-9.008 14.987-9.373z"
        ></path>
        <path
            {...assignIndex(2)}
          id="L3_x5F_collab_x5F_open_collaboration_space_00000080197702023629310110000007282363253521177235_"
          fill="#A88CAD"
          d="M1170.284 674.141c-5.732-4.193-13.363-3.955-19.778-1.259-6.415 2.696-11.887 7.542-17.243 12.314-9.049 8.062-18.377 16.5-23.527 28.068-3.428 7.699-3.478 19.398 3.731 22.442 4.033 1.703 8.509-.242 12.384-2.349 13.796-7.501 26.526-17.433 37.573-29.314 3.339-3.591 6.606-7.497 8.255-12.332 2.037-5.975 2.662-14.602-1.395-17.57z"
        ></path>
        <path
            {...assignIndex(2)}
          id="L3_x5F_collab_x5F_open_collaboration_space_00000126316244371445160120000006327449333710885265_"
          fill="#A88CAD"
          d="M846.878 662.789c-9.616-13.141-19.638-26.602-33.593-34.993s-32.98-10.523-46.026-.779c-17.92 13.385-16.223 41.114-7.434 61.682 12.845 30.06 36.122 54.246 59.139 77.458 20.964 21.142 42.316 42.239 67.217 58.56s53.867 27.732 83.639 27.422c14.551-.152 29.378-3.24 41.639-11.079 12.26-7.839 21.648-20.863 22.959-35.356 1.344-14.861-5.844-29.552-16.329-40.169-10.485-10.617-23.99-17.701-37.641-23.724-25.059-11.056-51.405-19.121-75.998-31.176-24.593-12.054-44.971-30.626-57.572-47.846z"
        ></path>
        <path
            {...assignIndex(12)}
          id="L3_x5F_respite_x5F_wellness"
          fill="#915C00"
          d="M881.213 652.112c-8.16.838-16.009-4.576-20.159-11.652-4.15-7.075-5.228-15.52-5.508-23.718-.115-3.371-.092-6.844 1.161-9.975 2.65-6.624 9.961-9.985 16.795-12.033a109.984 109.984 0 0131.44-4.62c4.456-.003 9.073.308 13.015 2.386 3.942 2.078 7.036 6.346 6.464 10.765-.375 2.899-2.197 5.379-4.012 7.67-12.211 15.422-26.886 28.888-39.196 41.177z"
        ></path>
        <path
            {...assignIndex(3)}
          id="L3_x5F_respite_x5F_lounge"
          fill="#C24DFF"
          d="M911.266 673.5c-9.311-2.586-17.271-6.407-17.271-17.736 0-12.301 17.414-31.453 28.356-37.245 10.942-5.792 25.032-.488 34.459 7.538 7.22 6.147-11.037 30.658-14.063 34.276-7.035 8.412-11.227 18.793-31.481 13.167z"
        ></path>
        <path
            {...assignIndex(14)}
            stroke="#915C00"
            strokeWidth={5}
          id="L3_x5F_respite_x5F_phone"
          fill="#D9A300"
          d="M1117.283 639.179c-.53-3.043-2.874-5.697-5.828-6.597-3.958-1.207-8.233.649-11.513 3.171-3.649 2.806-6.672 6.596-7.908 11.03-.935 3.354-.517 7.542 2.387 9.462 1.285.85 2.87 1.122 4.41 1.166 4.926.143 9.873-1.95 13.201-5.584s5.922-8.794 5.251-12.648z"
        ></path>
        <path
            {...assignIndex(19)}
          id="L3_x5F_store_x5F_shelf_00000011030143351449245650000011354339885362305169_"
          fill="#FF002E"
          d="M783.1 559.712c-4.664-2.213-10.13-1.56-15.219-.692-3.453.589-6.974 1.286-9.98 3.083s-5.445 4.923-5.49 8.425c-.054 4.279 3.549 7.971 7.652 9.187 4.104 1.216 8.54.423 12.61-.899 4.279-1.39 8.585-3.539 11.016-7.325 2.432-3.786 2.67-10.233-.589-11.779z"
        ></path>
        <path
            {...assignIndex(18)}
          id="L3_x5F_store_x5F_shelf_00000134932909377096246700000009174000569160107914_"
          fill="#FF002E"
          d="M991.667 736.287c-14.331-1.14-26.928 8.829-37.552 18.515-10.611 9.674-20.967 19.686-30.066 30.794-2.042 2.492-4.135 5.448-3.64 8.631.618 3.979 5.232 6.296 9.234 5.854s7.475-2.856 10.754-5.192c17.582-12.527 35.401-25.276 48.93-42.099 1.933-2.404 3.852-5.099 3.952-8.182.102-3.084-2.504-6.41-1.612-8.321z"
        ></path>
        <path
            {...assignIndex(18)}
          id="L3_x5F_store_x5F_shelf_00000151537127988703210820000011118619439513906853_"
          fill="#FF002E"
          d="M831.099 635.777c.663 18.495 2.388 38.789 15.426 51.923 2.129 2.144 5.292 4.143 7.985 2.774 2.908-1.478 2.903-5.583 2.346-8.797a118.096 118.096 0 00-13.058-37.019c-1.219-2.196-2.623-4.466-4.862-5.605s-5.52-.58-7.837-3.276z"
        ></path>
        <path
            {...assignIndex(18)}
          id="L3_x5F_store_x5F_shelf"
          fill="#FF002E"
          d="M920.617 717.153a134.267 134.267 0 00-41.623-16.356c-3.568-.748-7.83-1.149-10.336 1.498-2.284 2.413-1.934 6.456-.113 9.235 1.821 2.779 4.758 4.591 7.6 6.312 18.007 10.91 36.283 21.939 56.516 27.763 4.618 1.329 10.163 2.185 13.743-1.02 3.83-3.43 3.109-9.979-.141-13.962s-8.243-6.035-13.021-7.933c-5.788-2.298-11.418-4.81-12.625-5.537z"
        ></path>
        <path
            {...assignIndex(18)}
          id="L3_x5F_store_x5F_shelf_00000173842532632253637650000014290837485335297437_"
          fill="#FF002E"
          d="M810.013 623.817a129.738 129.738 0 00-53.022 12.039c-4.262 1.981-9.03 5.189-8.839 9.885.143 3.505 3.271 6.321 6.66 7.228 3.389.907 6.98.32 10.422-.358a210.455 210.455 0 0042.8-13.303c3.002-1.298 6.362-3.149 6.884-6.378.848-5.242-6.306-8.038-4.905-9.113z"
        ></path>
        <path
            {...assignIndex(20)}
          id="L3_x5F_store_x5F_print_area"
          fill="#FF002E"
          d="M955.683 579.328c-7.66-3.661-7.586-15.307-8.666-23.728-.23-1.793-.356-3.705.453-5.322.633-1.265 1.765-2.197 2.883-3.064a95.496 95.496 0 0126.165-14.361c.763-.274 1.567-.542 2.369-.423 1.867.277 2.777 2.379 3.35 4.177l7.729 24.272c.707 2.219 1.417 4.61.725 6.834-.929 2.989-4.04 4.667-6.941 5.845-9.279 3.767-21.604 8.86-28.067 5.77z"
        ></path>
        </svg>
    );
}

