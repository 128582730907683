import { WatchAnchors } from '@strategies/react-anchors';


export default function Page({ children, className }) {
    return (
        <div className={`Page ${className}`}>
            <WatchAnchors>
                {children}
            </WatchAnchors>
        </div>
    );
}
